/**
 * Компонент для получения данных о статистике
 */
import StatisticsGroupBy from "../Enums/StatisticsGroupBy";
import axios from "axios";
import DateTime from "../../lib/DateTime";
import StatisticsAPIResponse from "./Structs/StatisticsAPIResponse";
import PaginationConstants from "../Enums/PaginationConstants";
import StatisticsColumns from "../Enums/StatisticsColumns";
import StatisticsSortTypes from "../Enums/StatisticsSortTypes";

export default class StatisticsAPI {

    /**
     * @type {string}
     */
    group_by     = StatisticsGroupBy.defaultForPublisher;
    /**
     * @type {number}
     */
    current_page = PaginationConstants.defaultPage;
    /**
     * @type {number}
     */
    per_page     = PaginationConstants.defaultPerPage;
    /**
     * @type {String}
     */
    order_by     = StatisticsColumns.Paid;
    /**
     * @type {String}
     */
    sort_type    = StatisticsSortTypes.defaultSort;
    /**
     * @type {Date}
     */
    from         = new Date();
    /**
     * @type {Date}
     */
    to           = new Date();
    /**
     * @type {Array}
     */
    zones        = [];
    /**
     * @type {Array}
     */
    days         = [];
    /**
     * @type {Array}
     */
    hours        = [];
    /**
     * @type {Array}
     */
    countries    = [];
    /**
     * @type {Array}
     */
    carriers     = [];
    /**
     * @type {Array}
     */
    browsers     = [];
    /**
     * @type {Array}
     */
    oses         = [];
    /**
     * @type {Array}
     */
    devices      = [];
    /**
     * @type {Array}
     */
    pubids       = [];

    static get link() {
        return process.env.REACT_APP_API_URL + '/publisher/statistics';
    };

    get submitData() {
        return {
            group_by: this.group_by,
            current_page: this.current_page,
            per_page: this.per_page,
            order_by: this.order_by,
            sort_type: this.sort_type,
            to: DateTime.dateToYmd(this.to),
            from: DateTime.dateToYmd(this.from),
            zones: this.zones,
            countries: this.countries,
            carriers: this.carriers,
            days: this.days,
            hours: this.hours,
            oses: this.oses,
            browsers: this.browsers,
            pubids: this.pubids,
            devices: this.devices,
        };
    };

    /**
     * @param {Function} successCallback
     * @param {Function} errorCallback
     *
     * @return {Promise}
     */
    send = (successCallback, errorCallback) => {
        return axios.get(StatisticsAPI.link, {params: this.submitData})
            .then((response) => {
                successCallback(new StatisticsAPIResponse(response));
            })
            .catch(errorCallback);
    };
}
