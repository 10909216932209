import _ from 'lodash';
import axios from "axios/index";
import Campaign from "../Advertiser/Structs/Campaign";

/**
 * Компонент для получения данных о кампании/ях в системе
 */
export default class CampaignAPI {

    /**
     * API URL для работы с кампаниями
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/advertiser/campaigns';
    };

    /**
     * Возвращает список кампаний
     *
     * @param {Number} page
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static list(page, successCallback, errorCallback) {
        return axios.get(CampaignAPI.link, {params: {page: page}})
            .then(({data}) => {
                return {
                    current_page: data.current_page,
                    last_page: data.last_page,
                    campaigns: _.map(data.data, (campaign) => new Campaign(campaign)),
                };
            })
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Получение кампании по id
     *
     * @param {Number} id
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static getById(id, successCallback, errorCallback) {
        axios.get(CampaignAPI.link + '/' + id)
            .then(({data}) => new Campaign(data))
            .then(successCallback)
            .catch(errorCallback);
    }
}
