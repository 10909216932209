import React, {Component} from 'react';
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import SelectOptions from "./SelectOptions";
import _ from "lodash";

/**
 * Оббертка для компонента Select из пакета react-select
 * Реализует недостющую логику по выбору значений/я
 */
export default class Select extends Component {

    /**
     * Метод вызывается при изменении значения/й селекта
     * Возвращает name поля и его новое значение/я
     *
     * @param selected
     */
    handleChange = (selected) => {
        let value = null;

        // Если это мультиселект, возвращаем массив значений
        // Иначе, значение от выбранного элемента
        if (this.props.multi) {
            value = _.map(selected, el => el.value);
        } else {
            value = selected !== null ? selected.value : this.props.defaultValue;
        }

        this.props.onChange(this.props.name, value);
    };

    render() {
        // Из списка опций выбираем выбранные элементы
        let value = this.props.multi
            ? this.props.options.optionsByValues(this.props.value)
            : this.props.options.optionByValue(this.props.value);

        return (
            <ReactSelect
                id={this.props.name}
                name={this.props.name}
                value={value}
                onBlur={this.props.onBlur}
                onChange={this.handleChange}
                options={this.props.options.list()}
                isClearable={this.props.clearable}
                isSearchable={this.props.searchable}
                isMulti={this.props.multi}
                placeholder={this.props.placeholder}
                isDisabled={this.props.disabled}
                required={this.props.required}
                autoFocus={this.props.autoFocus}
                classNamePrefix="react-select"
            />
        );
    }
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    options: PropTypes.instanceOf(SelectOptions).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    clearable: PropTypes.bool,
    searchable: PropTypes.bool,
    multi: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
};

Select.defaultProps = {
    name: '',
    value: '',
    defaultValue: null,
    placeholder: '',
    options: new SelectOptions(),
    onBlur: function () {},
    onChange: function () {
        console.log('Default change callback');
    },
    clearable: false,
    searchable: false,
    multi: false,
    disabled: false,
    required: false,
    autoFocus: false,
};
