import React from 'react';
import BaseForm from '../../components/BaseForm';
import {Panel} from 'react-bootstrap';
import '../../styles/css/auth-form.css';
import AuthAPI from "../../api-data/AuthAPI";

export default class SignInForm extends BaseForm {
    state = {
        submit: {
            email: '',
            password: '',
            rememberMe: true,
        }
    };

    /**
     * @inheritDoc
     * @returns {function(*)}
     */
    get successCallback () {
        return () => window.location = '/';
    };

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        AuthAPI.login(this.state.submit, this.successCallback, this.errorCallback);
    };

    render() {
        return (
            <div className="col-sm-6 col-md-4 col-md-offset-4 auth-container">
                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <Panel.Title>Sign In</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <form className="login-form" id='login-form' onSubmit={this.handleSubmit}>
                            <div className="form-group" id="email-block">
                                <label htmlFor="email" className='control-label'>E-mail</label>
                                <input type="email" name="email" value={this.state.submit.email} className="form-control login-form__email-input" placeholder="Email" onChange={this.handleChange} required autoFocus/>
                                <small className="text-danger"/>
                            </div>
                            <div className="form-group" id="password-block">
                                <label htmlFor="password" className='control-label'>Password</label>
                                <input type="password" name="password" value={this.state.submit.password} className="form-control login-form__password-input" placeholder="Password" onChange={this.handleChange} required/>
                                <small className="text-danger"/>
                            </div>
                            {/*<div>*/}
                            {/*    <NavLink to='/registration'>Registration</NavLink>*/}
                            {/*</div>*/}
                        </form>
                    </Panel.Body>

                    <Panel.Footer>
                        <button className="btn btn-lg btn-warning btn-block" type="submit" form='login-form'>Sign in</button>
                    </Panel.Footer>
                </Panel>
            </div>
        );
    }
}
