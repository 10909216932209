import React, {Component} from 'react';
import PropTypes from "prop-types";
import StatisticsGroupBy from "../../../../api-data/Enums/StatisticsGroupBy";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import StatisticsColumns from "../../../../api-data/Enums/StatisticsColumns";

/**
 * Шапка таблицы
 */
export default class Header extends Component {

    render () {
        return (
            <tr>
                <th className={'manager-statistics-table__header__group-by manager-statistics-table__header__group-by-' + this.props.group_by} onClick={() => this.props.sortBy(StatisticsColumns.GroupBy)} colSpan={2}>{this.props.group_by}</th>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.TotalImpressions}>Total Impressions Count</Tooltip>}>
                    <th className='manager-statistics-table__header__total-impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.TotalImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.TotalImpressions)}>Total Impr.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.Impressions}>Direct Impressions Count</Tooltip>}>
                    <th className='manager-statistics-table__header__impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.Impressions)} onClick={() => this.props.sortBy(StatisticsColumns.Impressions)}>Impr.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.UnshownImpressions}>Unshown Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__unshown-impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.UnshownImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.UnshownImpressions)}>Unsh.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BackbuttonImpressions}>Backbutton Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__backbutton-impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.BackbuttonImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.BackbuttonImpressions)}>BB Impr.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.UnshownBackbuttonImpressions}>Unshown Backbutton Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__unshown-backbutton-impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.UnshownBackbuttonImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.UnshownBackbuttonImpressions)}>BB Unsh.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BackurlImpressions}>Backbutton Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__backurl-impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.BackurlImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.BackurlImpressions)}>BU Impr.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.Conversions}>Conversions Count</Tooltip>}>
                    <th className='manager-statistics-table__header__conversions' hidden={this.props.isHiddenColumn(StatisticsColumns.Conversions)} onClick={() => this.props.sortBy(StatisticsColumns.Conversions)}>Conv.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.CR}>Conversion Rate</Tooltip>}>
                    <th className='manager-statistics-table__header__cr' hidden={this.props.isHiddenColumn(StatisticsColumns.CR)} onClick={() => this.props.sortBy(StatisticsColumns.CR)}>CR</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BackbuttonConversions}>Backbutton Conversions Count</Tooltip>}>
                    <th className='manager-statistics-table__header__backbutton_conversions' hidden={this.props.isHiddenColumn(StatisticsColumns.BackbuttonConversions)} onClick={() => this.props.sortBy(StatisticsColumns.BackbuttonConversions)}>BB Conv.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BBCR}>Backbutton Conversion Rate</Tooltip>}>
                    <th className='manager-statistics-table__header__bb_cr' hidden={this.props.isHiddenColumn(StatisticsColumns.BBCR)} onClick={() => this.props.sortBy(StatisticsColumns.BBCR)}>BBCR</th>
                </OverlayTrigger>
                <th className='manager-statistics-table__header__revenue' hidden={this.props.isHiddenColumn(StatisticsColumns.Revenue)} onClick={() => this.props.sortBy(StatisticsColumns.Revenue)}>Revenue</th>
                <th className='manager-statistics-table__header__paid' hidden={this.props.isHiddenColumn(StatisticsColumns.Paid)} onClick={() => this.props.sortBy(StatisticsColumns.Paid)}>Paid</th>
                <th className='manager-statistics-table__header__profit' hidden={this.props.isHiddenColumn(StatisticsColumns.Profit)} onClick={() => this.props.sortBy(StatisticsColumns.Profit)}>Profit</th>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.ECPM}>Effective CPM</Tooltip>}>
                    <th className='manager-statistics-table__header__ecpm' hidden={this.props.isHiddenColumn(StatisticsColumns.ECPM)} onClick={() => this.props.sortBy(StatisticsColumns.ECPM)}>eCPM</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.ECPMPub}>Effective CPM Publisher</Tooltip>}>
                    <th className='manager-statistics-table__header__ecpm_pub' hidden={this.props.isHiddenColumn(StatisticsColumns.ECPMPub)} onClick={() => this.props.sortBy(StatisticsColumns.ECPMPub)}>eCPMPub</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BotImpressions}>Bots Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__bot_impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.BotImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.BotImpressions)}>Bots</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.HostingImpressions}>Percent Impressions from Hosting</Tooltip>}>
                    <th className='manager-statistics-table__header__hosting_impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.HostingImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.HostingImpressions)}>Hosting</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.IframeImpressions}>Iframe Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__iframe_impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.IframeImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.IframeImpressions)}>Iframe</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.ProxyImpressions}>Proxy Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__proxy_impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.ProxyImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.ProxyImpressions)}>Proxy</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.InappImpressions}>InApp Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__inapp_impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.InappImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.InappImpressions)}>InApp</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.RevShare}>Return on Investment</Tooltip>}>
                    <th className='manager-statistics-table__header__rev_share' hidden={this.props.isHiddenColumn(StatisticsColumns.RevShare)} onClick={() => this.props.sortBy(StatisticsColumns.RevShare)}>ROI</th>
                </OverlayTrigger>
            </tr>
        );
    }
}

Header.propTypes = {
    group_by: PropTypes.oneOf(StatisticsGroupBy.list).isRequired,
    sortBy: PropTypes.func.isRequired,
    isHiddenColumn: PropTypes.func.isRequired,
};
