import React, {Component} from 'react';
import PropTypes from "prop-types";
import User from '../../../../api-data/Manager/Structs/User';
import UserAPI from '../../../../api-data/Manager/UserAPI';
import Form from './Form';
import List from './List';

export default class Comments extends Component {

    state = {
        comments: [],
    };

    componentWillMount() {
        UserAPI.comments(this.props.user.id)
            .then((comments) => this.setState({comments: comments}));
    }

    addComment = (comment) => {
        let comments = this.state.comments;
            comments.unshift(comment);

        this.setState({comments: comments});
    };

    render() {
        return (
            <div>
                <Form user={this.props.user} onSubmit={this.addComment} />
                <List comments={this.state.comments} authUser={this.props.authUser}/>
            </div>
        );
    }
}

Comments.propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    authUser: PropTypes.instanceOf(User).isRequired,
};
