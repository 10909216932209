import React from 'react';

import _ from 'lodash';
import History from "../lib/History";

/**
 * Базовый класс форм
 * Содержит в себе методы обработки форм
 */
export default class BaseForm extends React.Component {

    /**
     * Обработка события обновления значения поля формы
     * @param event
     */
    handleChange = (event) => {
        this.updateSubmitState(event.target.name, event.target.value);
    };
    
    /**
     * Обработка события обновления значения поля формы
     * Принимаются только значения соответствующие типам float && integer
     * @param event
     */
    handleChangeFloat = (event) => {
        let value = event.target.value;

        if (/^-?(\d{1,5}\.\d{0,4}|\d{0,5})$/.test(value)) {
            // Проверка первой строки на знак -, это знак отрицательной величины
            // Если он есть, ничего не меняем
            if (value !== '-') {
                // Если остался первый 0, и это не флоат значение, вырезаю его
                value = value.replace(/^0(\d)(.*)/, '$1$2');
                value = isNaN(parseFloat(value)) ? '' : value;
            }

            this.updateSubmitState(event.target.name, value);
        }
    };

    /**
     * Обработка события обновления значения поля формы
     * Принимаются только значения соответствующие типа integer
     * @param event
     */
    handleChangeInt = (event) => {
        let value = event.target.value;

        if (/^-?(\d{0,5})$/.test(value)) {
            // Проверка первой строки на знак -, это знак отрицательной величины
            // Если он есть, ничего не меняем
            if (value !== '-') {
                // Если остался первый 0, и это не флоат значение, вырезаю его
                value = value.replace(/^0(\d)(.*)/, '$1$2');
                value = isNaN(parseInt(value, 10)) ? '' : value;
            }

            this.updateSubmitState(event.target.name, value);
        }
    };

    /**
     * Обработка события обновления чекбокса
     * @param event
     */
    handleChangeCheckbox = (event) => {
        const name = event.target.name;

        this.updateSubmitState(name, !this.state.submit[name]);
    };

    /**
     * Обработка события смены периода дат
     * @param dateRange
     */
    handleChangeDateRange = (dateRange) => {
        let start = dateRange.startDate;
            start.setHours(0, 0, 0, 0);
        let end   = dateRange.endDate;
            end.setHours(23, 59, 59, 0);

        this.updateSubmitState('from', start);
        this.updateSubmitState('to', end);
    };

    /**
     * Обработка события смены периода дат
     * @param date
     */
    handleChangeDate = (date) => {
        this.updateSubmitState('date', date);
    };

    /**
     * @param event
     */
    handleChangeFile = (event) => {
        this.updateSubmitState(event.target.name, event.target.files[0]);
    };

    onKeyPress = (event) => {
        console.log(event.key);
        switch (event.key) {
            case 'Enter': return this.onKeyPressEnter(event)
            default:
        }
    };

    onKeyPressEnter = () => {};

    /**
     * Сохранение значения в списке полей
     * @param name
     * @param value
     * @param callback
     */
    updateSubmitState = (name, value, callback) => {
        let state = this.state;
            state.submit[name] = value;
        this.setState(state, callback);

        console.log(name, value);
    };

    /**
     * Добавляет переданные параметры в текущий URL адрес
     * @param params
     */
    updateUrlParams = (params) => {
        if (this.props.history !== undefined) {
            History.update(this.props.history, params);
        }
    };

    /**
     * Callback который нужно вызвать в случае успешной отправки данных
     * @returns {function(*)}
     */
    get successCallback () {
        return (response) => console.log(response);
    };

    /**
     * Callback который нужно вызвать в случае успешной отправки данных
     * @returns {function(*)}
     */
    get errorCallback () {
        return (error) => {
            let data = error && error.response && error.response.data;

            if (data !== undefined) {
                this.addErrors(data.errors);
            }
        };
    };

    /**
     * Удаляет ошибки из формы
     */
    removeErrors = () => {
        let elements = document.getElementsByClassName('has-error');

        _.map.call(elements, (block) => {
            block.children[2].innerText = '';
            block.classList.remove('has-error');
        });
    };

    /**
     * Добавляет ошибки валидации в форму
     * @param errors
     */
    addErrors = (errors) => {
        this.removeErrors();

        _.each(errors, (values, key) => {
            const block = document.getElementById(key + '-block');

            if (block === null) {
                return;
            }

            block.classList.add('has-error');

            const error = block.getElementsByClassName('text-danger')[0];

            if (error !== undefined) {
                error.innerText = _.first(values);
            }
        });
    }
}
