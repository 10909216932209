export default class StatisticsCorrectionStatus {
    /**
     * @returns {string}
     */
    static get InProgress() {
        return 'in_progress';
    };

    /**
     * @returns {string}
     */
    static get Success() {
        return 'success';
    };

    /**
     * @returns {string}
     */
    static get Failed() {
        return 'failed';
    };

    /**
     * @param status
     * @returns {string}
     */
    static statusColor(status) {
        switch (status) {
            case StatisticsCorrectionStatus.Success:
                return 'success';
            case StatisticsCorrectionStatus.Failed:
                return 'danger';
            case StatisticsCorrectionStatus.InProgress:
            default:
                return '';
        }
    }
}
