import React, {Component} from 'react';

/**
 * Компонент - заглушка для загружающейся страницы
 */
export default class Loading extends Component {

    render() {
        return (
            <div className='container wait-block'>
                {/*<img src={'/images/logo-animate.gif'} alt={'animate logo checketads.net'}/>*/}
            </div>
        );
    };
}
