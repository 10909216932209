/**
 * Структура, содержащая информацию о event
 */
import User from "./User";
import Campaign from "./Campaign";
import PostbacksReport from "./PostbacksReport";
import S2SRequest from "./S2SRequest";
import Correction from './Correction';

export default class Event {

    id                   = 0;
    type                 = '';
    is_viewed            = false;
    creator              = null;
    campaign             = null;
    postbacksReport      = null;
    s2sRequest           = null;
    statisticsCorrection = null;
    created_at           = null;
    updated_at           = null;

    constructor(data) {
        this.id                   = data.id;
        this.is_viewed            = data.is_viewed;
        this.type                 = data.type;
        this.creator              = data.creator ? new User(data.creator) : null;
        this.campaign             = data.campaign ? new Campaign(data.campaign) : null;
        this.postbacksReport      = data.postbacksReport ? new PostbacksReport(data.postbacksReport) : null;
        this.s2sRequest           = data.s2sRequest ? new S2SRequest(data.s2sRequest) : null;
        this.statisticsCorrection = data.statisticsCorrection ? new Correction(data.statisticsCorrection) : null;
        this.created_at           = new Date(data.created_at);
        this.updated_at           = new Date(data.updated_at);
    }
}
