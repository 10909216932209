import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import ColorNumber from "../../../../components/ColorNumber";
import StatsData from "../../../../api-data/Advertiser/Structs/StatsData";
import Number from "../../../../components/Formatting/Number";
import Checkbox from "../../../../components/Checkbox";
import CampaignLink from "../../../../components/ObjectLinks/CampaignLink";
import Progressbar from "../../../../components/Progressbar";
import CampaignStatusIco from "../../../../components/CampaignStatusIco";

/**
 * Список рядов таблицы статистки
 */
export default class CampaignBody extends Component {
    render () {
        return _.map(this.props.statistics, (statistics, key) => {
            let campaign = statistics.group_by;

            return (
                <tr className={this.props.isSelectedRow(campaign.id) ? 'selected': ''} key={key}>
                    <td className='check-column'>
                        <Checkbox onChange={() => this.props.updateSelection(campaign.id)} isChecked={this.props.isSelectedRow(campaign.id)} />
                    </td>
                    <td className='group_by-column'>
                        <CampaignLink id={campaign.id} name={campaign.pretty_name} is_blank={true}/>
                    </td>
                    <td className='status'>
                        <CampaignStatusIco status={campaign.status}/>
                    </td>
                    <td className='daily-limit'>
                        {campaign && <Progressbar
                            value={campaign.money.daily_limit_spent}
                            max={campaign.money.daily_limit}
                            unlimited={campaign.money.is_unlimited_daily}
                        />}
                    </td>
                    <td className='total-limit'>
                        {campaign && <Progressbar
                            value={campaign.money.total_limit_spent}
                            max={campaign.money.total_limit}
                            unlimited={campaign.money.is_unlimited_total}
                        />}
                    </td>
                    <td>{Number(statistics.impressions)}</td>
                    <td>{Number(statistics.conversions)}</td>
                    <td>{Number(statistics.cr, 4)}%</td>
                    <td>
                        <ColorNumber value={statistics.revenue} precision={2} postfix='$'/>
                    </td>
                    <td>{Number(statistics.ecpm, 4)}$</td>
                </tr>
            )
        });
    }
}

CampaignBody.propTypes = {
    isSelectedRow: PropTypes.func.isRequired,
    statistics: PropTypes.arrayOf(PropTypes.instanceOf(StatsData)).isRequired,
};
