/**
 * Структура, содержащая информацию о кампании
 */
import _ from 'lodash';
import User from "./User";
import Zone from "./Zone";
import CampaignMoney from "../../Structs/CampaignMoney";
import CampaignCountry from "../../Structs/CampaignCountry";
import CampaignPriceModels from "../../Enums/CampaignPriceModels";
import CampaignFormats from "../../Enums/CampaignFormats";
import CampaignStatuses from "../../Enums/CampaignStatuses";
import Prelanding from "./Prelanding";
import CampaignGroup from "./CampaignGroup";
import Company from "./Company";
import Event from "./Event";

export default class Campaign {

    id                      = 0;
    name                    = '';
    pretty_name             = '';
    user_id                 = 0;
    campaign_group_id       = 0;
    prelanding_id           = null;
    prelanding_params       = '';
    prelanding_link         = '';
    link                    = '';
    postback_link           = '';
    rate                    = 0;
    daily_limit             = 0;
    total_limit             = 0;
    frequency               = '';
    status                  = '';
    format                  = '';
    price_model             = '';
    is_unlimited_daily      = true;
    is_unlimited_total      = true;
    allow_to_backbutton     = false;
    money                   = null;
    company                 = null;
    user                    = null;
    group                   = null;
    prelanding              = null;
    zones                   = [];
    countries               = [];
    browsers                = [];
    devices                 = [];
    oses                    = [];
    carriers                = [];
    status_changes          = [];
    blacklist_publishers    = [];
    blacklist_zones         = [];
    blacklist_partner_zones = [];
    created_at              = null;
    updated_at              = null;

    constructor(data) {
        this.id                      = data.id;
        this.name                    = data.name;
        this.pretty_name             = data.pretty_name;
        this.user_id                 = data.user_id;
        this.campaign_group_id       = data.campaign_group_id;
        this.prelanding_id           = data.prelanding_id;
        this.prelanding_params       = data.prelanding_params || '';
        this.prelanding_link         = data.prelanding_link || '';
        this.link                    = data.link || '';
        this.postback_link           = data.postback_link;
        this.rate                    = data.rate;
        this.daily_limit             = data.daily_limit;
        this.total_limit             = data.total_limit;
        this.is_unlimited_daily      = data.is_unlimited_daily;
        this.is_unlimited_total      = data.is_unlimited_total;
        this.frequency               = data.frequency;
        this.status                  = data.status || CampaignStatuses.Paused;
        this.format                  = data.format || CampaignFormats.direct;
        this.price_model             = data.price_model || CampaignPriceModels.CPA;
        this.allow_to_backbutton     = data.allow_to_backbutton;
        this.company                 = new Company(data.company);
        this.user                    = new User(data.user);
        this.group                   = new CampaignGroup(data.group);
        this.money                   = new CampaignMoney(data.money);
        this.prelanding              = data.prelanding ? new Prelanding(data.prelanding) : null;
        this.status_changes          = data.status_changes ? _.map(data.status_changes, (event) => new Event(event)) : [];
        this.zones                   = data.zones ? _.map(data.zones, (zone) => new Zone(zone)) : [];
        this.countries               = data.countries ? _.map(data.countries, (country) => new CampaignCountry(country)) : [];
        this.blacklist_publishers    = data.blacklist_publishers ? _.map(data.blacklist_publishers, (publisher) => new User(publisher)) : [];
        this.blacklist_zones         = data.blacklist_zones ? _.map(data.blacklist_zones, (zone) => new Zone(zone)) : [];
        this.blacklist_partner_zones = data.blacklist_partner_zones || [];
        this.browsers                = data.browsers || [];
        this.devices                 = data.devices || [];
        this.oses                    = data.oses || [];
        this.carriers                = data.carriers || [];
        this.created_at              = new Date(data.created_at);
        this.updated_at              = new Date(data.updated_at);
    }
}
