import React, {Component} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import StatsData from "../../../../api-data/Publisher/Structs/StatsData";
import Footer from "./Footer";
import Header from "./Header";
import Body from "./Body";
import StatisticsGroupBy from "../../../../api-data/Enums/StatisticsGroupBy";
import ZoneBody from "./ZoneBody";
import CountryBody from "./CountryBody";

/**
 * Таблица статистики
 */
export default class StatisticsTable extends Component {

    /**
     * Проверка колонки на отсутствие в списке скрытых колонок
     * @param value
     * @returns {boolean}
     */
    isSelectedRow = (value) => {
        return this.props.selected.indexOf(value.toString()) !== -1;
    };

    /**
     * Обновление списка отмеченных полей
     * - Если сейчас это поле выбрано, тогда удаляем его из списка
     * - Если сейчас это поле не выбрано, тогда добавляем его в список
     *
     * @param value
     */
    updateSelection = (value) => {
        this.isSelectedRow(value)
            ? this.props.onUnselect(this.props.group_by, value)
            : this.props.onSelect(this.props.group_by, value);
    };

    render() {
        let header = '';
        let body   = '';
        let footer = '';

        switch (this.props.group_by) {
            case StatisticsGroupBy.zone:
                header = <Header sortBy={this.props.sortBy} group_by={StatisticsGroupBy.zone} />;
                body   = <ZoneBody isSelectedRow={this.isSelectedRow} updateSelection={this.updateSelection} statistics={this.props.statistics} />;
                footer = <Footer total={this.props.total} />;
                break;

            case StatisticsGroupBy.country:
                header = <Header sortBy={this.props.sortBy} group_by={StatisticsGroupBy.country} />;
                body   = <CountryBody isSelectedRow={this.isSelectedRow} updateSelection={this.updateSelection} statistics={this.props.statistics} />;
                footer = <Footer total={this.props.total} />;
                break;

            default:
                header = <Header sortBy={this.props.sortBy} group_by={this.props.group_by} />;
                body   = <Body group_by={this.props.group_by} isSelectedRow={this.isSelectedRow} updateSelection={this.updateSelection} statistics={this.props.statistics} />;
                footer = <Footer total={this.props.total} />;
        }

        return (
            <div>
                <Table hover bordered className='publisher-statistics-table'>
                    <thead className='publisher-statistics-table__header'>
                    {header}
                    </thead>

                    <tbody className='publisher-statistics-table__body'>
                        {body}
                    </tbody>

                    <tfoot className='publisher-statistics-table_footer'>
                    {footer}
                    </tfoot>
                </Table>
            </div>
        );
    }
}

StatisticsTable.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onUnselect: PropTypes.func.isRequired,
    sortBy: PropTypes.func.isRequired,
    group_by: PropTypes.oneOf(StatisticsGroupBy.publisherList).isRequired,
    selected: PropTypes.array.isRequired,
    statistics: PropTypes.arrayOf(PropTypes.instanceOf(StatsData)).isRequired,
    total: PropTypes.instanceOf(StatsData).isRequired,
};
