import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Pagination as PaginationBootstrap} from "react-bootstrap";

/**
 * Отрисовывает элемент Истины или Лжи в зависимости от параметра isOk
 */
export default class Pagination extends Component {

    render() {
        const FirstPage = 1;

        // При наличии 2 и больше страниц имеет смысл показывать пагинацию
        if (this.props.last_page < 2) {
            return '';
        }

        let prevPages = [];
        let postPages = [];

        // Если до первой страницы больше 2 страниц
        // Добавляем троеточие и 2 страницы: N-2, N-1
        // eslint-disable-next-line
        switch (true) {
            // eslint-disable-next-line
            case this.props.current_page - FirstPage > 2:
                prevPages.push(<PaginationBootstrap.Ellipsis key={-3} disabled />);

            // eslint-disable-next-line
            case this.props.current_page - FirstPage === 2:
                prevPages.push(
                    <PaginationBootstrap.Item key={-2} onClick={() => this.props.onChangePage(this.props.current_page - 2)}>
                        {this.props.current_page - 2}
                    </PaginationBootstrap.Item>
                );

            // eslint-disable-next-line
            case this.props.current_page - FirstPage === 1:
                prevPages.push(
                    <PaginationBootstrap.Item key={-1} onClick={() => this.props.onChangePage(this.props.current_page - 1)}>
                        {this.props.current_page - 1}
                    </PaginationBootstrap.Item>
                );
        }

        // Если от первой страницы до последней больше 2 страниц
        // Добавляем троеточие и 2 страницы: N+2, N+1
        // eslint-disable-next-line
        switch (true) {
            // eslint-disable-next-line
            case this.props.last_page - this.props.current_page > 2:
                postPages.unshift(<PaginationBootstrap.Ellipsis disabled key={3} />);

            // eslint-disable-next-line
            case this.props.last_page - this.props.current_page === 2:
                postPages.unshift(
                    <PaginationBootstrap.Item key={2} onClick={() => this.props.onChangePage(this.props.current_page + 2)}>
                        {this.props.current_page + 2}
                    </PaginationBootstrap.Item>
                );

            // eslint-disable-next-line
            case this.props.last_page - this.props.current_page === 1:
                postPages.unshift(
                    <PaginationBootstrap.Item key={1} onClick={() => this.props.onChangePage(this.props.current_page + 1)}>
                        {this.props.current_page + 1}
                    </PaginationBootstrap.Item>
                );
        }

        return (
            <PaginationBootstrap bsSize="small">
                <PaginationBootstrap.First
                    disabled={this.props.current_page === FirstPage}
                    onClick={() => this.props.onChangePage(FirstPage)}
                />

                {prevPages}

                <PaginationBootstrap.Item key={this.props.current_page} active>
                    {this.props.current_page}
                </PaginationBootstrap.Item>

                {postPages}

                <PaginationBootstrap.Last
                    disabled={this.props.current_page === this.props.last_page}
                    onClick={() => this.props.onChangePage(this.props.last_page)}
                />
            </PaginationBootstrap>
        );
    }
}

Pagination.propTypes = {
    onChangePage: PropTypes.func.isRequired,
    current_page: PropTypes.number.isRequired,
    last_page: PropTypes.number.isRequired,
};
