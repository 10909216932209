import User from './User';

export default class UserCommentResource {
    id         = 0;
    comment    = '';
    /** @type {User} */
    creator    = null;
    /** @type {Date} */
    created_at = null;

    constructor(data) {
        this.id         = data.id;
        this.comment    = data.comment;
        this.creator    = new User(data.creator);
        this.created_at = new Date(data.created_at);
    }
}
