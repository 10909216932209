import _ from 'lodash';
import axios from "axios/index";
import Prelanding from "./Structs/Prelanding";

/**
 * Компонент для получения данных о преленде/ах в системе
 */
export default class PrelandingAPI {

    /**
     * API URL для работы с прелендами
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/manager/prelandings';
    };

    /**
     * Возвращает список прелендов
     *
     * @param {Function} successCallback
     * @param {Function} errorCallback
     * @return {Promise}
     */
    static list(successCallback, errorCallback) {
        return axios.get(PrelandingAPI.link)
            .then(({data}) => _.map(data.data, (prelanding) => new Prelanding(prelanding)))
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Сохранение данных о преленде
     * Если этот преленд был ранее созадан, тогда обновляем его данные
     *
     * @param prelanding
     * @return {Promise}
     */
    static save(prelanding) {
        return axios.post(PrelandingAPI.link, prelanding);
    }

    /**
     * Получение преленда по id
     *
     * @param {Number} id
     * @param {Function} successCallback
     * @param {Function} errorCallback
     * @return {Promise}
     */
    static getById(id, successCallback, errorCallback) {
        return axios.get(PrelandingAPI.link + '/' + id)
            .then(({data}) => new Prelanding(data))
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Удаление преленда по id
     *
     * @param id
     * @param successCallback
     * @param errorCallback
     * @return {Promise}
     */
    static delete(id, successCallback, errorCallback) {
        return axios.delete(PrelandingAPI.link + '/' + id)
            .then(successCallback)
            .catch(errorCallback);
    }
}
