import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from "prop-types";

/**
 * Модальное окно с информацией о том, как использовать преленды
 */
export default class InfoModal extends Component {

    render() {
        return (
            <div className="static-modal">
                <Modal show={this.props.show} onHide={this.handleClose} bsSize="large">
                    <Modal.Body>
                        <h3>About</h3>
                        <p>
                            Преленд - это одностаничный сайт, который может помочь лучше монетизировать ваш оффер.
                            Преленды размещенные через наш сервис - геораспределенные. Он размещается на серверах в кажом из континентов, для уменьшения пинга при отдаче контента.
                        </p>

                        <h3>Гибкость</h3>
                        <p>
                            Каждую страницу можно сделать динамической с помощью параметров подставноки.
                            Вы можете передавать параметры через адресную строку, которые будут подставляться в контент страницы.
                        </p>

                        <h3>Начало работы</h3>
                        <p>
                            Вам нужно создать <b>ZIP</b> архив, в котором будет папка содержащая все элементы вашего преленда.
                            Название <b>ZIP</b> архива, папки с контентом внутри архива и имя субдомена должны совпадать.
                            Субдомен может содеражть только буквы латинского алфавита в нижнем регистре (a-z) и цифры (0-9).
                            В корне папки должен находится основной файл <b>index.html</b>.
                            Пути до статический файлов должны быть относильными либо ссылки на CDN.
                        </p>
                    </Modal.Body>

                    <div className="clearfix"/>

                    <Modal.Footer>
                        <button onClick={this.props.handleClose} className='btn btn-default modal-footer__button modal-footer__button_left'>
                            &nbsp;<span className='glyphicon glyphicon-remove' />&nbsp;
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

InfoModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

