import React, {Component} from 'react';
import UserLink from '../../../../components/ObjectLinks/UserLink';
import ShowButton from '../../../../buttons/ShowButton';
import StatisticsButton from '../../../../buttons/StatisticsButton';
import StatisticsGroupBy from '../../../../api-data/Enums/StatisticsGroupBy';
import EditButton from '../../../../buttons/EditButton';
import PropTypes from 'prop-types';
import _ from 'lodash';
import User from '../../../../api-data/Manager/Structs/User';
import ColorNumber from '../../../../components/ColorNumber';
import UserRoles from '../../../../api-data/Enums/UserRoles';

class UserRow extends Component {

    render() {
        const {user} = this.props;

        return (
            <tr className='user-table__body'>
                <td className='user-table__body_id'>{user.id}</td>
                <td className='user-table__body_name'>
                    <UserLink id={user.id} name={user.name} />
                </td>
                <td>{user.email}</td>
                <td>
                    {user.manager &&
                    <UserLink id={user.manager.id} name={user.manager.pretty_name} />
                    }
                </td>
                <td>{user.role}</td>
                <td className='user-table__body_balance'>
                    <ColorNumber value={user.balance} precision={2} postfix='$' />
                </td>
                <td>
                    <div className='btn-group btn-group-xs pull-right'>
                        <ShowButton route={'/users/' + user.id}/>
                        {user.role === UserRoles.advertiser &&
                        <StatisticsButton route={`/statistics?${StatisticsGroupBy.advertiser}[]=${user.id}`}/>
                        }
                        {user.role === UserRoles.publisher &&
                        <StatisticsButton route={`/statistics?${StatisticsGroupBy.publisher}[]=${user.id}`}/>
                        }
                        {(user.role === UserRoles.manager || user.role === UserRoles.headManager) &&
                        <StatisticsButton route='' disabled={true}/>
                        }
                        <EditButton route={`/users/${user.id}/edit`}/>
                    </div>
                </td>
            </tr>
        );
    }
}

UserRow.propTypes = {
    user: PropTypes.object.isRequired,
};

/**
 * Возвращает список рядов таблицы
 */
export default class Body extends Component {
    render() {
        const users = _.map(this.props.users, (user) => <UserRow user={user} key={user.id} />);

        return (
            <tbody>
                {users}
            </tbody>
        );
    }
}

Body.propTypes = {
    users: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,
};
