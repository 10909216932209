import User from "./User";
import ZoneMoney from "../../Structs/ZoneMoney";
import Campaign from "./Campaign";
import _ from 'lodash';
import CampaignGroup from "./CampaignGroup";

/**
 * Структура, содержащая информацию о зоне
 */
export default class Zone {

    id                      = 0;
    user_id                 = 0;
    firewall_campaign_id    = 0;
    name                    = '';
    pretty_name             = '';
    direct_link             = '';
    repostback_url          = '';
    back_url                = '';
    rate                    = 0;
    rev_share               = 0;
    fix_conversion_rate     = 0;
    type                    = '';
    rotation_type           = '';
    allow_to_backbutton     = false;
    enabled_firewall        = false;
    inherit_back_url        = true;
    inherit_repostback_url  = true;
    is_enabled_quality_page = false;
    money                   = null;
    user                    = null;
    firewall_campaign       = null;
    main_campaigns_groups   = [];
    main_campaigns          = [];
    backup_campaigns_groups = [];
    backup_campaigns        = [];
    created_at              = null;
    updated_at              = null;

    constructor(data) {
        this.id                      = data.id;
        this.user_id                 = data.user_id;
        this.firewall_campaign_id    = data.firewall_campaign_id;
        this.name                    = data.name;
        this.pretty_name             = data.pretty_name;
        this.direct_link             = data.direct_link;
        this.repostback_url          = data.repostback_url || '';
        this.back_url                = data.back_url || '';
        this.rate                    = data.rate;
        this.rev_share               = data.rev_share;
        this.fix_conversion_rate     = data.fix_conversion_rate;
        this.type                    = data.type;
        this.rotation_type           = data.rotation_type;
        this.allow_to_backbutton     = data.allow_to_backbutton;
        this.enabled_firewall        = data.enabled_firewall;
        this.inherit_back_url        = data.inherit_back_url;
        this.inherit_repostback_url  = data.inherit_repostback_url;
        this.is_enabled_quality_page = data.is_enabled_quality_page;
        this.money                   = new ZoneMoney(data.money);
        this.user                    = new User(data.user);
        this.firewall_campaign       = data.firewall_campaign ? new Campaign(data.firewall_campaign) : null;
        this.main_campaigns_groups   = data.main_campaigns_groups ? _.map(data.main_campaigns_groups, (group) => new CampaignGroup(group)) : [];
        this.main_campaigns          = data.main_campaigns ? _.map(data.main_campaigns, (campaign) => new Campaign(campaign)) : [];
        this.backup_campaigns_groups = data.backup_campaigns_groups ? _.map(data.backup_campaigns_groups, (group) => new CampaignGroup(group)) : [];
        this.backup_campaigns        = data.backup_campaigns ? _.map(data.backup_campaigns, (campaign) => new Campaign(campaign)) : [];
        this.created_at              = new Date(data.created_at);
        this.updated_at              = new Date(data.updated_at);
    }
}
