import _ from 'lodash';
import axios from "axios/index";
import S2SRequest from "./Structs/S2SRequest";

export default class S2SRequestAPI {

    /**
     * API URL для работы с s2s requests
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/manager/s2s-requests';
    };

    /**
     * Возвращает список s2s requests
     *
     * @param {Function} successCallback
     * @param {Function} errorCallback
     * @return {Promise}
     */
    static list(successCallback, errorCallback) {
        return axios.get(S2SRequestAPI.link)
            .then(({data}) => _.map(data.data, (s2s) => new S2SRequest(s2s)))
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Сохранение данных о s2s request
     *
     * @param data
     * @param successCallback
     * @param errorCallback
     * @return {Promise}
     */
    static save(data, successCallback, errorCallback) {
        return axios.post(S2SRequestAPI.link, data)
            .then(({data}) => new S2SRequest(data))
            .then(successCallback)
            .catch(errorCallback);
    }
}
