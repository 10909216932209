import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from "prop-types";
import Form from "./Form";
import User from "../../../api-data/Manager/Structs/User";
import ListAPI from '../../../api-data/Manager/ListAPI';
import DomainTypes from '../../../api-data/Enums/DomainTypes';
import SelectOptions from '../../../components/Select/SelectOptions';
import DangerEvent from '../../../components/Alerts/DangerEvent';
import Loading from '../../../components/Loading';
import NoDomainsError from './NoDomainsError';

/**
 * Модальное окно с формой создания преленда
 */
export default class CreateModal extends Component {

    state = {
        isReady: false,
        domainsList: new SelectOptions(),
    };

    componentWillMount() {
        ListAPI.domains(DomainTypes.Prelanding)
            .then((domains) => this.setState({domainsList: SelectOptions.createBy(domains, 'id', 'domain')}))
            .catch(DangerEvent.onSystemError)
            .finally(() => this.setState({isReady: true}));
    }

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        const hasDomains = this.state.domainsList.length() > 0;

        return (
            <div className="static-modal">
                <Modal show={this.props.show} onHide={this.handleClose}>
                    <Modal.Header>
                        <Modal.Title>Create new prelanding</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {hasDomains &&
                            <Form user={this.props.user} domains={this.state.domainsList} handleSave={this.props.handleSave} />
                        }
                        {!hasDomains &&
                            <NoDomainsError />
                        }
                    </Modal.Body>

                    <div className="clearfix"/>

                    <Modal.Footer>
                        <button onClick={this.props.handleClose} className='btn btn-default modal-footer__button modal-footer__button_left'>
                            &nbsp;<span className='glyphicon glyphicon-remove' />&nbsp;
                        </button>
                        {hasDomains &&
                            <button className='btn btn-primary pull-right modal-footer__button modal-footer__button_right' type='submit' form='prelanding-form'>
                                &nbsp;<span className='glyphicon glyphicon-ok' />&nbsp;
                            </button>
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

CreateModal.propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
};

