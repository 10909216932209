export default class InvoiceResource {

    id         = 0;
    amount     = 0;
    /** @type {Date} */
    date       = null;
    /** @type {Date} */
    created_at = null;

    constructor(data) {
        this.id         = data.id;
        this.amount     = data.amount;
        this.date       = new Date(data.date);
        this.created_at = new Date(data.created_at);
    }
}
