import SelectOptions from '../SelectOptions';
import UserRoles from '../../../api-data/Enums/UserRoles';

export default class RolesOptions extends SelectOptions {

    constructor() {
        const options = [
            {value: UserRoles.publisher, label: 'Publisher'}, {value: UserRoles.advertiser, label: 'Advertiser'},
            {value: UserRoles.manager, label: 'Manager'}, {value: UserRoles.headManager, label: 'Head Manager'}
        ];

        super(options);
    }
}