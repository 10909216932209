import React, {Component} from "react";
import PropTypes from "prop-types";

export default class CopyToClipboard extends Component {

    textArea = null;

    state = {
        isClicked: false,
    };

    copy = () => {
        this.textArea.select();
        document.execCommand("copy");

        this.setState({isClicked: true});
    };

    render() {
        const content = this.state.isClicked
            ? <span><span>Copied&nbsp;</span><span className='glyphicon glyphicon-copy'/></span>
            : <span className='glyphicon glyphicon-copy'/>;

        return (
            <div className="input-group copy-to-clipboard copy-to-clipboard__table-row">
                <textarea
                    ref={(textarea) => this.textArea = textarea}
                    value={this.props.value}
                    onChange={() => {}}
                    className='hidden-textarea'
                />
                <input type="text" value={this.props.value} className="form-control" disabled />
                <div className="input-group-btn">
                    <button className={this.state.isClicked ? 'btn btn-success': 'btn btn-warning'} type='button' onClick={this.copy}>
                        {content}
                    </button>
                </div>
            </div>
        );
    }
}

CopyToClipboard.propTypes = {
    value: PropTypes.string.isRequired,
};
