import React from 'react';
import PropTypes from 'prop-types';
import BaseForm from '../../../../components/BaseForm';
import SelectOptions from '../../../../components/Select/SelectOptions';
import DangerEvent from '../../../../components/Alerts/DangerEvent';
import ListAPI from '../../../../api-data/Manager/ListAPI';
import Select from '../../../../components/Select/Select';
import CampaignPriceModels from '../../../../api-data/Enums/CampaignPriceModels';
import CampaignFormats from '../../../../api-data/Enums/CampaignFormats';

export default class SearchHeader extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            submit: {
                id:                props.defaultValues.id,
                name:              props.defaultValues.name,
                user_id:           props.defaultValues.user_id,
                campaign_group_id: props.defaultValues.campaign_group_id,
                format:            props.defaultValues.format,
                price_model:       props.defaultValues.price_model,
            },

            advertisersList: new SelectOptions(),
            groupsList: new SelectOptions(),
        };
    }

    componentWillMount() {
        ListAPI.campaignsGroups()
            .then((groups) => SelectOptions.createByIdPrettyName(groups))
            .then((options) => this.setState({groupsList: options}))
            .catch(DangerEvent.onSystemError);
        ListAPI.advertisers()
            .then((advertisers) => SelectOptions.createByIdPrettyName(advertisers))
            .then((options) => this.setState({advertisersList: options}))
            .catch(DangerEvent.onSystemError);
    }

    /**
     * При нажатии на энтер, отправляем форму
     */
    onKeyPressEnter = () => this.submitSearchParams();

    /**
     * Передаем в родительский компонент параметры поиска
     * @return {*}
     */
    submitSearchParams = () => this.props.handleFilters(this.state.submit);

    render () {
        const submit = this.state.submit;

        return (
            <thead>
            <tr className='campaign-table__head'>
                <td className='campaign-table__head_id table__head-filtered'>
                    <input
                        type='text'
                        name='id'
                        value={submit.id}
                        onKeyPress={this.onKeyPress}
                        onBlur={this.submitSearchParams}
                        onChange={this.handleChangeInt}
                        className="form-control"
                        placeholder='ID'
                    />
                </td>
                <td className='campaign-table__head_name table__head-filtered'>
                    <input
                        type='text'
                        name='name'
                        value={submit.name}
                        onKeyPress={this.onKeyPress}
                        onBlur={this.submitSearchParams}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder='Campaign name'
                    />
                </td>
                <td className='campaign-table__head_advertiser table__head-filtered'>
                    <Select
                        name="user_id"
                        value={submit.user_id}
                        onChange={(name, value) => this.updateSubmitState(name, value, this.submitSearchParams)}
                        options={this.state.advertisersList}
                        clearable={true}
                        searchable={true}
                        placeholder='Advertiser'
                    />
                </td>
                <td className='campaign-table__head_group table__head-filtered'>
                    <Select
                        name="campaign_group_id"
                        value={submit.campaign_group_id}
                        onChange={(name, value) => this.updateSubmitState(name, value, this.submitSearchParams)}
                        options={this.state.groupsList}
                        clearable={true}
                        searchable={true}
                        placeholder='Group name'
                    />
                </td>
                <td className='campaign-table__head_format table__head-filtered'>
                    <Select
                        name="format"
                        value={submit.format}
                        onChange={(name, value) => this.updateSubmitState(name, value, this.submitSearchParams)}
                        options={new SelectOptions([
                            {value: CampaignFormats.direct, label: CampaignFormats.direct},
                            {value: CampaignFormats.prelanding, label: CampaignFormats.prelanding}
                        ])}
                        clearable={true}
                        searchable={true}
                        placeholder='Format'
                    />
                </td>
                <td className='campaign-table__head_pm table__head-filtered'>
                    <Select
                        name="price_model"
                        value={submit.price_model}
                        onChange={(name, value) => this.updateSubmitState(name, value, this.submitSearchParams)}
                        options={new SelectOptions([
                            {value: CampaignPriceModels.CPA, label: CampaignPriceModels.CPA},
                            {value: CampaignPriceModels.CPM, label: CampaignPriceModels.CPM},
                        ])}
                        clearable={true}
                        searchable={true}
                        placeholder='PM'
                    />
                </td>
                <td className='campaign-table__head_daily-limit-spent hidden-sm'>Daily $</td>
                <td className='campaign-table__head_total-limit-spent hidden-sm'>Total $</td>
                <td className='campaign-table__head_status'>Status</td>
                <td className='campaign-table__head_actions'>Actions</td>
            </tr>
            </thead>
        );
    }
}

SearchHeader.propTypes = {
    handleFilters: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
};