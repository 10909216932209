import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import CloneForm from './Form';
import PropTypes from "prop-types";
import User from "../../../api-data/Manager/Structs/User";
import {Redirect} from "react-router";
import SuccessEvent from '../../../components/Alerts/SuccessEvent';

/**
 * Компонент редактирования кампании
 */
export default class Clone extends Component {

    state = {
        campaign: null,
        cloned: false,
    };

    /**
     * @param data
     */
    handleSubmit = ({data}) => this.setState({campaign: data}, SuccessEvent.created);

    render() {
        if (this.state.cloned && this.state.campaign) {
            return <Redirect to={`/campaigns/${this.state.campaign.id}`} />;
        }

        return (
            <Panel>
                <Panel.Body className='manager-campaign-settings-panel'>
                    <CloneForm user={this.props.authUser} id={this.props.id} isClone={true} handleSubmit={this.handleSubmit} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <NavLink to='/campaigns' className='btn btn-default panel-footer__button panel-footer__button_left'>
                        Cancel
                    </NavLink>
                    <button
                        className='btn btn-primary pull-right panel-footer__button panel-footer__button_right'
                        type='submit'
                        form='campaign-form'
                        onClick={() => this.setState({cloned: true})}
                    >
                        Clone campaign
                    </button>
                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}

Clone.propTypes = {
    id: PropTypes.number.isRequired,
    authUser: PropTypes.instanceOf(User).isRequired,
};

