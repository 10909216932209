import React from 'react';
import BaseForm from "../../../components/BaseForm";
import PropTypes from "prop-types";
import qs from "query-string";
import ListAPI from "../../../api-data/Manager/ListAPI";
import SelectOptions from "../../../components/Select/SelectOptions";
import Select from "../../../components/Select/Select";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import InvoiceAPI from '../../../api-data/Manager/InvoiceAPI';

/**
 * Форма получения данных о биллинге в системе
 */
export default class SearchInvoicesForm extends BaseForm {

    constructor(props) {
        super(props);

        const query = qs.parse(props.search);
        this.state  = {
            submit:    {
                users: Array.isArray(query['users[]'])
                    ? query['users[]']
                    : (query['users[]'] ? [query['users[]']] : []),
            },
            usersList: new SelectOptions(),
        };
    }

    searchInvoices = () => {
        return InvoiceAPI.list(this.state.submit)
            .then(this.props.updateInvoices)
            .catch(this.errorCallback);
    };

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        this.searchInvoices()
            .then(() => this.updateUrlParams(this.state.submit));
    };

    /**
     * При инициализации компонента загружаем:
     * - список пользователей
     */
    componentDidMount() {
        ListAPI.users()
            .then((users) => this.setState({usersList: SelectOptions.createByIdPrettyName(users)}))
            .catch(DangerEvent.onSystemError);

        this.searchInvoices();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.needSearch !== prevProps.needSearch) {
            this.searchInvoices();
        }
    }

    render() {
        return (
            <form className="invoices-form" id="invoices-form" onSubmit={this.handleSubmit}>
                <div className="form-group" id="users">
                    <Select
                        name="users"
                        value={this.state.submit.users}
                        options={this.state.usersList}
                        placeholder='Select users...'
                        multi={true}
                        clearable={true}
                        searchable={true}
                        onChange={this.updateSubmitState}
                    />
                    <small className="text-danger"/>
                </div>
            </form>
        );
    }
}

SearchInvoicesForm.propTypes = {
    updateInvoices: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    search: PropTypes.string.isRequired,
    needSearch: PropTypes.bool.isRequired,
};
