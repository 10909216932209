import React from 'react';
import SimpleHeader from './SimpleHeader';
import Body from './Body';
import {Table} from 'react-bootstrap';
import SearchHeader from './SearchHeader';

const UsersList = {
    Table:        (props) => <Table {...props} bordered condensed hover className='user-table' />,
    SimpleHeader: SimpleHeader,
    SearchHeader: SearchHeader,
    Body:         Body,
};

export default UsersList;