/**
 * Структура, содержащая единицу данных о статистике
 */
export default class StatsData {

    group_by                    = '';
    impressions                 = 0;
    bot_impressions             = 0;
    hosting_impressions         = 0;
    iframe_impressions          = 0;
    proxy_impressions           = 0;
    inapp_impressions           = 0;
    conversions                 = 0;
    paid                        = 0;
    ecpm                        = 0;
    cr                          = 0;
    impressions_percent         = 0;
    bot_impressions_percent     = 0;
    hosting_impressions_percent = 0;
    iframe_impressions_percent  = 0;
    proxy_impressions_percent   = 0;
    inapp_impressions_percent   = 0;

    constructor(data) {
        this.group_by                    = data.group_by || '';
        this.impressions                 = data.impressions || 0;
        this.bot_impressions             = data.bot_impressions || 0;
        this.hosting_impressions         = data.hosting_impressions || 0;
        this.iframe_impressions          = data.iframe_impressions || 0;
        this.proxy_impressions           = data.proxy_impressions || 0;
        this.inapp_impressions           = data.inapp_impressions || 0;
        this.conversions                 = data.conversions || 0;
        this.paid                        = data.paid || 0;
        this.ecpm                        = data.ecpm || 0;
        this.cr                          = data.cr || 0;
        this.impressions_percent         = data.impressions_percent || 0;
        this.bot_impressions_percent     = data.bot_impressions_percent || 0;
        this.hosting_impressions_percent = data.hosting_impressions_percent || 0;
        this.iframe_impressions_percent  = data.iframe_impressions_percent || 0;
        this.proxy_impressions_percent   = data.proxy_impressions_percent || 0;
        this.inapp_impressions_percent   = data.inapp_impressions_percent || 0;
    }
}
