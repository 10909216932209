import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * Кнопка удаления
 */
export default class DeleteButton extends Component {

    confirmDelete = () => {
        if (window.confirm('Are you sure, you want delete this item?')) {
            this.props.callback();
        }
    };

    render() {
        const className = this.props.className || 'btn btn-danger';

        return (
            <button onClick={this.confirmDelete} type='button' className={className}>
                <span className='glyphicon glyphicon-trash'/>
            </button>
        );
    }
}

DeleteButton.propTypes = {
    callback: PropTypes.func.isRequired,
};
