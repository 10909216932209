import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button, MenuItem, SplitButton} from "react-bootstrap";
import StatisticsGroupBy from "../../../../api-data/Enums/StatisticsGroupBy";
import _ from "lodash";

export default class GroupByButton extends Component {

    handleChange = (e) => {
        e.preventDefault();

        this.props.onClick(e.target.value);
    };

    render() {
        const className = this.props.active ? 'active': '';
        const title     = this.props.title;
        const id        = Math.random();

        // Отображаем только кнопку, без выпадающего списка в том случае,
        // Если пока ничего не выбрано или это активный фильтр
        // Это сделано для того, чтобы не мудрить с удалением из отмеченного списка и выпадающего
        if (this.props.items.length === 0 || this.props.active) {
            return <Button onClick={this.handleChange} className={className} id={id} value={title}>{title}</Button>;
        }

        let items = this.props.items;

        return (
            <SplitButton onClick={this.handleChange} className={className} title={title} id={id} value={title}>
                {_.map(items, (item, i) => {
                    const value = item instanceof Object ? item.pretty_name: item;
                    const id    = item instanceof Object ? item.id: item;

                    return (
                        <MenuItem eventKey={i} key={i} onClick={() => this.props.onCleanItem(title, id)} >
                            <span aria-hidden="true">×</span>&nbsp;{value || 'Empty'}
                        </MenuItem>
                    );
                })}
                <MenuItem divider />
                <MenuItem eventKey={this.props.items.length} key={this.props.items.length} onClick={() => this.props.onCleanAll(title)}>
                    <span aria-hidden="true">×</span>&nbsp;Clean all
                </MenuItem>
            </SplitButton>
        );
    }
}

GroupByButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    title: PropTypes.oneOf(StatisticsGroupBy.list).isRequired,
    items: PropTypes.array.isRequired,
    onCleanItem: PropTypes.func.isRequired,
    onCleanAll: PropTypes.func.isRequired,
};
