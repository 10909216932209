import axios from "axios/index";

/**
 * Компонент предоставляющий доступ к словарям
 */
export default class ListAPI {

    /**
     * API URL для работы со словарями
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/advertiser/lists';
    };

    /**
     * Получение списка кампаний
     *
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static campaigns(successCallback, errorCallback) {
        axios.get(ListAPI.link + '/campaigns')
            .then((response) => response.data)
            .then(successCallback)
            .catch(errorCallback);
    }
}
