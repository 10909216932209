import axios from "axios/index";
import _ from "lodash";
import Zone from "./Structs/Zone";

/**
 * Компонент для получения данных о зоне/ах в системе
 */
export default class ZoneAPI {

    /**
     * API URL для работы с зонами
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/manager/zones';
    };

    /**
     * Сохранение данных о зоне
     * Если эта зона была ранее созадана, тогда обновляет ее данные
     *
     * @param {Object} zone
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static save(zone, successCallback, errorCallback) {
        let request = (zone.id)
            ? axios.put(ZoneAPI.link + '/' + zone.id, zone)
            : axios.post(ZoneAPI.link, zone);

        request
            .then(({data}) => successCallback(new Zone(data)))
            .catch(errorCallback);
    }

    /**
     * Получение списка зон
     *
     * @param {Object} params
     *
     * @return {Promise}
     */
    static list(params) {
        return axios.get(ZoneAPI.link, {params: params})
            .then(({data}) => {
                return {
                    current_page: data.current_page,
                    last_page:    data.last_page,
                    zones:        _.map(data.data, (zone) => new Zone(zone)),
                };
            });
    }

    /**
     * Получение зоны по id
     *
     * @param {Number} id
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static getById(id, successCallback, errorCallback) {
        axios.get(ZoneAPI.link + '/' + id)
            .then(({data}) => new Zone(data))
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Удаление зоны по id
     *
     * @param {Number} id
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static delete(id, successCallback, errorCallback) {
        return axios.delete(ZoneAPI.link + '/' + id)
            .then(successCallback)
            .catch(errorCallback);
    }
}
