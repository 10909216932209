import numeral from 'numeral';

/**
 * Преобразовывает число к удобочитаемому формату
 */
export default function Number (number, precision) {
    switch (precision) {
        case 4:
            return numeral(number).format('0,0.0000');
        case 3:
            return numeral(number).format('0,0.000');
        case 2:
            return numeral(number).format('0,0.00');
        case 1:
            return numeral(number).format('0,0.0');
        case 0:
        default:
            return numeral(number).format('0,0');
    }
}
