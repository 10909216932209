import React, {Component} from 'react';
import PropTypes from "prop-types";
import StatisticsGroupBy from "../../../../api-data/Enums/StatisticsGroupBy";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import StatisticsColumns from "../../../../api-data/Enums/StatisticsColumns";

/**
 * Шапка таблицы
 */
export default class Header extends Component {

    render () {
        return (
            <tr>
                <th className={'publisher-statistics-table__header__group-by publisher-statistics-table__header__group-by-' + this.props.group_by} onClick={() => this.props.sortBy(StatisticsColumns.GroupBy)} colSpan={2}>{this.props.group_by}</th>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.Impressions}>Direct Impressions Count</Tooltip>}>
                    <th className='publisher-statistics-table__header__impressions' onClick={() => this.props.sortBy(StatisticsColumns.Impressions)}>Impressions</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.Conversions}>Conversions Count</Tooltip>}>
                    <th className='publisher-statistics-table__header__conversions' onClick={() => this.props.sortBy(StatisticsColumns.Conversions)}>Conversions</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.CR}>Conversion Rate</Tooltip>}>
                    <th className='publisher-statistics-table__header__cr' onClick={() => this.props.sortBy(StatisticsColumns.CR)}>CR</th>
                </OverlayTrigger>
                <th className='publisher-statistics-table__header__paid' onClick={() => this.props.sortBy(StatisticsColumns.Paid)}>Paid</th>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.ECPM}>Effective CPM</Tooltip>}>
                    <th className='publisher-statistics-table__header__ecpm_pub' onClick={() => this.props.sortBy(StatisticsColumns.ECPM)}>eCPM</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BotImpressions}>Bots Impressions Percent</Tooltip>}>
                    <th className='publisher-statistics-table__header__bot_impressions' onClick={() => this.props.sortBy(StatisticsColumns.BotImpressions)}>Bots</th>
                </OverlayTrigger>
            </tr>
        );
    }
}

Header.propTypes = {
    group_by: PropTypes.oneOf(StatisticsGroupBy.publisherList).isRequired,
    sortBy: PropTypes.func.isRequired,
};
