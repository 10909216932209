import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import ColorNumber from "../../../../components/ColorNumber";
import StatsData from "../../../../api-data/Publisher/Structs/StatsData";
import StatisticsGroupBy from "../../../../api-data/Enums/StatisticsGroupBy";
import Number from "../../../../components/Formatting/Number";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Checkbox from "../../../../components/Checkbox";
import StatisticsColumns from "../../../../api-data/Enums/StatisticsColumns";

/**
 * Список рядов таблицы статистки
 */
export default class Body extends Component {
    render () {
        return _.map(this.props.statistics, (statistics, key) => {
            let groupBy = statistics.group_by;

            return (
                <tr className={this.props.isSelectedRow(groupBy) ? 'selected': ''} key={key}>
                    <td className='check-column'>
                        <Checkbox onChange={() => this.props.updateSelection(groupBy)} isChecked={this.props.isSelectedRow(groupBy)} />
                    </td>
                    <td className='group_by-column'>{groupBy}</td>
                    <td>{Number(statistics.impressions)}</td>
                    <td>{Number(statistics.conversions)}</td>
                    <td>{Number(statistics.cr, 4)}%</td>
                    <td>
                        <ColorNumber value={statistics.paid} precision={2} postfix='$'/>
                    </td>
                    <td>{Number(statistics.ecpm, 4)}$</td>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.BotImpressions}>{Number(statistics.bot_impressions)}</Tooltip>}>
                        <td>{Number(statistics.bot_impressions_percent)}%</td>
                    </OverlayTrigger>
                </tr>
            )
        });
    }
}

Body.propTypes = {
    group_by: PropTypes.oneOf(StatisticsGroupBy.publisherList).isRequired,
    isSelectedRow: PropTypes.func.isRequired,
    statistics: PropTypes.arrayOf(PropTypes.instanceOf(StatsData)).isRequired,
};
