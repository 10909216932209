/**
 * Структура, содержащая информацию о кампании
 */
import CampaignPriceModels from "../../Enums/CampaignPriceModels";
import CampaignFormats from "../../Enums/CampaignFormats";
import CampaignStatuses from "../../Enums/CampaignStatuses";
import CampaignMoney from "../../Structs/CampaignMoney";

export default class Campaign {

    id                  = 0;
    name                = '';
    pretty_name         = '';
    link                = '';
    postback_link       = '';
    rate                = 0;
    daily_limit         = 0;
    total_limit         = 0;
    frequency           = '';
    status              = '';
    format              = '';
    price_model         = '';
    allow_to_backbutton = false;
    is_unlimited_daily  = true;
    is_unlimited_total  = true;
    money               = null;
    created_at          = '';
    updated_at          = '';

    constructor(data) {
        this.id                  = data.id;
        this.name                = data.name;
        this.pretty_name         = data.pretty_name;
        this.link                = data.link;
        this.postback_link       = data.postback_link;
        this.rate                = data.rate;
        this.daily_limit         = data.daily_limit;
        this.total_limit         = data.total_limit;
        this.frequency           = data.frequency;
        this.status              = data.status || CampaignStatuses.Paused;
        this.format              = data.format || CampaignFormats.direct;
        this.price_model         = data.price_model || CampaignPriceModels.CPA;
        this.allow_to_backbutton = data.allow_to_backbutton;
        this.is_unlimited_daily  = data.is_unlimited_daily;
        this.is_unlimited_total  = data.is_unlimited_total;
        this.money               = new CampaignMoney(data.money);
        this.created_at          = data.created_at;
        this.updated_at          = data.updated_at;
    }
}
