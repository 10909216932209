import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import CreateForm from './Form';
import SuccessEvent from "../../../components/Alerts/SuccessEvent";

export default class Create extends Component {

    state = {
        newZoneAfterSubmit: false
    };

    /**
     * @param {Zone} data
     */
    handleSubmit = (data) => {
        SuccessEvent.created();

        if (this.state.newZoneAfterSubmit) {
            this.props.history.push('/temporary');
            this.props.history.goBack();
        } else {
            this.props.history.push(`/zones/${data.id}`);
        }
    };

    render() {
        return (
            <Panel>
                <Panel.Body className='manager-zone-settings-panel'>
                    <CreateForm handleSubmit={this.handleSubmit} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <NavLink to='/zones' className='btn btn-default panel-footer__button panel-footer__button_left'>
                        &nbsp;<span className='glyphicon glyphicon-remove' />&nbsp;
                    </NavLink>
                    <div className='panel-footer__button-group panel-footer__button-group_right btn-group'>
                        <button className='btn btn-primary' type='submit' form='zone-form'>
                            &nbsp;<span className='glyphicon glyphicon-ok' />&nbsp;
                        </button>
                        <button className='btn btn-success' type='submit' form='zone-form' onClick={() => {
                            this.setState({newZoneAfterSubmit: true});
                        }}>
                            <span className='glyphicon glyphicon-chevron-right'/>
                            <span className='glyphicon glyphicon-chevron-right'/>
                        </button>
                    </div>
                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}
