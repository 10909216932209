import axios from "axios/index";
import _ from 'lodash';
import User from "./Structs/User";
import UserCommentResource from './Structs/UserCommentResource';

/**
 * Компонент для получения данных о пользователе в системе
 */
export default class UserAPI {

    static get link () {
        return process.env.REACT_APP_API_URL + '/manager/users';
    };

    /**
     * Сохранение данных о пользователе
     * Если этот пользователь была ранее созадан, тогда обновляем его данные
     *
     * @param {User} user
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static save(user, successCallback, errorCallback) {
        let request;

        if (user.id) {
            // Если при обновлении данных пользователя, пароль не был указан
            // Тогда удаляем это поле, чтобы не применялась валидация этого поля
            if (!user.password) {
                delete user.password;
            }

            request = axios.put(UserAPI.link + '/' + user.id, user);
        } else {
            request = axios.post(UserAPI.link, user);
        }

        request.then(successCallback).catch(errorCallback);
    }

    /**
     * Получение пользователя по id
     *
     * @param id
     */
    static getById(id) {
        return axios.get(UserAPI.link + '/' + id)
            .then(({data}) => new User(data))
    }

    /**
     * Удаление пользователя по id
     *
     * @param id
     * @param successCallback
     * @param errorCallback
     */
    static delete(id, successCallback, errorCallback) {
        axios.delete(UserAPI.link + '/' + id)
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Получение списка паблишеров
     */
    static list(params) {
        return axios.get(UserAPI.link, {params: params})
            .then(({data}) => {
                return {
                    current_page: data.current_page,
                    last_page: data.last_page,
                    users: _.map(data.data, (user) => new User(user)),
                };
            });
    }

    /**
     * Получение списка комментариев
     */
    static comments(userId) {
        return axios.get(UserAPI.link + `/${userId}/comments`)
            .then(({data}) => _.map(data, (comment) => new UserCommentResource(comment)));
    }

    /**
     * Сохранение комментария
     */
    static storeComment(userId, data) {
        return axios.post(UserAPI.link + `/${userId}/comments`, data)
            .then(({data}) => new UserCommentResource(data));
    }
}
