/**
 * Структура, содержащая единицу данных о постбек-отчетах
 */
export default class PostbacksReport {

    id         = 0;
    name       = '';
    file_path  = null;
    status     = '';
    created_at = '';
    updated_at = '';

    constructor(data) {
        this.id         = data.id;
        this.name       = data.name;
        this.file_path  = data.file_path || null;
        this.status     = data.status;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
    }
}
