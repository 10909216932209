import {Alert} from "react-bootstrap";
import React, {Component} from 'react';

export default class SuccessEvent extends Component {

    static success() {
        const alert = <Alert bsStyle="success" key={new Date().getTime()}>Success!</Alert>;

        document.dispatchEvent(new CustomEvent('alert-notification', {detail: {alert: alert, duration: 3000}}));
    }

    static created() {
        const alert = <Alert bsStyle="success" key={new Date().getTime()}>Created successful!</Alert>;

        document.dispatchEvent(new CustomEvent('alert-notification', {detail: {alert: alert, duration: 3000}}));
    }
}
