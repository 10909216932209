import React, {Component} from 'react';

export default class SimpleHeader extends Component {

    render () {
        return (
            <thead>
            <tr className='campaign-table__head'>
                <td className='campaign-table__head_id'>ID</td>
                <td className='campaign-table__head_name'>Name</td>
                <td className='campaign-table__head_advertiser'>Advertiser</td>
                <td className='campaign-table__head_group'>Group</td>
                <td className='campaign-table__head_format'>Format</td>
                <td className='campaign-table__head_pm'>PM</td>
                <td className='campaign-table__head_daily-limit-spent hidden-sm'>Daily $</td>
                <td className='campaign-table__head_total-limit-spent hidden-sm'>Total $</td>
                <td className='campaign-table__head_status'>Status</td>
                <td className='campaign-table__head_actions'>Actions</td>
            </tr>
            </thead>
        );
    }
}