import AuthAPI from "../../api-data/AuthAPI";
import DangerEvent from "../../components/Alerts/DangerEvent";

/**
 * @return {string}
 */
export default function Logout() {

    AuthAPI.logout(() => window.location = '/login', DangerEvent.onSystemError);

    return '';
}
