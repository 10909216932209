import React, {Component} from 'react';
import PropTypes from "prop-types";

export default class Progressbar extends Component {

    percentOf = (val, maxVal) => {
        return val >= maxVal ? 100: 100 / (maxVal / val);
    };

    styleByPercent = (percent) => {
        if (percent >= 90) {
            return 'danger';
        } else if (percent >= 70) {
            return 'warning';
        }

        return 'success';
    };

    render() {
        let {value, max} = this.props;
            max          = max || 0;
            value        = value || 0;
        let percent      = this.percentOf(value, max);
        let style        = this.styleByPercent(percent);
        let content      = '';

        if (this.props.percent && !this.props.unlimited) {
            content = (
                <div className={"progress-bar progress-bar-" + style} role="progressbar" aria-valuenow={value} aria-valuemin={0} aria-valuemax={max} style={{width: percent + '%'}}>
                    <span className='percent'>{ Math.round(percent) }%</span>
                    <span className='value'>{ value }$ / {max}$</span>
                </div>
            );
        } else if (this.props.unlimited === true) {
            content = (
                <div className={"progress-bar progress-bar-info"} role="progressbar" style={{width: '100%'}}>
                    {value}$
                </div>
            );
        } else {
            content = (
                <div className={"progress-bar progress-bar-" + style} role="progressbar" aria-valuenow={value} aria-valuemin={0} aria-valuemax={max} style={{width: percent + '%'}}>
                    { value }$ / {max}$
                </div>
            );
        }

        return (
            <div className="progress">
                {content}
            </div>
        );
    }
}

Progressbar.propTypes = {
    value:     PropTypes.number,
    max:       PropTypes.number,
    unlimited: PropTypes.bool,
    percent:   PropTypes.bool,
};
