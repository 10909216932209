import React, {Component} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import _ from 'lodash';
import ShowButton from "../../../buttons/ShowButton";
import StatisticsButton from "../../../buttons/StatisticsButton";
import Progressbar from "../../../components/Progressbar";
import Campaign from "../../../api-data/Advertiser/Structs/Campaign";
import CampaignLink from "../../../components/ObjectLinks/CampaignLink";
import CampaignStatusIco from "../../../components/CampaignStatusIco";
import {NavLink} from "react-router-dom";
import StatisticsGroupBy from "../../../api-data/Enums/StatisticsGroupBy";

import '../../../styles/css/campaigns/list.css';

/**
 * Информация о кампании
 */
class CampaignRow extends Component {

    render() {
        const {campaign} = this.props;

        return (
            <tr>
                <td>{campaign.id}</td>
                <td className='cut-off'>
                    <CampaignLink id={campaign.id} name={campaign.name}/>
                </td>
                <td>
                    <Progressbar
                        value={campaign.money.daily_limit_spent}
                        max={campaign.money.daily_limit}
                        unlimited={campaign.money.is_unlimited_daily}
                    />
                </td>
                <td>
                    <Progressbar
                        value={campaign.money.total_limit_spent}
                        max={campaign.money.total_limit}
                        unlimited={campaign.money.is_unlimited_total}
                    />
                </td>
                <td className='campaign-table__body_status'>
                    <CampaignStatusIco status={campaign.status}/>
                </td>
                <td>{campaign.format}</td>
                <td>{campaign.price_model}</td>
                <td>
                    <div className='btn-group btn-group-xs pull-right'>
                        <ShowButton route={'/campaigns/' + campaign.id}/>
                        <StatisticsButton route={`/statistics?${StatisticsGroupBy.campaign}[]=${campaign.id}`}/>
                        <NavLink to={`/postbacks?campaigns[]=${campaign.id}`} className='btn btn-primary'>
                            <span className='glyphicon glyphicon-transfer'/>
                        </NavLink>
                    </div>
                </td>
            </tr>
        );
    }
}

CampaignRow.propTypes = {
    campaign: PropTypes.object.isRequired,
};

/**
 * Возвращает список рядов таблицы
 */
class CampaignsList extends Component {

    render() {
        return _.map(this.props.list, (campaign) => <CampaignRow campaign={campaign} key={campaign.id} />);
    }
}

CampaignsList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.instanceOf(Campaign)).isRequired,
};

/**
 * Таблица со списком кампаний
 */
export default class CampaignsListTable extends Component {

    render () {
        return (
            <Table bordered condensed hover className='campaign-table'>
                <thead>
                <tr className='campaign-table__head'>
                    <td className='campaign-table__head_id'>ID</td>
                    <td className='campaign-table__head_name'>Name</td>
                    <td className='campaign-table__head_daily-limit-spent hidden-sm'>Daily limit spent $</td>
                    <td className='campaign-table__head_total-limit-spent hidden-sm'>Total limit spent $</td>
                    <td className='campaign-table__head_status'>Status</td>
                    <td className='campaign-table__head_format'>Format</td>
                    <td className='campaign-table__head_pm'>PM</td>
                    <td className='campaign-table__head_actions'>Actions</td>
                </tr>
                </thead>

                <tbody>
                <CampaignsList list={this.props.list} />
                </tbody>
            </Table>
        );
    }
}

CampaignsListTable.propTypes = {
    list: PropTypes.arrayOf(PropTypes.instanceOf(Campaign)).isRequired
};
