/**
 * Структура, содержащая информацию о пользователе
 */
import _ from 'lodash';
import Manager from "../../Structs/Manager";
import Zone from "./Zone";

export default class User {
    id          = 0;
    name        = '';
    pretty_name = '';
    email       = '';
    balance     = 0;
    role        = '';
    manager_id  = null;
    manager     = null;
    campaigns   = [];
    created_at  = '';

    constructor(data) {
        this.id          = data.id;
        this.name        = data.name;
        this.pretty_name = data.pretty_name;
        this.email       = data.email;
        this.balance     = data.balance;
        this.role        = data.role;
        this.manager_id  = data.manager_id || null;
        this.manager     = data.manager ? new Manager(data.manager) : null;
        this.zones       = data.zones ? _.map(data.zones, (zone) => new Zone(zone)) : [];
        this.created_at  = data.created_at;
    }
}
