import _ from 'lodash';
import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Panel, Table} from 'react-bootstrap';
import CampaignAPI from "../../../api-data/Advertiser/CampaignAPI";
import Progressbar from "../../../components/Progressbar";
import Loading from "../../../components/Loading";
import '../../../styles/css/campaigns/show.css';
import StatisticsGroupBy from "../../../api-data/Enums/StatisticsGroupBy";
import DangerEvent from "../../../components/Alerts/DangerEvent";

/**
 * Компонент преобразовывает список стран кампании в таблицу
 *
 * @param {CampaignCountry[]} countries
 * @return {String}
 * @constructor
 */
function CountryList({countries}) {
    const body   = _.map(countries, (country, key) => {
        return (
            <tr key={key}>
                <td>{country.pretty_name}</td>
                <td className='text-success'>{country.rate}$</td>
            </tr>
        )}
    );

    return (
        <Table bordered hover className='campaign-show campaign-show__country-rate-table'>
            <thead>
            <tr>
                <th className='campaign-show__country-rate-table__name'>Name</th>
                <th className='campaign-show__country-rate-table__rate'>Rate</th>
            </tr>
            </thead>

            <tbody>
            {body}
            </tbody>
        </Table>
    );
}

/**
 * Компонент отображения информации о кампании
 */
export default class Show extends Component {

    state = {
        isReady: false,
        campaign: null,
    };

    /**
     * Загрузка кампании по id
     */
    componentWillMount() {
        CampaignAPI.getById(this.props.id, (campaign) => this.setState({campaign: campaign, isReady: true}), DangerEvent.onSystemError);
    }

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <NavLink to='/campaigns' className='btn btn-default panel-heading__button panel-heading__button_left'>
                        <span className='glyphicon glyphicon-chevron-left'/>
                        <span className='glyphicon glyphicon-chevron-left'/>
                    </NavLink>
                    <div className='panel-heading__button-group panel-heading__button-group_right btn-group'>
                        <NavLink to={`/statistics?${StatisticsGroupBy.campaign}[]=${this.state.campaign.id}`} className='btn btn-info'>
                            &nbsp;<span className='glyphicon glyphicon-stats'/>&nbsp;
                        </NavLink>
                        <NavLink to={`/postbacks?campaigns[]=${this.state.campaign.id}`} className='btn btn-primary'>
                            &nbsp;<span className='glyphicon glyphicon-transfer'/>&nbsp;
                        </NavLink>
                    </div>
                </Panel.Heading>

                <Panel.Body>
                    <Table bordered className='campaign-show'>
                        <tbody>
                        <tr>
                            <th>ID</th>
                            <td>{this.state.campaign.id}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{this.state.campaign.name}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>{this.state.campaign.status}</td>
                        </tr>
                        <tr>
                            <th>Link</th>
                            <td>{this.state.campaign.link}</td>
                        </tr>
                        <tr>
                            <th>Postback link</th>
                            <td>{this.state.campaign.postback_link}</td>
                        </tr>
                        <tr>
                            <th>Format</th>
                            <td>{this.state.campaign.format}</td>
                        </tr>
                        <tr className='money-block'>
                            <th>Money</th>
                            <td>
                                <Table bordered striped>
                                    <thead>
                                    <tr>
                                        <td className='money-block_frequency'>Frequency</td>
                                        <td className='money-block_rate'>Rate $</td>
                                        <td className='money-block_daily_limit'>
                                            Daily limit: {this.state.campaign.money.daily_limit ? this.state.campaign.money.daily_limit + '$': 'Unlimited'}
                                        </td>
                                        <td className='money-block_total_limit'>
                                            Total limit: {this.state.campaign.money.total_limit ? this.state.campaign.money.total_limit + '$': 'Unlimited'}
                                        </td>
                                        <td className='money-block_pm'>Price model (PM)</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className='text-center'>{this.state.campaign.frequency || '∞ / ∞'}</td>
                                        <td className='text-center'>{this.state.campaign.rate}$</td>
                                        <td>
                                            <Progressbar
                                                value={this.state.campaign.money.daily_limit_spent}
                                                max={this.state.campaign.money.daily_limit}
                                                unlimited={this.state.campaign.money.is_unlimited_daily}
                                            />
                                        </td>
                                        <td>
                                            <Progressbar
                                                value={this.state.campaign.money.total_limit_spent}
                                                max={this.state.campaign.money.total_limit}
                                                unlimited={this.state.campaign.money.is_unlimited_total}
                                            />
                                        </td>
                                        <td className='text-center'>{this.state.campaign.price_model}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <th>Targeting</th>
                            <td>
                                <Table bordered striped className='targeting-table'>
                                    <tbody>
                                    <tr>
                                        <td>Countries</td>
                                        <td>
                                            <CountryList countries={this.state.campaign.countries} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Carriers</td>
                                        <td>{this.state.campaign.carriers.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td>Browsers</td>
                                        <td>{this.state.campaign.browsers.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td>Devices</td>
                                        <td>{this.state.campaign.devices.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td>OSes</td>
                                        <td>{this.state.campaign.oses.join(', ')}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Panel.Body>
            </Panel>
        );
    }
}
