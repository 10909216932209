import React, {Component} from 'react';

export default class SimpleHeader extends Component {

    render () {
        return (
            <thead>
            <tr className='zone-table__head'>
                <td className='zone-table__head_id'>ID</td>
                <td className='zone-table__head_name'>Name</td>
                <td className='zone-table__head_name'>Publisher</td>
                <td className='zone-table__head_type'>Type</td>
                <td className='zone-table__head_today-profit'>Today profit $</td>
                <td className='zone-table__head_total-profit'>Total profit $</td>
                <td className='zone-table__head_actions'>Actions</td>
            </tr>
            </thead>
        );
    }
}