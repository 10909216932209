import React, {Component} from 'react';
import PropTypes from "prop-types";
import StatisticsGroupBy from "../../../../api-data/Enums/StatisticsGroupBy";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import StatisticsColumns from "../../../../api-data/Enums/StatisticsColumns";

/**
 * Шапка таблицы
 */
export default class CampaignHeader extends Component {

    render () {
        return (
            <tr>
                <th className={'manager-statistics-table__header__group-by-' + StatisticsGroupBy.campaign} onClick={() => this.props.sortBy(StatisticsColumns.GroupBy)} colSpan={2}>{StatisticsGroupBy.campaign}</th>
                <th hidden={this.props.isHiddenColumn('price_model')} className='manager-statistics-table__header__price-model unsotrable'>Model</th>
                <th hidden={this.props.isHiddenColumn('campaign_group')} className='manager-statistics-table__header__campaign_group unsotrable'>Group</th>
                <th hidden={this.props.isHiddenColumn('campaign_status')} className='manager-statistics-table__header__status unsotrable'>Status</th>
                <th hidden={this.props.isHiddenColumn('campaign_daily_limit')} className='manager-statistics-table__header__daily-limit unsotrable'>Daily limit spent $</th>
                <th hidden={this.props.isHiddenColumn('campaign_total_limit')} className='manager-statistics-table__header__total-limit unsotrable'>Total limit spent $</th>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.TotalImpressions}>Total Impressions Count</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.TotalImpressions)} className='manager-statistics-table__header__total-impressions' onClick={() => this.props.sortBy(StatisticsColumns.TotalImpressions)}>Total Impr.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.Impressions}>Direct Impressions Count</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.Impressions)} className='manager-statistics-table__header__impressions' onClick={() => this.props.sortBy(StatisticsColumns.Impressions)}>Impr.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.UnshownImpressions}>Unshown Impressions Percent</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.UnshownImpressions)} className='manager-statistics-table__header__unshown-impressions' onClick={() => this.props.sortBy(StatisticsColumns.UnshownImpressions)}>Unsh.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BackbuttonImpressions}>Backbutton Impressions Percent</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.BackbuttonImpressions)} className='manager-statistics-table__header__backbutton-impressions' onClick={() => this.props.sortBy(StatisticsColumns.BackbuttonImpressions)}>BB Impr.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.UnshownBackbuttonImpressions}>Unshown Backbutton Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__unshown-backbutton-impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.UnshownBackbuttonImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.UnshownBackbuttonImpressions)}>BB Unsh.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BackurlImpressions}>Backbutton Impressions Percent</Tooltip>}>
                    <th className='manager-statistics-table__header__backurl-impressions' hidden={this.props.isHiddenColumn(StatisticsColumns.BackurlImpressions)} onClick={() => this.props.sortBy(StatisticsColumns.BackurlImpressions)}>BU Impr.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.Conversions}>Conversions Count</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.Conversions)} className='manager-statistics-table__header__conversions' onClick={() => this.props.sortBy(StatisticsColumns.Conversions)}>Conv.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.CR}>Conversion Rate</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.CR)} className='manager-statistics-table__header__cr' onClick={() => this.props.sortBy(StatisticsColumns.CR)}>CR</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BackbuttonConversions}>Backbutton Conversions Count</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.BackbuttonConversions)} className='manager-statistics-table__header__backbutton_conversions' onClick={() => this.props.sortBy(StatisticsColumns.BackbuttonConversions)}>BB Conv.</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BBCR}>Backbutton Conversion Rate</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.BBCR)} className='manager-statistics-table__header__bb_cr' onClick={() => this.props.sortBy(StatisticsColumns.BBCR)}>BBCR</th>
                </OverlayTrigger>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.Revenue)} className='manager-statistics-table__header__revenue' onClick={() => this.props.sortBy(StatisticsColumns.Revenue)}>Revenue</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.Paid)} className='manager-statistics-table__header__paid' onClick={() => this.props.sortBy(StatisticsColumns.Paid)}>Paid</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.Profit)} className='manager-statistics-table__header__profit' onClick={() => this.props.sortBy(StatisticsColumns.Profit)}>Profit</th>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.ECPM}>Effective CPM</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.ECPM)} className='manager-statistics-table__header__ecpm' onClick={() => this.props.sortBy(StatisticsColumns.ECPM)}>eCPM</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.ECPMPub}>Effective CPM Publisher</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.ECPMPub)} className='manager-statistics-table__header__ecpm_pub' onClick={() => this.props.sortBy(StatisticsColumns.ECPMPub)}>eCPMPub</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.BotImpressions}>Bots Impressions Percent</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.BotImpressions)} className='manager-statistics-table__header__bot_impressions' onClick={() => this.props.sortBy(StatisticsColumns.BotImpressions)}>Bots</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.HostingImpressions}>Percent Impressions from Hosting</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.HostingImpressions)} className='manager-statistics-table__header__hosting_impressions' onClick={() => this.props.sortBy(StatisticsColumns.HostingImpressions)}>Hosting</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.IframeImpressions}>Iframe Impressions Percent</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.IframeImpressions)} className='manager-statistics-table__header__iframe_impressions' onClick={() => this.props.sortBy(StatisticsColumns.IframeImpressions)}>Iframe</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.ProxyImpressions}>Proxy Impressions Percent</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.ProxyImpressions)} className='manager-statistics-table__header__proxy_impressions' onClick={() => this.props.sortBy(StatisticsColumns.ProxyImpressions)}>Proxy</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.InappImpressions}>InApp Impressions Percent</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.InappImpressions)} className='manager-statistics-table__header__inapp_impressions' onClick={() => this.props.sortBy(StatisticsColumns.InappImpressions)}>InApp</th>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id={StatisticsColumns.RevShare}>Return on Investment</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.RevShare)} className='manager-statistics-table__header__rev_share' onClick={() => this.props.sortBy(StatisticsColumns.RevShare)}>ROI</th>
                </OverlayTrigger>
            </tr>
        );
    }
}

CampaignHeader.propTypes = {
    sortBy: PropTypes.func.isRequired,
    isHiddenColumn: PropTypes.func.isRequired,
};
