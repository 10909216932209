import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import SignInForm from "./SignInForm";

/**
 * Индексная страница авторизации
 */
export default class Authorization extends Component {

    render() {
        return (
            <div className='content-block'>
                <div className='container-fluid content'>
                    <Switch>
                        <Route exact={true} path="/" render={() =>
                            <SignInForm/>
                        }/>
                        <Route path="/login" render={() =>
                            <SignInForm/>
                        }/>
                        {/*<Route path="/registration" render={() =>*/}
                        {/*    <SignUpForm/>*/}
                        {/*}/>*/}
                        <Route render={() =>
                            <SignInForm/>
                        }/>
                    </Switch>
                </div>

                <div className="clearfix"/>

                <footer className='footer'>
                    <div className='container'>
                        <div className='footer__line'>
                            <hr/>
                        </div>
                        <div className='footer__copyright'>
                            Checket Net OÜ, registry code: 16271528
                            Narva mnt 5, Tallinn 10117, Estonia.
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
