import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Modal, Table} from "react-bootstrap";

/**
 * Модальное окно с вспомогательной информаций о параметрах URL
 * Которые заменяются на стороне delivery при редиректе на офер
 */
export default class DeliveryReplaceParamsInfoModal extends Component {

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>URL params helper</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered>
                        <thead>
                        <tr>
                            <td>Param name</td>
                            <td>Description</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&#123;subid&#125;</td>
                            <td>Unique conversion/click key.</td>
                        </tr>
                        <tr>
                            <td>&#123;pubid&#125;</td>
                            <td>Unique publisher id.</td>
                        </tr>
                        <tr>
                            <td>&#123;psubid&#125;</td>
                            <td>Unique publisher click_id.</td>
                        </tr>
                        <tr>
                            <td>&#123;referer&#125;</td>
                            <td>Website from which the user came.</td>
                        </tr>
                        <tr>
                            <td>&#123;country&#125;</td>
                            <td>User country.</td>
                        </tr>
                        <tr>
                            <td>&#123;city&#125;</td>
                            <td>User city.</td>
                        </tr>
                        <tr>
                            <td>&#123;browser&#125;</td>
                            <td>User browser</td>
                        </tr>
                        <tr>
                            <td>&#123;os&#125;</td>
                            <td>User os.</td>
                        </tr>
                        </tbody>
                    </Table>

                </Modal.Body>
            </Modal>
        );
    }
}

DeliveryReplaceParamsInfoModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};
