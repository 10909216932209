import React, {Component} from 'react';
import PropTypes from "prop-types";
import Number from "./Formatting/Number";

/**
 * Возвращает число нужно цвета
 */
export default class ColorNumber extends Component {

    render () {
        const value     = this.props.value;
        const postfix   = this.props.postfix || '';
        const precision = this.props.precision || 0;

        if (value > 0) {
            return <span className='text-success'>{Number(value, precision) + postfix}</span>;
        }

        if (value < 0) {
            return <span className='text-danger'>{Number(value, precision) + postfix}</span>;
        }

        return <span>{Number(value, precision) + postfix}</span>;
    };
}

ColorNumber.propTypes = {
    value: PropTypes.number.isRequired,
    postfix: PropTypes.string,
    precision: PropTypes.number
};
