import _ from 'lodash';
import React, {Component} from 'react';
import Select from '../../../components/Select/Select';
import SelectOptions from '../../../components/Select/SelectOptions';
import {Panel} from 'react-bootstrap';
import PropTypes from "prop-types";
import BaseForm from "../../../components/BaseForm";
import CampaignFormats from "../../../api-data/Enums/CampaignFormats";
import CampaignPriceModels from "../../../api-data/Enums/CampaignPriceModels";
import BrowserAPI from "../../../api-data/Dictionaries/BrowserAPI";
import DeviceAPI from "../../../api-data/Dictionaries/DeviceAPI";
import OSAPI from "../../../api-data/Dictionaries/OSAPI";
import CountryAPI from "../../../api-data/Dictionaries/CountryAPI";
import CampaignStatuses from "../../../api-data/Enums/CampaignStatuses";
import CampaignAPI from "../../../api-data/Manager/CampaignAPI";
import CampaignCountry from "../../../api-data/Structs/CampaignCountry";
import Loading from "../../../components/Loading";
import FileAPI from "../../../api-data/Manager/FileAPI";
import CarrierAPI from "../../../api-data/Dictionaries/CarrierAPI";
import PrelandingAPI from "../../../api-data/Manager/PrelandingAPI";
import User from "../../../api-data/Manager/Structs/User";
import Checkbox from "../../../components/Checkbox";
import CampaignGroupAPI from "../../../api-data/Manager/CampaignGroupAPI";
import '../../../styles/css/manager/campaigns/form.css';
import ListAPI from "../../../api-data/Manager/ListAPI";
import CreatableSelect from "../../../components/Select/CreatableSelect";
import DeliveryReplaceParamsInfoModal from "../../../components/DeliveryReplaceParamsInfoModal";
import Progressbar from "../../../components/Progressbar";
import DangerEvent from "../../../components/Alerts/DangerEvent";

/**
 * Компонент отображения списка гео для кампании
 */
class CountryList extends Component {

    state = {
        dictionary: new SelectOptions(),
    };

    // Загрузка всех стран выполняется 1 раз, при создании компонента
    componentWillMount() {
        CountryAPI.list(
            (countries) => {
                this.setState({dictionary: SelectOptions.createFromDictionary(countries)});
            },
            DangerEvent.onSystemError
        );
    }

    /**
     * Обновление данных о стране по позиции
     * @param position
     * @param country
     * @param rate
     */
    updateCountry = (position, country, rate) => {
        const countries = this.props.countries;

        countries[position] = new CampaignCountry({country, rate});

        this.props.setCountries(countries);
    };

    /**
     * Удаляет страну из списка по позиции
     * @param position
     */
    deleteCountry = (position) => {
        let countries = this.props.countries;

        countries.splice(position, 1);

        this.props.setCountries(countries);
    };

    render() {
        return _.map(this.props.countries, ({country, rate}, key) => {
            let countryList = SelectOptions.createByCollection(this.state.dictionary.list());

            // Удаляем из словаря значения, которые уже использовались
            // Кроме текущей страны
            _.each(_.reject(this.props.countries, ['country', country]), (countryObj) => {
                countryList.drop(countryObj.country);
            });

            return <CountryBlock
                country={country}
                rate={rate}
                options={countryList}
                updateCountry={this.updateCountry}
                deleteCountry={this.deleteCountry}
                isAutofocus={this.props.isAutofocus && this.props.countries.length === (key + 1)}
                position={key}
                key={key}
            />;
        });
    }
}

CountryList.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    setCountries: PropTypes.func.isRequired,
    isAutofocus: PropTypes.bool.isRequired,
};

/**
 * Компонент отрисовки селекта для гео
 */
class CountryBlock extends Component {

    render() {
        const {position, country, rate, options} = this.props;
        const {deleteCountry, updateCountry}     = this.props;

        return (
            <div className='col-md-3 country-rate'>
                <div className='row col-md-9'>
                    <Select
                        name={"country-" + country}
                        value={country}
                        options={options}
                        searchable={true}
                        required={true}
                        clearable={false}
                        onChange={(_, value) => updateCountry(position, value, rate)}
                        autoFocus={this.props.isAutofocus}
                    />
                </div>
                <div className='input-group'>
                    <input type="text" value={rate} className="form-control" onChange={e => updateCountry(position, country, e.target.value)}/>
                    <div className="input-group-btn">
                        <button className='btn btn-danger' type='button' onClick={() => deleteCountry(position)}>
                            <span className='glyphicon glyphicon-trash'/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

CountryBlock.propTypes = {
    position: PropTypes.number.isRequired,
    country: PropTypes.string.isRequired,
    rate: PropTypes.number.isRequired,
    options: PropTypes.instanceOf(SelectOptions).isRequired,
    deleteCountry: PropTypes.func.isRequired,
    updateCountry: PropTypes.func.isRequired,
    isAutofocus: PropTypes.bool.isRequired,
};

/**
 * Компонент формы создания/обновления кампании
 */
export default class Form extends BaseForm {

    state = {
        submit: {
            name: '',
            link: '',
            prelanding_params: '',
            rate: 0.1,
            daily_limit: 0,
            daily_limit_spent: 0,
            is_unlimited_daily: true,
            total_limit : 0,
            total_limit_spent : 0,
            is_unlimited_total: true,
            frequency: '',
            user_id: null,
            prelanding_id: null,
            campaign_group_id: null,
            campaign_group_name: '',
            format: CampaignFormats.direct,
            price_model: CampaignPriceModels.CPA,
            status: CampaignStatuses.Started,
            allow_to_backbutton: false,
            blacklist_publishers: [],
            blacklist_zones: [],
            blacklist_partner_zones: [],
            browsers: [],
            devices: [],
            oses: [],
            countries: [],
            carriers: [],
        },

        unlimitedDailyMoneyLimit: true,
        unlimitedTotalMoneyLimit: true,

        disableCSVGeoRatesFileButton: false,
        showInfoModal: false,
        isReady: false,
        isAutofocusCountry: false,

        // Список прелендов по ключам id
        // Используется для получения домена
        prelandings: [],
        // Опции, которые загружаются во время рендеринга компонента
        // Эти списки будут использоваться для Select
        advertisersList: new SelectOptions(),
        publisherList: new SelectOptions(),
        zonesList: new SelectOptions(),
        prelandingsList: new SelectOptions(),
        groupsList: new SelectOptions([{value: 0, label: 'Add New'}]),
        browsersList: new SelectOptions(),
        carriersList: new SelectOptions(),
        devicesList: new SelectOptions(),
        osesList: new SelectOptions(),
    };

    /**
     * Callback который нужно вызвать в случае успешной отправки данных
     * @returns {function(*)}
     */
    get successCallback () {
        window.scrollTo(0, 0);

        return this.props.handleSubmit;
    };

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        // Если у кампании выбрана новая группа
        // Сначала отправляем запрос на создание новой группы
        // Потом сохраняем кампанию
        if (this.state.submit.campaign_group_id === 0 && this.state.submit.campaign_group_name !== '') {
            CampaignGroupAPI.save({'name': this.state.submit.campaign_group_name})
                .then((group) => {
                    // Обновляем стейт групп кампаний
                    // Добавляем новую группу в список
                    // И устанавливаем ее ID как выбранный у кампании
                    let groups = this.state.groupsList;
                        groups.unshift({value: group.id, label: group.pretty_name});
                    this.setState({groupsList: groups});
                    this.updateSubmitState('campaign_group_id', group.id);
                })
                .then(() => CampaignAPI.save(this.state.submit, this.successCallback, this.errorCallback))
                .catch(DangerEvent.onSystemError);
        } else {
            CampaignAPI.save(this.state.submit, this.successCallback, this.errorCallback);
        }
    };

    /**
     * При инициализации компонента загружаем:
     * - кампанию по id в случае если он указан
     */
    componentWillMount() {
        if (this.props.id) {
            CampaignAPI.getById(this.props.id, this.onLoadCampaign, DangerEvent.onSystemError);
        } else {
            this.setState({isReady: true});
        }
        BrowserAPI.list(
            ({data}) => {
                this.setState({browsersList: SelectOptions.createFromDictionary(data)});
            },
            DangerEvent.onSystemError
        );
        DeviceAPI.list(
            ({data}) => {
                this.setState({devicesList: SelectOptions.createFromDictionary(data)});
            },
            DangerEvent.onSystemError
        );
        OSAPI.list(
            ({data}) => {
                this.setState({osesList: SelectOptions.createFromDictionary(data)});
            },
            DangerEvent.onSystemError
        );
        CarrierAPI.list(
            ({data}) => {
                this.setState({carriersList: SelectOptions.createFromDictionary(data)});
            },
            DangerEvent.onSystemError
        );
        PrelandingAPI.list(
            (prelandings) => {
                const options      = SelectOptions.createByIdPrettyName(prelandings);
                const prelandingId = this.state.submit.prelanding_id || (options.first() && options.first().value);

                this.setState({
                    prelandingsList: options,
                    prelandings: _.keyBy(prelandings, 'id')
                });
                this.updateSubmitState('prelanding_id', prelandingId);
            },
            DangerEvent.onSystemError
        );
        ListAPI.campaignsGroups()
            .then((groups) => {
                const options = SelectOptions.createByIdPrettyName(groups);
                      options.push({value: 0, label: 'Add New',});
                const groupId = this.state.submit.campaign_group_id || (options.first() && options.first().value);

                this.setState({groupsList: options});
                this.updateSubmitState('campaign_group_id', groupId);
            })
            .catch(DangerEvent.onSystemError);
        ListAPI.advertisers()
            .then((advertisers) => {
                const options = SelectOptions.createByIdPrettyName(advertisers);
                const userId  = this.state.submit.user_id || (options.first() && options.first().value);

                this.setState({advertisersList: options});
                this.updateSubmitState('user_id', userId);
            })
            .catch(DangerEvent.onSystemError);
        ListAPI.zones(
            (zones) => {
                this.setState({zonesList: SelectOptions.createByIdPrettyName(zones)});
            },
            DangerEvent.onSystemError
        );
        ListAPI.publishers()
            .then((publishers) => this.setState({publisherList: SelectOptions.createByIdPrettyName(publishers)}))
            .catch(DangerEvent.onSystemError);
    }

    /**
     * Метод который выполняется при загрузке информации о кампании по id
     * @param {Campaign} campaign
     */
    onLoadCampaign = (campaign) => {
        // Список паблишеров превращаем в список id паблишеров
        let publishers = _.map(campaign.blacklist_publishers, (publisher) => publisher.id);
        // Список зон превращаем в список id зон
        let zones      = _.map(campaign.blacklist_zones, (zone) => zone.id);

        // Если это клонирование кампании, удаляем id из загруженного объекта
        // Чтобы было сохранение новой кампании, а не обновление клонируемой
        if (this.props.isClone) {
            delete campaign.id;
        }

        this.setState({submit: {
            ...campaign,
            campaign_group_name: '',
            blacklist_publishers: publishers,
            blacklist_zones: zones,
            daily_limit_spent: campaign.money.daily_limit_spent,
            total_limit_spent: campaign.money.total_limit_spent,
        }, isReady: true});
    };

    /**
     * Добавляет новый объект страны в список стран
     */
    addNewCountryWithRate = () => {
        const countries = this.state.submit.countries;
        const rate      = this.state.submit.rate || 0;

        countries.push(new CampaignCountry({country: '', rate: rate}));

        this.updateSubmitState('countries', countries);
        this.setState({isAutofocusCountry: true});
    };

    /**
     * Эммитирует клик по input file
     * Чтобы можно было выбрать файл для загрузки
     *
     * @param e
     */
    showCSVGeoRatesFileUpload = (e) => {
        e.preventDefault();

        this.csvGeoRatesFileRef.click();
    };

    /**
     * Отправка файла CSV Geo Rates
     * Установка списка Гео кампаний
     *
     * @param e
     */
    uploadCSVGeoRatesFile = (e) => {
        e.preventDefault();

        this.setState({disableCSVGeoRatesFileButton: true});

        let data = new FormData();
            data.append('file', e.target.files[0]);

        FileAPI.uploadCSVGeoRatesFile(data,
            ({data}) => {
                const countries = _.map(data, (country) => new CampaignCountry(country));

                this.updateSubmitState('countries', countries);
                this.setState({disableCSVGeoRatesFileButton: false});
            },
            () => {
                this.setState({disableCSVGeoRatesFileButton: false});
            })
    };

    /**
     * Применить новый рейт ко всего гео
     */
    applyGeoRate = () => {
        let countries = this.state.submit.countries;
        let rate      = parseFloat(this.state.submit.rate) || 0;

        countries = _.map(countries, (country) => {
            country.rate = rate;

            return country;
        });

        this.updateSubmitState('countries', countries);
    };

    /**
     * Скрывает открытое модальное окно
     */
    hideModal = () => {
        this.setState({showInfoModal: false})
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        const submit     = this.state.submit;
        const groupId    = submit.campaign_group_id !== null ? submit.campaign_group_id : this.state.groupsList.list()[0].value;
        const isNewGroup = groupId === 0;

        return (
            <form className='manager-campaign-settings-form' id='campaign-form' onSubmit={this.handleSubmit}>
                <DeliveryReplaceParamsInfoModal show={this.state.showInfoModal} onHide={this.hideModal} />

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left'>Main settings</div>
                    </Panel.Heading>

                    <Panel.Body>
                        <div className="form-group col-md-6" id="name-block">
                            <label htmlFor="name" className='control-label'>Campaign name</label>
                            <input type="text" name="name" value={submit.name} className="form-control" placeholder="Campaign name" onChange={this.handleChange} required autoFocus/>
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group col-md-2" id="format-block">
                            <label className="control-label" htmlFor="format">Format</label>
                            <Select
                                name="format"
                                value={submit.format}
                                onChange={this.updateSubmitState}
                                options={new SelectOptions([
                                    {value: CampaignFormats.direct, label: CampaignFormats.direct},
                                    {value: CampaignFormats.prelanding, label: CampaignFormats.prelanding}
                                ])}
                                clearable={false}
                                required={true}
                            />
                            <small className="text-danger" />
                        </div>

                        <div className="form-group col-md-3" id="user_id-block">
                            <label className="control-label" htmlFor="type">Advertiser</label>
                            <Select
                                name="user_id"
                                value={submit.user_id}
                                options={this.state.advertisersList}
                                onChange={this.updateSubmitState}
                                searchable={true}
                                clearable={false}
                                required={true}
                            />
                            <small className="text-danger" />
                        </div>

                        <div className="form-group col-md-1" id="price_model-block">
                            <label className="control-label" htmlFor="price_model">Price Model</label>
                            <Select
                                name="price_model"
                                value={submit.price_model}
                                onChange={this.updateSubmitState}
                                options={new SelectOptions([{value: CampaignPriceModels.CPA, label: CampaignPriceModels.CPA}, {value: CampaignPriceModels.CPM, label: CampaignPriceModels.CPM}])}
                                clearable={false}
                                required={true}
                            />
                            <small className="text-danger" />
                        </div>

                        {submit.format === CampaignFormats.prelanding &&
                            <div>
                                <div className="form-group col-md-6" id="prelanding_params-block">
                                    <label htmlFor="prelanding_params" className='control-label'>Prelanding params</label>
                                    <div className="input-group">
                                        <span className="input-group-addon">
                                            {submit.prelanding_id && this.state.prelandings.length !== 0
                                                ? this.state.prelandings[submit.prelanding_id].domain
                                                : this.props.user.company.prelandingDomain.domain
                                            }
                                        </span>
                                        <span className="input-group-addon">?</span>
                                        <input type="text" name="prelanding_params" value={submit.prelanding_params} className="form-control" placeholder="place={your}&any={params}" onChange={this.handleChange} required={true} />
                                        <span className="input-group-addon manager-campaign-settings-form__bb-checkbox">
                                            <Checkbox name='allow_to_backbutton' onChange={this.handleChangeCheckbox} isChecked={submit.allow_to_backbutton}>
                                                BB
                                            </Checkbox>
                                        </span>
                                        <div className="input-group-btn">
                                            <button type="button" className="btn btn-default" aria-label="Help" onClick={() => this.setState({showInfoModal: true})}>
                                                <span className="glyphicon glyphicon-question-sign"/>
                                            </button>
                                        </div>
                                    </div>
                                    <small className="text-danger"/>
                                </div>

                                <div className="form-group col-md-3" id="prelanding_id-block">
                                    <label className="control-label" htmlFor="type">Prelanding</label>
                                    <Select
                                        name="prelanding_id"
                                        value={submit.prelanding_id}
                                        options={this.state.prelandingsList}
                                        searchable={true}
                                        clearable={true}
                                        required={true}
                                        onChange={this.updateSubmitState}
                                    />
                                    <small className="text-danger" />
                                </div>
                            </div>
                        }

                        {submit.format === CampaignFormats.direct &&
                            <div className="form-group col-md-6" id="link-block">
                                <label htmlFor="link" className='control-label'>Offer link</label>
                                <div className="input-group">
                                    <input type="text" name="link" value={submit.link} className="form-control" placeholder="https://example.com" onChange={this.handleChange} required={true} />
                                    <span className="input-group-addon manager-campaign-settings-form__bb-checkbox">
                                        <Checkbox name='allow_to_backbutton' onChange={this.handleChangeCheckbox} isChecked={submit.allow_to_backbutton}>
                                            BB
                                        </Checkbox>
                                    </span>
                                    <div className="input-group-btn">
                                        <button type="button" className="btn btn-default" aria-label="Help" onClick={() => this.setState({showInfoModal: true})}>
                                            <span className="glyphicon glyphicon-question-sign"/>
                                        </button>
                                    </div>
                                </div>
                                <small className="text-danger"/>
                            </div>
                        }

                        <div className="form-group col-md-2" id="status-block">
                            <label className="control-label" htmlFor="status">Status</label>
                            <Select
                                name="status"
                                value={submit.status}
                                onChange={this.updateSubmitState}
                                options={new SelectOptions([{value: CampaignStatuses.Started, label: CampaignStatuses.Started}, {value: CampaignStatuses.Paused, label: CampaignStatuses.Paused}])}
                                clearable={false}
                                required={true}
                            />
                            <small className="text-danger" />
                        </div>

                        <div className={"form-group col-md-3 manager-campaign-settings-form__campaign-group" + (isNewGroup ? '-new': '')} id="campaign_group_id-block">
                            <label className="control-label" htmlFor="campaign_group_id">Group</label>
                            <Select
                                name="campaign_group_id"
                                value={groupId}
                                onChange={this.updateSubmitState}
                                options={this.state.groupsList}
                                clearable={false}
                                searchable={!isNewGroup}
                                required={!isNewGroup}
                            />
                            {isNewGroup &&
                                <input type="text" name="campaign_group_name" value={submit.campaign_group_name} className="form-control campaign_group_name" placeholder="Enter new group name" onChange={this.handleChange} autoFocus={isNewGroup} required={isNewGroup} />
                            }
                            <small className="text-danger" />
                        </div>

                        <div className="form-group col-md-1 center-block text-center manager-campaign-settings-form__frequency" id="frequency-block">
                            <label htmlFor="frequency" className='control-label'>Frequency</label>
                            <Select
                                name="frequency"
                                value={submit.frequency}
                                onChange={this.updateSubmitState}
                                options={new SelectOptions([
                                    {value: '', label: '∞ / ∞'},
                                    {value: '1/24', label: '1/24'},
                                    {value: '2/24', label: '2/24'},
                                    {value: '3/24', label: '3/24'},
                                    {value: '4/24', label: '4/24'},
                                    {value: '5/24', label: '5/24'},
                                    {value: '10/24', label: '10/24'},
                                ])}
                                clearable={false}
                                searchable={false}
                                required={false}
                            />
                            <small className="text-danger"/>
                        </div>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left pull-left'>Money settings</div>
                    </Panel.Heading>

                    <Panel.Body>
                        <div className="form-group col-md-offset-3 col-md-2 manager-campaign-settings-form__rate" id="rate-block">
                            <label htmlFor="rate" className='control-label center-block text-center'>Rate</label>
                            <div className="input-group">
                                <div className="input-group-btn">
                                    <button className='btn btn-success' type='button' onClick={this.applyGeoRate}>
                                        <span className='glyphicon glyphicon-ok'/>
                                    </button>
                                </div>
                                <input type="text" name="rate" value={submit.rate} className="form-control text-center" placeholder="0.10" onChange={this.handleChangeFloat} autoComplete="off" required/>
                                <span className="input-group-addon">$</span>
                            </div>
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group col-md-2" id="daily_limit-block">
                            <label htmlFor="daily_limit" className='control-label center-block text-center'>Daily limit</label>
                            <div className="input-group">
                                <span className="input-group-addon manager-campaign-settings-form__daily_limit-unlimited-checkbox">
                                    <Checkbox
                                        name='allow_to_backbutton'
                                        isChecked={submit.is_unlimited_daily}
                                        onChange={() => this.updateSubmitState('is_unlimited_daily', !submit.is_unlimited_daily)}
                                    />
                                </span>
                                {submit.is_unlimited_daily &&
                                    <input
                                        type="text"
                                        value='Unlimited'
                                        className="form-control text-center"
                                        disabled={true}
                                    />
                                }
                                {submit.is_unlimited_daily === false &&
                                    <input
                                        type="text"
                                        name="daily_limit"
                                        value={submit.daily_limit}
                                        className="form-control text-center"
                                        placeholder="1"
                                        autoComplete="off"
                                        onChange={this.handleChangeInt}
                                        required
                                    />
                                }
                                <span className="input-group-addon">$</span>
                            </div>
                            {this.state.submit.money !== undefined &&
                                <Progressbar
                                    value={this.state.submit.money.daily_limit_spent}
                                    max={submit.daily_limit}
                                    unlimited={submit.is_unlimited_daily}
                                    percent={true}
                                />
                            }
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group col-md-2" id="total_limit-block">
                            <label htmlFor="total_limit" className='control-label center-block text-center'>Total limit</label>
                            <div className="input-group">
                                <span className="input-group-addon manager-campaign-settings-form__total_limit-unlimited-checkbox">
                                    <Checkbox
                                        name='total_limit'
                                        isChecked={submit.is_unlimited_total}
                                        onChange={() => this.updateSubmitState('is_unlimited_total', !submit.is_unlimited_total)}
                                    />
                                </span>
                                {submit.is_unlimited_total &&
                                    <input
                                        type="text"
                                        value='Unlimited'
                                        className="form-control text-center"
                                        disabled={true}
                                    />
                                }
                                {submit.is_unlimited_total === false &&
                                    <input
                                        type="text"
                                        name="total_limit"
                                        value={submit.total_limit}
                                        className="form-control text-center"
                                        placeholder="1"
                                        autoComplete="off"
                                        onChange={this.handleChangeInt}
                                        required
                                    />
                                }
                                <span className="input-group-addon">$</span>
                            </div>
                            {this.state.submit.money !== undefined &&
                                <Progressbar
                                    value={this.state.submit.money.total_limit_spent}
                                    max={submit.total_limit}
                                    unlimited={submit.is_unlimited_total}
                                    percent={true}
                                />
                            }
                            <small className="text-danger"/>
                        </div>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left pull-left'>Geo targeting settings</div>

                        <div className='panel-heading__button-group panel-heading__button-group_right btn-group'>
                            <button className='btn btn-info' onClick={this.showCSVGeoRatesFileUpload} type='button' disabled={this.state.csvGeoRatesFileRef}>
                                &nbsp;<span className='glyphicon glyphicon-cloud-upload' />&nbsp;
                            </button>
                            <button className='btn btn-success' onClick={this.addNewCountryWithRate} type='button'>
                                &nbsp;<span className='glyphicon glyphicon-plus' />&nbsp;
                            </button>
                        </div>
                    </Panel.Heading>

                    <Panel.Body>
                        <input ref={input => this.csvGeoRatesFileRef = input} onChange={this.uploadCSVGeoRatesFile} type='file' name='csv-geo-rates-file' style={{display: "none"}} />
                        <CountryList
                            countries={submit.countries}
                            setCountries={countries => this.updateSubmitState('countries', countries)}
                            isAutofocus={this.state.isAutofocusCountry}
                        />
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left'>Targeting settings</div>
                    </Panel.Heading>

                    <Panel.Body>
                        <div className="form-group col-md-4" id="devices-block">
                            <label className="control-label" htmlFor="devices">Devices</label>
                            <Select
                                name="devices"
                                placeholder='Choose one or more targeted devices'
                                value={submit.devices}
                                options={this.state.devicesList}
                                multi={true}
                                clearable={true}
                                searchable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger" />
                        </div>
                        <div className="form-group col-md-4" id="browsers-block">
                            <label className="control-label" htmlFor="browsers">Browsers</label>
                            <Select
                                name="browsers"
                                placeholder='Choose one or more targeted browsers'
                                value={submit.browsers}
                                options={this.state.browsersList}
                                multi={true}
                                clearable={true}
                                searchable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger" />
                        </div>
                        <div className="form-group col-md-4" id="oses-block">
                            <label className="control-label" htmlFor="oses">Operation systems</label>
                            <Select
                                name="oses"
                                placeholder='Choose one or more targeted OS'
                                value={submit.oses}
                                options={this.state.osesList}
                                multi={true}
                                clearable={true}
                                searchable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger" />
                        </div>
                        <div className="form-group col-md-12" id="carriers-block">
                            <label className="control-label" htmlFor="carriers">Carriers</label>
                            <Select
                                name="carriers"
                                placeholder='Choose one or more targeted carriers'
                                value={submit.carriers}
                                options={this.state.carriersList}
                                multi={true}
                                clearable={true}
                                searchable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger" />
                        </div>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left'>Blacklist settings</div>
                    </Panel.Heading>

                    <Panel.Body>
                        <div className="form-group" id="blacklist_publishers-block">
                            <label className="control-label" htmlFor="blacklist_publishers">Blacklist publishers</label>
                            <Select
                                name='blacklist_publishers'
                                value={submit.blacklist_publishers}
                                placeholder='Select blacklist publishers...'
                                options={this.state.publisherList}
                                multi={true}
                                searchable={true}
                                clearable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger"/>
                        </div>
                        <div className="form-group" id="blacklist_zones-block">
                            <label className="control-label" htmlFor="blacklist_zones">Blacklist zones</label>
                            <Select
                                name='blacklist_zones'
                                value={submit.blacklist_zones}
                                options={this.state.zonesList}
                                placeholder='Select blacklist zones...'
                                multi={true}
                                searchable={true}
                                clearable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger"/>
                        </div>
                        <div className="form-group" id="blacklist_partner_zones-block">
                            <label htmlFor="blacklist_partner_zones" className='control-label'>Blacklist partner zones</label>
                            <CreatableSelect
                                name='blacklist_partner_zones'
                                value={submit.blacklist_partner_zones}
                                placeholder='Add blacklist partner zones...'
                                options={new SelectOptions()}
                                onChange={this.updateSubmitState}
                                multi={true}
                                clearable={true}
                            />
                            <small className="text-danger"/>
                        </div>
                    </Panel.Body>
                </Panel>
            </form>
        );
    }
}

Form.propTypes = {
    id: PropTypes.number,
    user: PropTypes.instanceOf(User).isRequired,
    isClone: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
};

Form.defaultProps = {
    isClone: false,
};
