import {Alert} from "react-bootstrap";
import React, {Component} from 'react';

export default class InfoEvent extends Component {

    static updated() {
        const alert = <Alert bsStyle="info" key={new Date().getTime()}>Updated successful!</Alert>;

        document.dispatchEvent(new CustomEvent('alert-notification', {detail: {alert: alert, duration: 3000}}));
    }

    static deleted() {
        const alert = <Alert bsStyle="info" key={new Date().getTime()}>Deleted successful!</Alert>;

        document.dispatchEvent(new CustomEvent('alert-notification', {detail: {alert: alert, duration: 3000}}));
    }
}
