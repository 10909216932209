import React from 'react';
import PropTypes from "prop-types";
import {Button, ButtonGroup, OverlayTrigger} from "react-bootstrap";
import StatisticsGroupBy from "../../../../api-data/Enums/StatisticsGroupBy";
import _ from "lodash";
import DateRange from "../../../../components/DateRange";
import DateTime from "../../../../lib/DateTime";
import BaseForm from "../../../../components/BaseForm";
import qs from "query-string";
import StatisticsAPI from "../../../../api-data/Publisher/StatisticsAPI";
import GroupByButton from "./GroupByButton";
import History from "../../../../lib/History";
import PaginationConstants from "../../../../api-data/Enums/PaginationConstants";
import StatisticsColumns from "../../../../api-data/Enums/StatisticsColumns";
import StatisticsSortTypes from "../../../../api-data/Enums/StatisticsSortTypes";
import ListAPI from "../../../../api-data/Publisher/ListAPI";
import DangerEvent from "../../../../components/Alerts/DangerEvent";

/**
 * Шапка таблицы
 */
export default class Filters extends BaseForm {

    /**
     * В конструкторе разбираются GET параметры
     * Выбираются все ранее отмеченные данные и заполняется форма
     *
     * @param props
     */
    constructor(props) {
        super(props);

        const query   = qs.parse(this.props.search);
        const groupBy = query.group_by || StatisticsGroupBy.defaultForPublisher;

        /**
         * Функция парсинга масивов данных из GET парамтров
         * @param query
         * @param name
         * @returns {*}
         */
        const getArrayFromQueryParam = (query, name) => {
            const paramName  = name + '[]';
            const queryParam = query[paramName];

            return Array.isArray(queryParam) ? queryParam : (queryParam === undefined ? [] : [queryParam]);
        };

        this.state = {
            submit: {
                group_by: groupBy,
                current_page: query.page || PaginationConstants.defaultPage,
                per_page: query.per_page || PaginationConstants.defaultPerPage,
                order_by: query.order_by || StatisticsColumns.FieldByGroupByForPublisher(groupBy),
                sort_type: query.sort_type || StatisticsSortTypes.defaultSort,
                from: query.from ? new Date(query.from) : new Date(),
                to: query.to ? new Date(query.to) : new Date(),
                [StatisticsGroupBy.zone]: getArrayFromQueryParam(query, [StatisticsGroupBy.zone]),
                [StatisticsGroupBy.day]: getArrayFromQueryParam(query, [StatisticsGroupBy.day]),
                [StatisticsGroupBy.hour]: getArrayFromQueryParam(query, [StatisticsGroupBy.hour]),
                [StatisticsGroupBy.country]: getArrayFromQueryParam(query, [StatisticsGroupBy.country]),
                [StatisticsGroupBy.carrier]: getArrayFromQueryParam(query, [StatisticsGroupBy.carrier]),
                [StatisticsGroupBy.browser]: getArrayFromQueryParam(query, [StatisticsGroupBy.browser]),
                [StatisticsGroupBy.os]: getArrayFromQueryParam(query, [StatisticsGroupBy.os]),
                [StatisticsGroupBy.device]: getArrayFromQueryParam(query, [StatisticsGroupBy.device]),
                [StatisticsGroupBy.pubid]: getArrayFromQueryParam(query, [StatisticsGroupBy.pubid]),
            },

            // Флаг отключения возможности изменять фильтры
            disabled: false,

            // Словари
            zones: [],
        };
    }

    /**
     * При инициализации компонента загружаем:
     * - список зон
     */
    componentWillMount() {
        ListAPI.zones((zones) => this.setState({zones: _.keyBy(zones, 'id')}), DangerEvent.onSystemError);
    }

    /**
     * Добавление элементов в фильтр
     *
     * @param key
     * @param value
     */
    addToFilter = (key, value) => {
        let items = this.state.submit[key];

        items.push(value.toString());

        this.updateSubmitState(key, items);
        this.updateHistory();
    };

    /**
     * Удаление элементов из фильтра
     * Если кол-во отмеченных элементов === 0
     * Запускаем отправку запроса на поиск статы
     *
     * @param key
     * @param value
     */
    removeFromFilter = (key, value) => {
        let items = this.state.submit[key];

        _.remove(items, (element) => element === value.toString());

        this.updateSubmitState(key, items);
        this.updateHistory();

        // Если убраны все элементы из этого фильтра
        // Запускаем новый поиск, чтобы вывелись все значения текущей группировки
        if (items.length === 0 || key !== this.getGroupBy()) {
            this.handleSubmit(this.state.submit.group_by);
        }
    };

    /**
     * При обновлении группировки, отправляем запрос на получение статы с этой группировокй
     * Обновляем стату только в том случае, если тип группировки изменился
     * Вместе с обновлением группировки, сбрасываем текущую страницу
     *
     * @param groupBy
     */
    onChangeGroupBy = (groupBy) => {
        if (groupBy === this.getGroupBy() || this.state.disabled === true) {
            return;
        }

        this.updateSubmitState('order_by', StatisticsColumns.FieldByGroupByForPublisher(groupBy));
        this.updateSubmitState('sort_type', StatisticsSortTypes.defaultSort);
        this.updateSubmitState('current_page', PaginationConstants.defaultPage);
        this.handleSubmit(groupBy);
        this.updateHistory();
    };

    /**
     * При изменении текущей страницы, отправляем новый запрос на получение статы
     *
     * @param page
     */
    updateCurrentPage = (page) => {
        page = page < 1 ? 1: page;

        this.updateSubmitState('current_page', page);
        this.handleSubmit(this.state.submit.group_by);
        this.updateHistory();
    };

    /**
     * Обновление порядка сортировки данных
     *
     * @param {String} fieldName
     */
    updateSortBy = (fieldName) => {
        let sortType = StatisticsSortTypes.desc;

        // Если колонка не поменялась
        // Меняем тип сортировки
        if (fieldName === this.state.submit.order_by) {
            sortType = this.state.submit.sort_type === StatisticsSortTypes.desc
                ? StatisticsSortTypes.asc
                : StatisticsSortTypes.desc;
        }

        this.updateSubmitState('order_by', fieldName);
        this.updateSubmitState('sort_type', sortType);
        this.handleSubmit(this.state.submit.group_by);
        this.updateHistory();
    };

    /**
     * Отключение фильтров
     */
    disable = () => {
        let state = this.state;

        state.disabled = true;

        this.setState(state);
    };

    /**
     * Включение фильтров
     */
    enable = () => {
        let state = this.state;

        state.disabled = false;

        this.setState(state);
    };

    /**
     * Отпраляет запрос на поиск статы с новой группировокй
     *
     * @param groupBy
     *
     * @return {Promise}
     */
    handleSubmit = (groupBy) => {
        this.disable();
        this.props.beforeSubmit();
        this.updateSubmitState('group_by', groupBy);

        const request = new StatisticsAPI();

        request.group_by     = groupBy;
        request.current_page = this.state.submit.current_page;
        request.per_page     = this.state.submit.per_page;
        request.order_by     = this.state.submit.order_by;
        request.sort_type    = this.state.submit.sort_type;
        request.from         = this.state.submit.from;
        request.to           = this.state.submit.to;
        request.zones        = this.state.submit[StatisticsGroupBy.zone];
        request.days         = this.state.submit[StatisticsGroupBy.day];
        request.hours        = this.state.submit[StatisticsGroupBy.hour];
        request.countries    = this.state.submit[StatisticsGroupBy.country];
        request.carriers     = this.state.submit[StatisticsGroupBy.carrier];
        request.browsers     = this.state.submit[StatisticsGroupBy.browser];
        request.oses         = this.state.submit[StatisticsGroupBy.os];
        request.devices      = this.state.submit[StatisticsGroupBy.device];
        request.pubids       = this.state.submit[StatisticsGroupBy.pubid];

        return request.send((data) => {
            this.props.afterSubmit(data);
            this.enable();
        }, () => {
            this.enable();
        });
    };

    /**
     * Обновление текущей статы, без изменений фильтров
     */
    refresh = () => {
        this.updateHistory();
        this.handleSubmit(this.state.submit.group_by);
    };

    /**
     * Полностью очищает значение фильтра
     *
     * @param key
     */
    cleanFilter = (key) => {
        this.updateSubmitState(key, []);
        this.updateHistory();
        this.handleSubmit(this.state.submit.group_by);
    };

    /**
     * Сброс всех фильтров на значения по-умолчанию
     * - Сбрасываем фильтры
     * - Обновляем историю
     * - Отправляем запрос на получение статы
     */
    cleanAllFilters = () => {
        const state = this.state;

        state.submit = {
            group_by: StatisticsGroupBy.defaultForPublisher,
            current_page: PaginationConstants.defaultPage,
            per_page: PaginationConstants.defaultPerPage,
            order_by: StatisticsColumns.FieldByGroupByForPublisher(StatisticsGroupBy.defaultForPublisher),
            sort_type: StatisticsSortTypes.defaultSort,
            from: new Date(),
            to: new Date(),
            [StatisticsGroupBy.zone]: [],
            [StatisticsGroupBy.day]: [],
            [StatisticsGroupBy.hour]: [],
            [StatisticsGroupBy.country]: [],
            [StatisticsGroupBy.carrier]: [],
            [StatisticsGroupBy.browser]: [],
            [StatisticsGroupBy.os]: [],
            [StatisticsGroupBy.device]: [],
            [StatisticsGroupBy.pubid]: [],
        };

        this.setState(state);
        this.updateHistory();
        this.handleSubmit(StatisticsGroupBy.defaultForPublisher);
    };

    /**
     * Вовзращает текущее значение группировки
     *
     * @returns {*}
     */
    getGroupBy = () => {
        return this.state.submit.group_by;
    };

    /**
     * Возвращает текущий список выбранных элементов в фильтре текущей группировки
     *
     * @returns {*}
     */
    getSelectedElements = () => {
        return this.state.submit[this.state.submit.group_by];
    };

    /**
     * Обновление GET параметорв
     */
    updateHistory = () => {
        const params = {
            group_by: this.state.submit.group_by,
            page: this.state.submit.current_page,
            per_page: this.state.submit.per_page,
            order_by: this.state.submit.order_by,
            sort_type: this.state.submit.sort_type,
            from: DateTime.dateToYmd(this.state.submit.from),
            to: DateTime.dateToYmd(this.state.submit.to),
            [StatisticsGroupBy.zone]: this.state.submit[StatisticsGroupBy.zone],
            [StatisticsGroupBy.day]: this.state.submit[StatisticsGroupBy.day],
            [StatisticsGroupBy.hour]: this.state.submit[StatisticsGroupBy.hour],
            [StatisticsGroupBy.country]: this.state.submit[StatisticsGroupBy.country],
            [StatisticsGroupBy.carrier]: this.state.submit[StatisticsGroupBy.carrier],
            [StatisticsGroupBy.browser]: this.state.submit[StatisticsGroupBy.browser],
            [StatisticsGroupBy.os]: this.state.submit[StatisticsGroupBy.os],
            [StatisticsGroupBy.device]: this.state.submit[StatisticsGroupBy.device],
            [StatisticsGroupBy.pubid]: this.state.submit[StatisticsGroupBy.pubid],
        };

        History.update(this.props.history, params);
    };

    render () {
        const selectedDays      = this.state.submit[StatisticsGroupBy.day].length > 0;
        const selectedHours     = this.state.submit[StatisticsGroupBy.hour].length > 0;
        const disabledDateRange = selectedDays || selectedHours;

        return (
            <ButtonGroup className={'panel-heading__button-group panel-heading__button-group_left publisher-statistics-filters ' + (this.state.disabled ? 'disabled': '')}>
                <Button bsStyle="success" onClick={this.refresh} className='publisher-statistics-filters__refresh-filters'>
                    <span className='glyphicon glyphicon-repeat'/>
                </Button>

                <div className='publisher-statistics-filters__date-range-picker'>
                    <OverlayTrigger trigger="click" rootClose placement="bottom" onExit={this.refresh} overlay={
                        <DateRange from={this.state.submit.from} to={this.state.submit.to} placement='bottom' position_top={113} offset_left={135} handleChange={this.handleChangeDateRange}/>
                    }>
                        <input
                            type="string"
                            name="date_range"
                            disabled={disabledDateRange}
                            // В случае если были выбраны кастомные даты, запрещаем изменять поле периода дат
                            value={disabledDateRange ? 'Custom': DateTime.dateToYmd(this.state.submit.from) + ' - ' + DateTime.dateToYmd(this.state.submit.to)}
                            className="form-control text-center date"
                            autoComplete='off'
                            required
                            readOnly
                        />
                    </OverlayTrigger>
                </div>

                <GroupByButton
                    active={this.state.submit.group_by === StatisticsGroupBy.zone}
                    title={StatisticsGroupBy.zone}
                    items={_.map(this.state.submit[StatisticsGroupBy.zone], (id) => this.state.zones[id] || null)}
                    onClick={this.onChangeGroupBy}
                    onCleanItem={this.removeFromFilter}
                    onCleanAll={this.cleanFilter}
                />
                <GroupByButton
                    active={this.state.submit.group_by === StatisticsGroupBy.day}
                    title={StatisticsGroupBy.day}
                    items={this.state.submit[StatisticsGroupBy.day]}
                    onClick={this.onChangeGroupBy}
                    onCleanItem={this.removeFromFilter}
                    onCleanAll={this.cleanFilter}
                />
                <GroupByButton
                    active={this.state.submit.group_by === StatisticsGroupBy.hour}
                    title={StatisticsGroupBy.hour}
                    items={this.state.submit[StatisticsGroupBy.hour]}
                    onClick={this.onChangeGroupBy}
                    onCleanItem={this.removeFromFilter}
                    onCleanAll={this.cleanFilter}
                />
                <GroupByButton
                    active={this.state.submit.group_by === StatisticsGroupBy.country}
                    title={StatisticsGroupBy.country}
                    items={this.state.submit[StatisticsGroupBy.country]}
                    onClick={this.onChangeGroupBy}
                    onCleanItem={this.removeFromFilter}
                    onCleanAll={this.cleanFilter}
                />
                <GroupByButton
                    active={this.state.submit.group_by === StatisticsGroupBy.carrier}
                    title={StatisticsGroupBy.carrier}
                    items={this.state.submit[StatisticsGroupBy.carrier]}
                    onClick={this.onChangeGroupBy}
                    onCleanItem={this.removeFromFilter}
                    onCleanAll={this.cleanFilter}
                />
                <GroupByButton
                    active={this.state.submit.group_by === StatisticsGroupBy.browser}
                    title={StatisticsGroupBy.browser}
                    items={this.state.submit[StatisticsGroupBy.browser]}
                    onClick={this.onChangeGroupBy}
                    onCleanItem={this.removeFromFilter}
                    onCleanAll={this.cleanFilter}
                />
                <GroupByButton
                    active={this.state.submit.group_by === StatisticsGroupBy.os}
                    title={StatisticsGroupBy.os}
                    items={this.state.submit[StatisticsGroupBy.os]}
                    onClick={this.onChangeGroupBy}
                    onCleanItem={this.removeFromFilter}
                    onCleanAll={this.cleanFilter}
                />
                <GroupByButton
                    active={this.state.submit.group_by === StatisticsGroupBy.device}
                    title={StatisticsGroupBy.device}
                    items={this.state.submit[StatisticsGroupBy.device]}
                    onClick={this.onChangeGroupBy}
                    onCleanItem={this.removeFromFilter}
                    onCleanAll={this.cleanFilter}
                />
                <GroupByButton
                    active={this.state.submit.group_by === StatisticsGroupBy.pubid}
                    title={StatisticsGroupBy.pubid}
                    items={this.state.submit[StatisticsGroupBy.pubid]}
                    onClick={this.onChangeGroupBy}
                    onCleanItem={this.removeFromFilter}
                    onCleanAll={this.cleanFilter}
                />

                <Button bsStyle="danger" onClick={this.cleanAllFilters} className='publisher-statistics-filters__clean-filters'>
                    <span className='glyphicon glyphicon-remove'/>
                </Button>
            </ButtonGroup>
        );
    }
}

Filters.propTypes = {
    history: PropTypes.object.isRequired,
    search: PropTypes.string.isRequired,
    beforeSubmit: PropTypes.func.isRequired,
    afterSubmit: PropTypes.func.isRequired,
};
