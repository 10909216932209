import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import ZoneAPI from "../../../api-data/Publisher/ZoneAPI";
import ZonesListTable from "./ZonesListTable";
import Loading from "../../../components/Loading";
import '../../../styles/css/publisher/zones/zone-list.css';
import qs from "query-string";
import PaginationConstants from "../../../api-data/Enums/PaginationConstants";
import History from "../../../lib/History";
import Pagination from "../../../components/Pagination";
import PropTypes from "prop-types";
import DangerEvent from "../../../components/Alerts/DangerEvent";

/**
 * Индексная страница отображающая список зон
 */
export default class Zones extends Component {

    constructor(props) {
        super(props);

        const query = qs.parse(this.props.location.search);

        this.state = {
            isReady: false,
            currentPage: query.page || PaginationConstants.defaultPage,
            lastPage: PaginationConstants.defaultPage,
            zonesList: []
        };
    }

    /**
     * При инициализации компонента загружает список зон
     */
    componentWillMount() {
        this.loadZonesList();
    }

    /**
     * Загрузка списка зон
     */
    loadZonesList = () => {
        ZoneAPI.list(
            this.state.currentPage,
            (response) => this.setState({currentPage: response.current_page, lastPage: response.last_page, zonesList: response.zones, isReady: true}),
            DangerEvent.onSystemError
        );
    };

    /**
     * Обновляем историю браузера, при смене страницы
     *
     * @param page
     */
    onChangePage = (page) => {
        this.setState({currentPage: page}, () => {
            History.update(this.props.history, {page: page});
            this.loadZonesList();
        });
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel className='zone-list-block'>
                <Panel.Heading className='panel-heading_top-rounded'/>

                <Panel.Body>
                    <ZonesListTable list={this.state.zonesList} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <div className='col-sm-12 panel-footer_pagination-wrapper'>
                        {this.refFilters !== null && <Pagination
                            onChangePage={(page) => this.onChangePage(page)}
                            current_page={this.state.currentPage}
                            last_page={this.state.lastPage}
                        />}
                    </div>

                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}

Zones.propTypes = {
    history: PropTypes.object.isRequired,
};
