import React, {Component} from 'react';
import PropTypes from "prop-types";

/**
 * Отрисовывает элемент Истины или Лжи в зависимости от параметра isOk
 */
export default class IsOk extends Component {

    render() {
        return this.props.isOk
            ? <span className='text-success glyphicon glyphicon-ok'/>
            : <span className='text-danger glyphicon glyphicon-remove'/>;
    }
}

IsOk.propTypes = {
    isOk: PropTypes.bool.isRequired,
};
