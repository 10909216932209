import axios from "axios";
import InvoiceResource from './Structs/InvoiceResource';
import _ from 'lodash';

export default class InvoiceAPI {

    static get link() {
        return process.env.REACT_APP_API_URL + '/advertiser/billing/invoices';
    };

    /**
     * @param {Object} data
     */
    static list = (data) => {
        return axios.get(InvoiceAPI.link, {params: data})
            .then(({data}) => {
                return {
                    current_page: data.current_page,
                    last_page: data.last_page,
                    invoices: _.map(data.data, (invoice) => new InvoiceResource(invoice)),
                };
            });
    };
}
