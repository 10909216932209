import axios from "axios/index";

/**
 * Компонент для получения списка стран в системе
 */
export default class CountryAPI {

    static get default() {return 'US'};

    /**
     * Получение списка стран
     *
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static list(successCallback, errorCallback) {
        axios.get(process.env.REACT_APP_API_URL + '/dictionary/countries')
            .then(({data}) => data)
            .then(successCallback)
            .catch(errorCallback);
    }
}
