import React from 'react';
import {Panel} from 'react-bootstrap';
import PropTypes from "prop-types";
import BaseForm from "../../../components/BaseForm";
import UserAPI from "../../../api-data/Manager/UserAPI";
import UserRoles from "../../../api-data/Enums/UserRoles";
import Loading from "../../../components/Loading";
import ListAPI from "../../../api-data/Manager/ListAPI";
import SelectOptions from "../../../components/Select/SelectOptions";
import Select from "../../../components/Select/Select";
import DeliveryReplaceParamsInfoModal from "../../../components/DeliveryReplaceParamsInfoModal";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import DomainTypes from '../../../api-data/Enums/DomainTypes';
import RolesOptions from '../../../components/Select/Options/RolesOptions';

const DEFAULT_PASSWORD_LENGTH = 8;

/**
 * Отображение символа глаза для пароля
 * @param show
 * @return {*}
 * @constructor
 */
function PasswordEye({show}) {
    return show === true
        ? <span className='glyphicon glyphicon-eye-open' />
        : <span className='glyphicon glyphicon-eye-close' />;
}

PasswordEye.propTypes = {
    show: PropTypes.bool.isRequired
};

/**
 * Компонент формы создания пользователя
 */
export default class Form extends BaseForm {

    constructor(props) {
        super(props);

        this.state      = {
            submit: {
                name: '',
                email: '',
                password: '',
                back_url: '',
                repostback_url: '',
                role: UserRoles.publisher,
                manager_id: null,
                traffic_domain_id: null,
            },

            isReady: false,
            showPassword: false,
            showDeliveryInfoModal: false,
            managersList: new SelectOptions(),
            domainsList: new SelectOptions(),
            rolesList: new RolesOptions(),
        };
    }

    /**
     * Callback который нужно вызвать в случае успешной отправки данных
     * @returns {function(*)}
     */
    get successCallback () {
        window.scrollTo(0, 0);

        return this.props.handleSubmit;
    };

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        UserAPI.save(this.state.submit, this.successCallback, this.errorCallback);
    };

    /**
     * При инициализации компонента загружаем:
     * - пользователя по id в случае если он указан
     * - список менеджеров
     */
    componentWillMount() {
        if (this.props.id) {
            UserAPI.getById(this.props.id)
                .then(user => this.setState({submit: {...user}, isReady: true}))
                .catch(DangerEvent.onSystemError);
        } else {
            this.setState({isReady: true});
        }
        ListAPI.managers()
            .then((managers) => this.setState({managersList: SelectOptions.createByIdPrettyName(managers)}))
            .catch(DangerEvent.onSystemError);
        ListAPI.domains(DomainTypes.Traffic)
            .then((domains) => this.setState({domainsList: SelectOptions.createBy(domains, 'id', 'domain')}))
            .catch(DangerEvent.onSystemError);
    }

    /**
     * Генерирует рандомный пароль
     */
    setRandPassword = () => {
        let password = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < DEFAULT_PASSWORD_LENGTH; i++) {
            password += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        this.updateSubmitState('password', password);
    };

    /**
     * Меняет параметр отображения пароля в открытом виде
     */
    showHidePassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    };

    /**
     * Скрывает открытое модальное окно
     */
    hideModal = () => {
        this.setState({showDeliveryInfoModal: false})
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        const submit = this.state.submit;

        return (
            <form className='user-form' id='user-form' onSubmit={this.handleSubmit} autoComplete="off">
                <DeliveryReplaceParamsInfoModal show={this.state.showDeliveryInfoModal} onHide={this.hideModal} />

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left'>Main settings</div>
                    </Panel.Heading>

                    <Panel.Body>
                        <div className="form-group col-md-6" id="name-block">
                            <label htmlFor="name" className='control-label'>Name</label>
                            <input type="text" name="name" value={submit.name} className="form-control" placeholder="User name" onChange={this.handleChange} required autoFocus/>
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group col-md-6" id="email-block">
                            <label htmlFor="email" className='control-label'>E-mail</label>
                            <input type="email" name="email" value={submit.email} className="form-control" placeholder="example@checket.net" onChange={this.handleChange} required/>
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group col-md-6" id="password-block">
                            <label htmlFor="password" className='control-label'>Password</label>
                            <div className="input-group">
                                <input type={this.state.showPassword ? 'text': 'password'} name="password" value={submit.password} className="form-control" onChange={this.handleChange} />
                                <span className="input-group-addon cursor-hand" onClick={this.showHidePassword}>
                                    <PasswordEye show={this.state.showPassword}/>
                                </span>
                                <span className="input-group-addon cursor-hand" onClick={this.setRandPassword}>
                                    <span className='glyphicon glyphicon-registration-mark'/>
                                </span>
                            </div>
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group col-md-3" id="role-block">
                            <label className="control-label" htmlFor="role">Role</label>
                            <Select
                                name="role"
                                value={submit.role}
                                onChange={this.updateSubmitState}
                                options={this.state.rolesList}
                                clearable={false}
                                required={true}
                            />
                            <small className="text-danger" />
                        </div>

                        <div className="form-group col-md-3" id="manager_id-block">
                            <label className="control-label" htmlFor="type">Manager</label>
                            <Select
                                name="manager_id"
                                placeholder='Choose manager'
                                value={submit.manager_id}
                                onChange={this.updateSubmitState}
                                options={this.state.managersList}
                                searchable={true}
                                clearable={true}
                            />
                            <small className="text-danger" />
                        </div>

                        {submit.role === UserRoles.publisher &&
                            <div className="form-group col-md-6" id="repostback_url-block">
                                <label htmlFor="name" className='control-label'>Repostback URL</label>
                                <div className="input-group">
                                    <input type="text" name="repostback_url" value={submit.repostback_url} className="form-control" placeholder="Repostback URL" onChange={this.handleChange} />
                                    <div className="input-group-btn">
                                        <button type="button" className="btn btn-info" aria-label="Help" onClick={() => this.setState({showDeliveryInfoModal: true})}>
                                            <span className="glyphicon glyphicon-question-sign"/>
                                        </button>
                                    </div>
                                </div>
                                <small className="text-danger"/>
                            </div>
                        }

                        {submit.role === UserRoles.publisher &&
                            <div className="form-group col-md-6" id="back_url-block">
                                <label htmlFor="name" className='control-label'>Back traffic URL</label>
                                <div className="input-group">
                                    <input type="text" name="back_url" value={submit.back_url} className="form-control" placeholder="Back traffic URL" onChange={this.handleChange} />
                                    <div className="input-group-btn">
                                        <button type="button" className="btn btn-info" aria-label="Help" onClick={() => this.setState({showDeliveryInfoModal: true})}>
                                            <span className="glyphicon glyphicon-question-sign"/>
                                        </button>
                                    </div>
                                </div>
                                <small className="text-danger"/>
                            </div>
                        }

                        {submit.role === UserRoles.publisher &&
                            <div className="form-group col-md-6" id="traffic_domain-block">
                                <label htmlFor="name" className='control-label'>Traffic domain</label>
                                <Select
                                    name="traffic_domain_id"
                                    placeholder='Choose domain for traffic'
                                    value={submit.traffic_domain_id}
                                    onChange={this.updateSubmitState}
                                    options={this.state.domainsList}
                                    searchable={true}
                                    clearable={true}
                                />
                                <small className="text-danger"/>
                            </div>
                        }
                    </Panel.Body>
                </Panel>
            </form>
        );
    }
}

Form.propTypes = {
    id: PropTypes.number,
    handleSubmit: PropTypes.func.isRequired,
};
