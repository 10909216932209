import React, {Component} from 'react';
import ZoneLink from '../../../../components/ObjectLinks/ZoneLink';
import UserLink from '../../../../components/ObjectLinks/UserLink';
import ShowButton from '../../../../buttons/ShowButton';
import StatisticsButton from '../../../../buttons/StatisticsButton';
import StatisticsGroupBy from '../../../../api-data/Enums/StatisticsGroupBy';
import EditButton from '../../../../buttons/EditButton';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Zone from '../../../../api-data/Manager/Structs/Zone';
import ColorNumber from '../../../../components/ColorNumber';

/**
 * Информация о кампании
 */
class ZoneRow extends Component {

    render() {
        const {zone} = this.props;

        return (
            <tr>
                <td className='zone-table__body_id'>{zone.id}</td>
                <td className='cut-off'>
                    <ZoneLink id={zone.id} name={zone.name}/>
                </td>
                <td className='cut-off'>
                    <UserLink id={zone.user_id} name={zone.user.pretty_name} is_blank={true}/>
                </td>
                <td className='zone-table__body_rev-share'>{zone.rev_share}%</td>
                <td className='zone-table__body_today-profit'>
                    <ColorNumber value={zone.money.today_profit} precision={2} postfix='$' />
                </td>
                <td className='zone-table__body_total-profit'>
                    <ColorNumber value={zone.money.total_profit} precision={2} postfix='$' />
                </td>
                <td>
                    <div className='btn-group btn-group-xs pull-right'>
                        <ShowButton route={'/zones/' + zone.id}/>
                        <StatisticsButton route={`/statistics?${StatisticsGroupBy.zone}[]=${zone.id}`}/>
                        <EditButton route={`/zones/${zone.id}/edit`}/>
                    </div>
                </td>
            </tr>
        );
    }
}

ZoneRow.propTypes = {
    zone: PropTypes.object.isRequired,
};

/**
 * Возвращает список рядов таблицы
 */
export default class Body extends Component {
    render() {
        const zones = _.map(this.props.zones, (zone) => <ZoneRow zone={zone} key={zone.id} />);

        return (
            <tbody>
                {zones}
            </tbody>
        );
    }
}

Body.propTypes = {
    zones: PropTypes.arrayOf(PropTypes.instanceOf(Zone)).isRequired,
};
