import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Panel, Table} from 'react-bootstrap';
import DeleteButton from "../../../buttons/DeleteButton";
import UserAPI from "../../../api-data/Manager/UserAPI";
import ColorNumber from "../../../components/ColorNumber";
import UserLink from '../../../components/ObjectLinks/UserLink';
import Loading from "../../../components/Loading";
import UserRoles from "../../../api-data/Enums/UserRoles";
import StatisticsButton from "../../../buttons/StatisticsButton";
import StatisticsGroupBy from "../../../api-data/Enums/StatisticsGroupBy";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import {Redirect} from "react-router";
import HowOld from "../../../components/HowOld";
import CopyToClipboard from "../../../components/CopyToClipboard";
import InfoEvent from "../../../components/Alerts/InfoEvent";
import InvoiceAPI from '../../../api-data/Manager/InvoiceAPI';
import InvoicesTable from '../Billing/InvoicesTable';
import CampaignsList from '../Campaigns/CampaignsList';
import ZonesList from '../Zones/ZonesList';
import Comments from './Comments';
import PropTypes from 'prop-types';
import User from '../../../api-data/Manager/Structs/User';

/**
 * Компонент отображения информации о пользователе
 */
export default class Show extends Component {

    state = {
        user: null,
        invoices: [],
        isDeleted: false,
        isReady: false,
    };

    /**
     * При инициализации компонента, загружаем пользователя по id
     */
    componentWillMount() {
        UserAPI.getById(this.props.match.params.id)
            .then(user => this.setState({user: user, isReady: true}))
            .catch(DangerEvent.onSystemError);
        
        InvoiceAPI.list({users: [this.props.match.params.id]})
            .then(({invoices}) => this.setState({invoices: invoices}))
            .catch(this.errorCallback);
    }

    /**
     * Метод удаления пользователя
     */
    deleteUser = () => {
        UserAPI.delete(this.props.match.params.id, () => this.setState({isDeleted: true}, InfoEvent.deleted), DangerEvent.onSystemError);
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        if (this.state.isDeleted) {
            return <Redirect to='/users' />
        }

        return (
            <Panel>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <NavLink to='/users' className='btn btn-default panel-heading__button panel-heading__button_left'>
                        <span className='glyphicon glyphicon-chevron-left'/>
                        <span className='glyphicon glyphicon-chevron-left'/>
                    </NavLink>
                    <div className='panel-heading__button-group panel-heading__button-group_right btn-group'>
                        {this.state.user.role === UserRoles.advertiser &&
                            <StatisticsButton route={`/statistics?${StatisticsGroupBy.advertiser}[]=${this.state.user.id}`}/>
                        }
                        {this.state.user.role === UserRoles.publisher &&
                            <StatisticsButton route={`/statistics?${StatisticsGroupBy.publisher}[]=${this.state.user.id}`}/>
                        }
                        {(this.state.user.role === UserRoles.manager || this.state.user.role === UserRoles.headManager) &&
                            <StatisticsButton route='' disabled={true}/>
                        }
                        <NavLink to={`/users/${this.props.match.params.id}/edit`} className='btn btn-primary'>
                            &nbsp;<span className='glyphicon glyphicon-pencil'/>&nbsp;
                        </NavLink>
                        <DeleteButton callback={this.deleteUser} className='btn btn-danger' />
                    </div>
                </Panel.Heading>

                <Panel.Body>
                    <Table bordered >
                        <tbody>
                        <tr>
                            <th>ID</th>
                            <td>{this.state.user.id}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{this.state.user.name}</td>
                        </tr>
                        <tr>
                            <th>E-mail</th>
                            <td>{this.state.user.email}</td>
                        </tr>
                        <tr>
                            <th>Manager</th>
                            <td>
                                {this.state.user.manager &&
                                    <UserLink id={this.state.user.manager.id} name={this.state.user.manager.pretty_name} />
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>Role</th>
                            <td>{this.state.user.role}</td>
                        </tr>
                        <tr>
                            <th>Balance</th>
                            <td>
                                <ColorNumber value={this.state.user.balance} precision={2} postfix='$' />
                            </td>
                        </tr>
                        {this.state.user.traffic_domain_id !== null &&
                            <tr>
                                <th>Traffic domain</th>
                                <td className='unpadding'>
                                    <CopyToClipboard value={this.state.user.traffic_domain.domain} />
                                </td>
                            </tr>
                        }
                        {this.state.user.repostback_url !== '' &&
                            <tr>
                                <th>Repostback URL</th>
                                <td className='unpadding'>
                                    <CopyToClipboard value={this.state.user.repostback_url} />
                                </td>
                            </tr>
                        }
                        {this.state.user.back_url !== '' &&
                            <tr>
                                <th>Back traffic URL</th>
                                <td className='unpadding'>
                                    <CopyToClipboard value={this.state.user.back_url} />
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>Registration Date</th>
                            <td><HowOld date={this.state.user.created_at} /></td>
                        </tr>
                        </tbody>
                    </Table>

                    <Panel>
                        <Panel.Heading className='panel-heading_top-rounded'>
                            <Panel.Title>Comments</Panel.Title>
                        </Panel.Heading>

                        <Panel.Body className='panel-body panel-body_unbordered'>
                            <Comments user={this.state.user} authUser={this.props.authUser} />
                        </Panel.Body>
                    </Panel>

                    {this.state.invoices &&
                        <Panel>
                            <Panel.Heading className='panel-heading_top-rounded'>
                                <Panel.Title>Invoices</Panel.Title>
                            </Panel.Heading>

                            <Panel.Body className='panel-body panel-body_unbordered'>
                                <InvoicesTable invoices={this.state.invoices} />
                            </Panel.Body>
                        </Panel>
                    }

                    {this.state.user.role === UserRoles.advertiser &&
                    <Panel>
                        <Panel.Heading className='panel-heading_top-rounded'>
                            <Panel.Title>Campaigns</Panel.Title>
                        </Panel.Heading>

                        <Panel.Body className='panel-body panel-body_unbordered'>
                            <CampaignsList.Table>
                                <CampaignsList.SimpleHeader />
                                <CampaignsList.Body campaigns={this.state.user.campaigns} />
                            </CampaignsList.Table>
                        </Panel.Body>
                    </Panel>
                    }

                    {this.state.user.role === UserRoles.publisher &&
                        <Panel>
                            <Panel.Heading className='panel-heading_top-rounded'>
                                <Panel.Title>Zones</Panel.Title>
                            </Panel.Heading>

                            <Panel.Body className='panel-body panel-body_unbordered'>
                                <ZonesList.Table>
                                    <ZonesList.SimpleHeader />
                                    <ZonesList.Body zones={this.state.user.zones} />
                                </ZonesList.Table>
                            </Panel.Body>
                        </Panel>
                    }
                </Panel.Body>
            </Panel>
        );
    }
}

Show.propTypes = {
    authUser: PropTypes.instanceOf(User).isRequired
};

