import axios from "axios/index";
import _ from "lodash";
import Zone from "../Publisher/Structs/Zone";

/**
 * Компонент для получения данных о зоне/ах в системе
 */
export default class ZoneAPI {

    /**
     * API URL для работы с зонами
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/publisher/zones';
    }

    /**
     * Получение списка зон
     *
     * @param {Number} page
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static list(page, successCallback, errorCallback) {
        axios.get(ZoneAPI.link, {params: {page: page}})
            .then(({data}) => {
                return {
                    current_page: data.current_page,
                    last_page: data.last_page,
                    zones: _.map(data.data, (zone) => new Zone(zone)),
                };
            })
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Получение зоны по id
     *
     * @param {Number} id
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static getById(id, successCallback, errorCallback) {
        axios.get(ZoneAPI.link + '/' + id)
            .then(({data}) => new Zone(data))
            .then(successCallback)
            .catch(errorCallback);
    }
}
