import React from 'react';
import PropTypes from 'prop-types';
import BaseForm from '../../../../components/BaseForm';
import SelectOptions from '../../../../components/Select/SelectOptions';
import DangerEvent from '../../../../components/Alerts/DangerEvent';
import ListAPI from '../../../../api-data/Manager/ListAPI';
import Select from '../../../../components/Select/Select';

export default class SearchHeader extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            submit: {
                id:      props.defaultValues.id,
                name:    props.defaultValues.name,
                user_id: props.defaultValues.user_id,
            },

            publishersList: new SelectOptions(),
        };
    }

    componentWillMount() {
        ListAPI.publishers()
            .then((publishers) => SelectOptions.createByIdPrettyName(publishers))
            .then((options) => this.setState({publishersList: options}))
            .catch(DangerEvent.onSystemError);
    }

    /**
     * При нажатии на энтер, отправляем форму
     */
    onKeyPressEnter = () => this.submitSearchParams();

    /**
     * Передаем в родительский компонент параметры поиска
     * @return {*}
     */
    submitSearchParams = () => this.props.handleFilters(this.state.submit);

    render () {
        const submit = this.state.submit;

        return (
            <thead>
            <tr className='zone-table__head'>
                <td className='zone-table__head_id table__head-filtered'>
                    <input
                        type='text'
                        name='id'
                        value={submit.id}
                        onKeyPress={this.onKeyPress}
                        onBlur={this.submitSearchParams}
                        onChange={this.handleChangeInt}
                        className="form-control"
                        placeholder='ID'
                    />
                </td>
                <td className='zone-table__head_name table__head-filtered'>
                    <input
                        type='text'
                        name='name'
                        value={submit.name}
                        onKeyPress={this.onKeyPress}
                        onBlur={this.submitSearchParams}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder='Zone name'
                    />
                </td>
                <td className='zone-table__head_publisher table__head-filtered'>
                    <Select
                        name="user_id"
                        value={submit.user_id}
                        onChange={(name, value) => this.updateSubmitState(name, value, this.submitSearchParams)}
                        options={this.state.publishersList}
                        clearable={true}
                        searchable={true}
                        placeholder='Publisher'
                    />
                </td>
                <td className='zone-table__body_rev-share hidden-sm'>Rev Share %</td>
                <td className='zone-table__body_today-profit hidden-sm'>Daily $</td>
                <td className='zone-table__body_total-profit hidden-sm'>Total $</td>
                <td className='zone-table__head_actions'>Actions</td>
            </tr>
            </thead>
        );
    }
}

SearchHeader.propTypes = {
    handleFilters: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
};