import ZoneMoney from "../../Structs/ZoneMoney";

/**
 * Структура, содержащая информацию о зоне
 */
export default class Zone {

    id                   = 0;
    user_id              = 0;
    name                 = '';
    pretty_name          = '';
    direct_link          = '';
    repostback_url       = '';
    rate                 = 0;
    rev_share            = 0;
    fix_conversion_rate  = 0;
    type                 = '';
    allow_to_backbutton  = false;
    money                = null;
    created_at           = '';
    updated_at           = '';

    constructor(data) {
        this.id                   = data.id;
        this.user_id              = data.user_id;
        this.name                 = data.name;
        this.pretty_name          = data.pretty_name;
        this.direct_link          = data.direct_link;
        this.repostback_url       = data.repostback_url || '';
        this.rate                 = data.rate;
        this.rev_share            = data.rev_share;
        this.fix_conversion_rate  = data.fix_conversion_rate;
        this.type                 = data.type;
        this.allow_to_backbutton  = data.allow_to_backbutton;
        this.money                = new ZoneMoney(data.money);
        this.created_at           = data.created_at;
        this.updated_at           = data.updated_at;
    }
}
