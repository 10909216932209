import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import Form from './Form';
import PostbacksReportAPI from "../../../api-data/Manager/PostbacksReportAPI";
import ReportsTable from "./PostbacksTable";
import DangerEvent from "../../../components/Alerts/DangerEvent";

import '../../../styles/css/manager/postbacks_report/index.css';
import SuccessEvent from "../../../components/Alerts/SuccessEvent";

/**
 * Страница отображения отчетов по конверсиям
 */
export default class PostbacksReports extends Component {

    refreshInterval = null;

    state = {
        reports: [],
    };

    /**
     * При инициализации компонента загружает список отчетов
     */
    componentWillMount() {
        this.refreshReportsList();
    }

    /**
     * Остановка интервала обновления списка отчетов
     */
    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    /**
     * Метод обновление списка отчетов
     */
    refreshReportsList = () => {
        PostbacksReportAPI.list(
            (reports) => this.setState({reports: reports}),
            DangerEvent.onSystemError
        );
    };

    /**
     * Методо обновления списка отчетов
     * После того как форма отправлена, результат будет обновлен
     * Также запускается автоматическое обновление списка отчетов
     *
     * @param report
     */
    addInReportsList = (report) => {
        let state = this.state;
            state.reports.unshift(report);

        if (this.refreshInterval === null) {
            const oneMinute = 60 * 1000;

            this.refreshInterval = setInterval(this.refreshReportsList, oneMinute);
        }

        this.setState(state, SuccessEvent.created);
    };

    render() {
        return (
            <div>
                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <Panel.Title>
                            <span className='glyphicon glyphicon-filter'/> Create postback report
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <Form onSubmit={this.addInReportsList} search={this.props.location.search} />
                    </Panel.Body>

                    <Panel.Footer className='panel-footer_bottom-rounded'>
                        <button className='btn btn-success panel-footer__button panel-footer__button_left' type='submit' form="postbacks-form">
                            &nbsp;<span className='glyphicon glyphicon-floppy-disk' />&nbsp;
                        </button>
                        <div className='clearfix'/>
                    </Panel.Footer>
                </Panel>
                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                    </Panel.Heading>

                    <Panel.Body className='panel-body panel-body_unbordered'>
                        <ReportsTable reports={this.state.reports} />
                    </Panel.Body>

                    <Panel.Footer className='panel-footer_bottom-rounded'>
                    </Panel.Footer>
                </Panel>
            </div>
        );
    }
}
