/**
 * Список доступных статусов
 */
export default class CampaignStatuses {
    static get Paused () { return 'Paused'; };
    static get Started () { return 'Started'; };
    static get Waiting () { return 'Waiting'; };
    static get Finished () { return 'Finished'; };

    /**
     * Получение списка статусов кампаний
     *
     * @returns {*[]}
     */
    static get list () {
        return [CampaignStatuses.Paused, CampaignStatuses.Started, CampaignStatuses.Waiting, CampaignStatuses.Finished];
    }

    /**
     * @param status
     * @return {boolean}
     */
    static isPaused(status) {
        return status === CampaignStatuses.Paused;
    }

    /**
     * @param status
     * @return {boolean}
     */
    static isStarted(status) {
        return status === CampaignStatuses.Started;
    }

    /**
     * @param status
     * @return {boolean}
     */
    static isWaiting(status) {
        return status === CampaignStatuses.Waiting;
    }

    /**
     * @param status
     * @return {boolean}
     */
    static isFinished(status) {
        return status === CampaignStatuses.Finished;
    }

    /**
     * @param status
     * @returns {string}
     */
    static statusColor(status) {
        switch (status) {
            case CampaignStatuses.Started:
                return 'success';
            case CampaignStatuses.Paused:
                return 'warning';
            case CampaignStatuses.Waiting:
                return 'info';
            case CampaignStatuses.Finished:
                return 'danger';
            default:
                return '';
        }
    }
}
