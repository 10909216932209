import StatisticsGroupBy from '../../Enums/StatisticsGroupBy';
import PaginationConstants from '../../Enums/PaginationConstants';
import StatisticsColumns from '../../Enums/StatisticsColumns';
import StatisticsSortTypes from '../../Enums/StatisticsSortTypes';

export default class StatisticsRequest {
    /**
     * @type {string}
     */
    group_by     = StatisticsGroupBy.default;
    /**
     * @type {string}
     */
    timezone     = process.env.REACT_APP_DEFAULT_TIMEZONE;
    /**
     * @type {number}
     */
    current_page = PaginationConstants.defaultPage;
    /**
     * @type {number}
     */
    per_page     = PaginationConstants.defaultPerPage;
    /**
     * @type {String}
     */
    order_by     = StatisticsColumns.Profit;
    /**
     * @type {String}
     */
    sort_type    = StatisticsSortTypes.defaultSort;
    /**
     * @type {String}
     */
    from         = '';
    /**
     * @type {String}
     */
    to           = '';
    /**
     * @type {Array}
     */
    publishers   = [];
    /**
     * @type {Array}
     */
    advertisers  = [];
    /**
     * @type {Array}
     */
    campaigns    = [];
    /**
     * @type {Array}
     */
    zones        = [];
    /**
     * @type {Array}
     */
    days         = [];
    /**
     * @type {Array}
     */
    hours        = [];
    /**
     * @type {Array}
     */
    countries    = [];
    /**
     * @type {Array}
     */
    carriers     = [];
    /**
     * @type {Array}
     */
    browsers     = [];
    /**
     * @type {Array}
     */
    oses         = [];
    /**
     * @type {Array}
     */
    devices      = [];
    /**
     * @type {Array}
     */
    pubids       = [];
    /**
     * @type {Array}
     */
    app_names     = [];
    /**
     * @type {Array}
     */
    errors       = [];
}
