/**
 * Структура, содержащая информацию о пользователе
 */
import UserRoles from "../Enums/UserRoles";
import Manager from "../Manager/Structs/User";
import Advertiser from "../Advertiser/Structs/User";
import Publisher from "../Publisher/Structs/User";

export default class User {
    /**
     * Возвращает правильный объект пользователя в зависимости от роли
     *
     * @param data
     * @returns {User}
     */
    static make(data) {
        switch (data.role) {
            case UserRoles.headManager:
            case UserRoles.manager:
                return new Manager(data);

            case UserRoles.advertiser:
                return new Advertiser(data);

            case UserRoles.publisher:
                return new Publisher(data);

            default:
                return {};
        }
    }
}
