import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import CurrentUserAPI from './api-data/CurrentUserAPI';
import {BrowserRouter as Router} from 'react-router-dom';
import Manager from "./pages/Manager";
import Advertiser from "./pages/Advertiser";
import Publisher from "./pages/Publisher";
import UserRoles from "./api-data/Enums/UserRoles";
import Loading from "./components/Loading";
import Alerts from "./components/Alerts";
import Authorization from "./pages/Auth";

import 'bootstrap/dist/css/bootstrap.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './styles/css/react-select.css';
import './styles/css/style.css';
import './styles/css/header.css';
import './styles/css/footer.css';
import './styles/css/wait-block.css';
import './styles/css/content-block.css';
import './styles/css/button.css';
import './styles/css/button-group.css';
import './styles/css/panel.css';
import './styles/css/table.css';
import './styles/css/progress.css';
import './styles/css/popover.css';
import './styles/css/modal.css';
import './styles/css/checkbox.css';
import './styles/css/pagination.css';
import './styles/css/copy-to-clipboard.css';
import './styles/css/alerts.css';
import './styles/css/events/nav-events.css';
import './styles/css/user-comments.css';

class App extends Component {

    state = {
        user: {},
        isReady: false
    };

    /**
     * Узнаем, авторизован ли пользователь в API приложении
     */
    componentWillMount() {
        CurrentUserAPI.current(
            (user) => this.setState({user: user, isReady: true}),
            () => this.setState({isReady: true}),
        );
    }

    render() {
        // Если еще идет загрузка, показывать анимацию
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        switch (this.state.user.role) {
            case UserRoles.advertiser:
                return <Advertiser authUser={this.state.user} />;

            case UserRoles.publisher:
                return <Publisher authUser={this.state.user} />;

            case UserRoles.headManager:
            case UserRoles.manager:
                return <Manager authUser={this.state.user} />;

            default:
                return <Authorization/>;
        }
    }
}

ReactDOM.render(
    (<Router>
        <div>
            <Alerts />
            <App />
        </div>
    </Router>),
    document.getElementById('root')
);
