import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

/**
 * Кнопка статистики
 */
export default class StatisticsButton extends Component {

    render() {
        return (
            <NavLink to={this.props.route} className={'btn btn-info ' + (this.props.disabled ? 'disabled': '')}>
                <span className='glyphicon glyphicon-stats'/>
            </NavLink>
        );
    }
}

StatisticsButton.propTypes = {
    route: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};
