import _ from 'lodash';
import axios from "axios/index";
import Campaign from "./Structs/Campaign";

/**
 * Компонент для получения данных о кампании/ях в системе
 */
export default class CampaignAPI {

    /**
     * API URL для работы с кампаниями
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/manager/campaigns';
    };

    /**
     * Возвращает список кампаний
     *
     * @param {Object} searchParams
     */
    static list(searchParams) {
        return axios.get(CampaignAPI.link, {params: searchParams})
            .then(({data}) => {
                return {
                    current_page: data.current_page,
                    last_page:    data.last_page,
                    campaigns:    _.map(data.data, (campaign) => new Campaign(campaign)),
                };
            });
    }

    /**
     * Сохранение данных о кампании
     * Если эта кампания была ранее создана, тогда обновляем ее данные
     *
     * @param campaign
     * @param successCallback
     * @param errorCallback
     */
    static save(campaign, successCallback, errorCallback) {
        let request;

        if (campaign.id) {
            request = axios.put(CampaignAPI.link + '/' + campaign.id, campaign);
        } else {
            request = axios.post(CampaignAPI.link, campaign);
        }

        request.then(successCallback).catch(errorCallback);
    }

    /**
     * Получение кампании по id
     *
     * @param {Number} id
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static getById(id, successCallback, errorCallback) {
        axios.get(CampaignAPI.link + '/' + id)
            .then(({data}) => new Campaign(data))
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Удаление кампании по id
     *
     * @param id
     * @param successCallback
     * @param errorCallback
     */
    static delete(id, successCallback, errorCallback) {
        axios.delete(CampaignAPI.link + '/' + id)
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Запуск кампании по id
     *
     * @param id
     * @param successCallback
     * @param errorCallback
     * @return {Promise}
     */
    static start(id, successCallback, errorCallback) {
        return axios.put(CampaignAPI.link + '/' + id + '/start')
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Остановка кампании по id
     *
     * @param id
     * @param successCallback
     * @param errorCallback
     * @return {Promise}
     */
    static pause(id, successCallback, errorCallback) {
        return axios.put(CampaignAPI.link + '/' + id + '/pause')
            .then(successCallback)
            .catch(errorCallback);
    }
}
