import React, {Component} from 'react';
import {Button, Panel} from 'react-bootstrap';
import Loading from "../../../components/Loading";
import CreateModal from "./CreateModal";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import SuccessEvent from "../../../components/Alerts/SuccessEvent";
import CorrectionsAPI from '../../../api-data/Manager/CorrectionsAPI';
import CorrectionsListTable from './CorrectionsListTable';
import '../../../styles/css/manager/corrections/index.css';

export default class Corrections extends Component {

    state = {
        showModal: false,
        isReady: false,
        list: [],
    };

    componentWillMount() {
        CorrectionsAPI.list()
            .then((corrections) => this.setState({list: corrections, isReady: true}))
            .catch(DangerEvent.onSystemError);
    }

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel className='corrections-index'>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <div className='panel-heading__button-group panel-heading__button-group_right btn-group'>
                        <Button onClick={() => this.setState({showModal: true})} className='btn btn-success'>
                            &nbsp;<span className='glyphicon glyphicon-plus'/>&nbsp;
                        </Button>
                    </div>
                </Panel.Heading>

                <Panel.Body className='panel-body panel-body_unbordered'>
                    <CreateModal
                        show={this.state.showModal}
                        handleClose={() => this.setState({showModal: false})}
                        handleSave={(corrections) => {
                            let requests = this.state.list;
                                requests.unshift(corrections);

                            this.setState({list: requests, showModal: false}, SuccessEvent.created);
                        }}
                    />

                    <CorrectionsListTable list={this.state.list} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'/>
            </Panel>
        );
    }
}

Corrections.propTypes = {};
