/**
 * Структура, содержащая информацию о s2s request
 */
import Zone from "./Zone";
import Campaign from "./Campaign";

export default class S2SRequest {

    id          = 0;
    user_id     = 0;
    zone_id     = 0;
    campaign_id = 0;
    link        = '';
    /**
     * @type {null|Zone}
     */
    zone        = null;
    /**
     * @type {null|Campaign}
     */
    campaign    = null;
    received_at = null;
    created_at  = null;
    updated_at  = null;

    constructor(data) {
        this.id          = data.id;
        this.user_id     = data.user_id;
        this.zone_id     = data.zone_id;
        this.campaign_id = data.campaign_id;
        this.link        = data.link;
        this.zone        = new Zone(data.zone);
        this.campaign    = new Campaign(data.campaign);
        this.received_at = data.received_at ? new Date(data.received_at): null;
        this.created_at  = new Date(data.created_at);
        this.updated_at  = new Date(data.updated_at);
    }
}
