import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import PropTypes from "prop-types";
import Form from "./Form";

export default class CreateModal extends Component {

    render() {
        return (
            <div className="static-modal">
                <Modal show={this.props.show} onHide={this.handleClose}>
                    <Modal.Header>
                        <Modal.Title>Create new statistics correction</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form handleSave={this.props.handleSave} />
                    </Modal.Body>

                    <div className="clearfix"/>

                    <Modal.Footer>
                        <button onClick={this.props.handleClose} className='btn btn-default modal-footer__button modal-footer__button_left'>
                            &nbsp;<span className='glyphicon glyphicon-remove' />&nbsp;
                        </button>
                        <button className='btn btn-primary pull-right modal-footer__button modal-footer__button_right' type='submit' form='correction-form'>
                            &nbsp;<span className='glyphicon glyphicon-ok' />&nbsp;
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

CreateModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
};

