import React, {Component} from 'react';
import {Button, Panel} from 'react-bootstrap';
import Loading from "../../../components/Loading";
import PrelandingAPI from "../../../api-data/Manager/PrelandingAPI";
import CreateModal from "./CreateModal";
import PropTypes from "prop-types";
import User from "../../../api-data/Manager/Structs/User";
import PrelandingsListTable from "./PrelandingsListTable";

import '../../../styles/css/prelandings/prelandings.css';
import InfoModal from "./InfoModal";
import DangerEvent from "../../../components/Alerts/DangerEvent";

/**
 * Индексная страница, на которой отображается список прелендов
 */
export default class Prelandings extends Component {

    state = {
        showInfoModal: false,
        showCreateModal: true,
        isReady: false,
        list: [],
    };

    /**
     * Загружаем список прелендов
     */
    componentWillMount() {
        PrelandingAPI.list((prelandings) => this.setState({list: prelandings, isReady: true}), DangerEvent.onSystemError);
    }

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel className='prelanding-index'>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <div className='panel-heading__button-group panel-heading__button-group_right btn-group'>
                        {/*<Button onClick={() => this.setState({showInfoModal: true})} className='btn btn-info'>*/}
                        {/*    &nbsp;<span className='glyphicon glyphicon-info-sign'/>&nbsp;*/}
                        {/*</Button>*/}
                        <Button onClick={() => this.setState({showCreateModal: true})} className='btn btn-success'>
                            &nbsp;<span className='glyphicon glyphicon-plus'/>&nbsp;
                        </Button>
                    </div>
                </Panel.Heading>

                <Panel.Body className='panel-body panel-body_unbordered'>
                    <InfoModal
                        show={this.state.showInfoModal}
                        handleClose={() => this.setState({showInfoModal: false})}
                    />
                    <CreateModal
                        show={this.state.showCreateModal}
                        user={this.props.authUser}
                        handleClose={() => this.setState({showCreateModal: false})}
                        handleSave={(prelanding) => {
                            let list = [prelanding];
                            list.push(...this.state.list);

                            this.setState({list: list, showCreateModal: false});
                        }}
                    />

                    <PrelandingsListTable list={this.state.list} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'/>
            </Panel>
        );
    }
}

Prelandings.propTypes = {
    authUser: PropTypes.instanceOf(User).isRequired
};
