import {Alert} from "react-bootstrap";
import React, {Component} from 'react';

export default class DangerEvent extends Component {

    static onSystemError = () => {
        const alert = <Alert bsStyle="danger" key={new Date().getTime()}>System error!</Alert>;

        document.dispatchEvent(new CustomEvent('alert-notification', {detail: {alert: alert, duration: 3000}}));
    };
}
