import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import Form from './Form';
import PropTypes from "prop-types";
import InfoEvent from "../../../components/Alerts/InfoEvent";
import {Redirect} from "react-router";

/**
 * Модуль редактирования информации о пользователе
 */
export default class Edit extends Component {

    state = {
        user: null,
        edited: false,
    };

    handleSubmit = ({data}) => this.setState({user: data}, InfoEvent.updated);

    render() {
        if (this.state.edited && this.state.user) {
            return <Redirect to={`/users/${this.state.user.id}`} />;
        }

        return (
            <Panel>
                <Panel.Body className='unpadding'>
                    <Form id={this.props.id} handleSubmit={this.handleSubmit} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <NavLink to='/users' className='btn btn-default panel-footer__button panel-footer__button_left'>
                        &nbsp;<span className='glyphicon glyphicon-remove' />&nbsp;
                    </NavLink>
                    <button
                        className='btn btn-primary pull-right panel-footer__button panel-footer__button_right'
                        type='submit'
                        form='user-form'
                        onClick={() => this.setState({edited: true})}
                    >
                        &nbsp;<span className='glyphicon glyphicon-ok' />&nbsp;
                    </button>
                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}

Edit.propTypes = {
    id: PropTypes.number.isRequired
};
