import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import EditForm from './Form';
import PropTypes from 'prop-types';
import InfoEvent from "../../../components/Alerts/InfoEvent";
import {Redirect} from "react-router";

/**
 * Компонент редактирования зоны
 */
export default class Edit extends Component {

    state = {
        edited: false,
        zone: null,
    };

    /**
     * @param {Zone} data
     */
    handleSubmit = (data) => this.setState({zone: data}, InfoEvent.updated);

    render() {
        if (this.state.edited && this.state.zone) {
            return <Redirect to={`/zones/${this.state.zone.id}`} />;
        }

        return (
            <Panel>
                <Panel.Body className='manager-zone-settings-panel'>
                    <EditForm id={this.props.id} handleSubmit={this.handleSubmit} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <NavLink to='/zones' className='btn btn-default panel-footer__button panel-footer__button_left'>
                        Cancel
                    </NavLink>
                    <button
                        className='btn btn-primary pull-right panel-footer__button panel-footer__button_right'
                        type='submit'
                        form='zone-form'
                        onClick={() => this.setState({edited: true})}
                    >
                        Edit zone
                    </button>
                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}

Edit.propTypes = {
    id: PropTypes.number.isRequired
};
