import React, {Component} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import _ from 'lodash';
import StatsData from "../../../../api-data/Manager/Structs/StatsData";
import Footer from "./Footer";
import Header from "./Header";
import Body from "./Body";
import StatisticsGroupBy from "../../../../api-data/Enums/StatisticsGroupBy";
import ZoneBody from "./ZoneBody";
import CampaignBody from "./CampaignBody";
import CampaignHeader from "./CampaignHeader";
import CampaignFooter from "./CampaignFooter";
import AdvertiserBody from "./AdvertiserBody";
import PublisherBody from "./PublisherBody";
import ColumnModal from "../components/ColumnModal";
import LocalStorage from "../../../../components/LocalStorage";
import CountryBody from "./CountryBody";

/**
 * Таблица статистики
 */
export default class StatisticsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            hiddenColumns: LocalStorage.getHiddenColumns(),
        };
    }

    /**
     * Обновление списка скрытых колонок
     * @param name
     */
    updateHiddenColumns = (name) => {
        let columns = this.state.hiddenColumns;

        if (this.isHiddenColumn(name)) {
            _.remove(columns, (element) => element === name);
        } else {
            columns.push(name);
        }

        this.setState({hiddenColumns: columns});
        LocalStorage.setHiddenColumns(columns);
    };

    /**
     * Показать модальное окно
     */
    showModal = () => {
        this.setState({showModal: true});
    };

    /**
     * Скрыть модальное окно
     */
    hideModal = () => {
        this.setState({showModal: false});
    };

    /**
     * Проверка колонки на отсутствие в списке скрытых колонок
     * @param name
     * @returns {boolean}
     */
    isHiddenColumn = (name) => {
        return this.state.hiddenColumns.indexOf(name) !== -1;
    };

    /**
     * Проверка колонки на отсутствие в списке отмеченных колонок
     * @param value
     * @returns {boolean}
     */
    isSelectedRow = (value) => {
        return this.props.selected.indexOf(value.toString()) !== -1;
    };

    /**
     * Обновление списка отмеченных полей
     * - Если сейчас это поле выбрано, тогда удаляем его из списка
     * - Если сейчас это поле не выбрано, тогда добавляем его в список
     *
     * @param value
     */
    updateSelection = (value) => {
        this.isSelectedRow(value)
            ? this.props.onUnselect(this.props.group_by, value)
            : this.props.onSelect(this.props.group_by, value);
    };

    render() {
        let header = '';
        let body   = '';
        let footer = '';

        switch (this.props.group_by) {
            case StatisticsGroupBy.advertiser:
                header = <Header isHiddenColumn={this.isHiddenColumn} sortBy={this.props.sortBy} group_by={StatisticsGroupBy.advertiser} />;
                body   = <AdvertiserBody isHiddenColumn={this.isHiddenColumn} isSelectedRow={this.isSelectedRow} updateSelection={this.updateSelection} statistics={this.props.statistics} />;
                footer = <Footer isHiddenColumn={this.isHiddenColumn} total={this.props.total} />;
                break;

            case StatisticsGroupBy.publisher:
                header = <Header isHiddenColumn={this.isHiddenColumn} sortBy={this.props.sortBy} group_by={StatisticsGroupBy.publisher} />;
                body   = <PublisherBody isHiddenColumn={this.isHiddenColumn} isSelectedRow={this.isSelectedRow} updateSelection={this.updateSelection} statistics={this.props.statistics} />;
                footer = <Footer isHiddenColumn={this.isHiddenColumn} total={this.props.total} />;
                break;

            case StatisticsGroupBy.campaign:
                header = <CampaignHeader isHiddenColumn={this.isHiddenColumn} sortBy={this.props.sortBy} />;
                body   = <CampaignBody isHiddenColumn={this.isHiddenColumn} isSelectedRow={this.isSelectedRow} updateSelection={this.updateSelection} statistics={this.props.statistics} />;
                footer = <CampaignFooter isHiddenColumn={this.isHiddenColumn} total={this.props.total} />;
                break;

            case StatisticsGroupBy.zone:
                header = <Header isHiddenColumn={this.isHiddenColumn} sortBy={this.props.sortBy} group_by={StatisticsGroupBy.zone} />;
                body   = <ZoneBody isHiddenColumn={this.isHiddenColumn} isSelectedRow={this.isSelectedRow} updateSelection={this.updateSelection} statistics={this.props.statistics} />;
                footer = <Footer isHiddenColumn={this.isHiddenColumn} total={this.props.total} />;
                break;

            case StatisticsGroupBy.country:
                header = <Header isHiddenColumn={this.isHiddenColumn} sortBy={this.props.sortBy} group_by={StatisticsGroupBy.country} />;
                body   = <CountryBody isHiddenColumn={this.isHiddenColumn} isSelectedRow={this.isSelectedRow} updateSelection={this.updateSelection} statistics={this.props.statistics} />;
                footer = <Footer isHiddenColumn={this.isHiddenColumn} total={this.props.total} />;
                break;

            default:
                header = <Header isHiddenColumn={this.isHiddenColumn} sortBy={this.props.sortBy} group_by={this.props.group_by} />;
                body   = <Body group_by={this.props.group_by} isHiddenColumn={this.isHiddenColumn} isSelectedRow={this.isSelectedRow} updateSelection={this.updateSelection} statistics={this.props.statistics} />;
                footer = <Footer isHiddenColumn={this.isHiddenColumn} total={this.props.total} />;
        }

        return (
            <div>
                <ColumnModal show={this.state.showModal} isHidden={this.isHiddenColumn} onChange={this.updateHiddenColumns} onHide={this.hideModal}/>
                <Table hover bordered className='manager-statistics-table'>
                    <thead className='manager-statistics-table__header'>
                    {header}
                    </thead>

                    <tbody className='manager-statistics-table__body'>
                        {body}
                    </tbody>

                    <tfoot className='manager-statistics-table_footer'>
                    {footer}
                    </tfoot>
                </Table>
            </div>
        );
    }
}

StatisticsTable.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onUnselect: PropTypes.func.isRequired,
    sortBy: PropTypes.func.isRequired,
    group_by: PropTypes.oneOf(StatisticsGroupBy.list).isRequired,
    selected: PropTypes.array.isRequired,
    statistics: PropTypes.arrayOf(PropTypes.instanceOf(StatsData)).isRequired,
    total: PropTypes.instanceOf(StatsData).isRequired,
};
