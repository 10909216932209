import React from 'react';
import BaseForm from "../../../components/BaseForm";
import PropTypes from "prop-types";
import User from "../../../api-data/Manager/Structs/User";
import PrelandingAPI from "../../../api-data/Manager/PrelandingAPI";
import Prelanding from "../../../api-data/Manager/Structs/Prelanding";
import SelectOptions from '../../../components/Select/SelectOptions';
import Select from '../../../components/Select/Select';

/**
 * Компонент формы создания преленда
 */
export default class Form extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            submit: {
                name: '',
                subdomain: '',
                domain_id: (props.domains.first() && props.domains.first().value) || null,
                file: null,
            },
            domainsList: props.domains,
        };
    }

    /**
     * Callback который нужно вызвать в случае успешной отправки данных
     * @returns {function(*)}
     */
    get successCallback () {
        return ({data}) => this.props.handleSave(new Prelanding(data));
    };

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        let submit = this.state.submit;
        let data   = new FormData();
            data.append('name', submit.name);
            data.append('domain_id', submit.domain_id);
            data.append('subdomain', submit.subdomain);
            data.append('file', submit.file);

        PrelandingAPI.save(data)
            .then(this.successCallback)
            .catch(this.errorCallback);
    };

    render() {
        let submit = this.state.submit;
        let [host, domain] = this.state.domainsList.optionByValue(submit.domain_id).label.split('://');

        return (
            <form className='prelanding-form' id='prelanding-form' encType="multipart/form-data" onSubmit={this.handleSubmit}>
                <div className="form-group col-md-12" id="name-block">
                    <label htmlFor="name" className='control-label'>Prelanding name</label>
                    <input type="text" name="name" value={submit.name} className="form-control" placeholder="Prelanding name" onChange={this.handleChange} required autoFocus/>
                    <small className="text-danger"/>
                </div>

                <div className="form-group col-md-12" id="domain_id-block">
                    <label htmlFor="name" className='control-label'>Domain</label>
                    <Select
                        name="domain_id"
                        placeholder='Choose root domain for landing'
                        value={submit.domain_id}
                        onChange={this.updateSubmitState}
                        options={this.state.domainsList}
                        searchable={true}
                        clearable={false}
                    />
                    <small className="text-danger"/>
                </div>

                <div className="form-group col-md-12" id="subdomain-block">
                    <label htmlFor="subdomain" className='control-label'>Campaign subdomain</label>
                    <div className="input-group">
                        <span className="input-group-addon">{host}://</span>
                        <input type="text" name="subdomain" value={submit.subdomain} className="form-control" placeholder="subdomain" onChange={this.handleChange} required/>
                        <span className="input-group-addon">.{domain}</span>
                    </div>
                    <small className="text-danger"/>
                </div>

                <div className="form-group col-md-12 form-input-file" id="file-block">
                    <label htmlFor="file" className='control-label hidden'/>
                    <input type="file" name="file" id="file" onChange={this.handleChangeFile} required />
                    <small className="text-danger"/>
                </div>
            </form>
        );
    }
}

Form.propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    domains: PropTypes.instanceOf(SelectOptions).isRequired,
    handleSave: PropTypes.func.isRequired,
};

