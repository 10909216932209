import React, {Component} from 'react';
import PropTypes from "prop-types";
import User from '../../../../api-data/Manager/Structs/User';
import UserCommentResource from '../../../../api-data/Manager/Structs/UserCommentResource';
import UserLink from '../../../../components/ObjectLinks/UserLink';
import HowOld from '../../../../components/HowOld';
import _ from 'lodash';

class Comment extends Component {
    render() {
        const comment     = this.props.comment;
        const isMyComment = comment.creator.id === this.props.authUser.id;

        return (
            <div className='user-comment'>
                <div className={`user-comment_item ` + (isMyComment ? 'owner': '')}>
                    <div className='user-comment_creator'>
                        <small><UserLink id={comment.creator.id} name={comment.creator.pretty_name} is_blank={true} /></small>
                    </div>
                    <div className='user-comment_time'>
                        <small><HowOld date={comment.created_at} /></small>
                    </div>
                    <div className='user-comment_text'>
                        {comment.comment}
                    </div>
                </div>
            </div>
        );
    }
}

Comment.propTypes = {
    authUser: PropTypes.instanceOf(User).isRequired,
    comment: PropTypes.instanceOf(UserCommentResource).isRequired,
};

export default class List extends Component {

    render() {
        let comments = _.map(this.props.comments,
            (comment) => <Comment key={comment.id} comment={comment} authUser={this.props.authUser} />
        );

        if (this.props.comments.length === 0) {
            return <div className='user-comments user-comments__empty' />
        }

        return (
            <div className='user-comments'>
                {comments}
            </div>
        );
    }
}

List.propTypes = {
    authUser: PropTypes.instanceOf(User).isRequired,
    comments: PropTypes.arrayOf(PropTypes.instanceOf(UserCommentResource)).isRequired,
};
