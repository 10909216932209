import Echo from "laravel-echo";
import Event from "../../api-data/Manager/Structs/Event";
import SocketIo from "socket.io-client";

export default class PrivateManagerChannel {

    /**
     * @type {User}
     */
    user = null;

    /**
     *
     * @type {Echo}
     */
    echo = null;

    /**
     * @param {User} user
     */
    constructor(user) {
        this.user = user;
        this.echo = new Echo({
            broadcaster: 'socket.io',
            host: `${window.location.hostname}:${process.env.REACT_APP_SOCKET_PORT}`,
            client: SocketIo,
        });
    }

    /**
     * @param {Function} callback
     */
    listen = (callback) => {
        this.echo
            .private('manager-channel.' + this.user.company.id + '.manager.' + this.user.id)
            .listen('.private', (event) => callback(new Event(event)))
            .error((e) => console.log('Socket error:', e));
    };
}
