import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import InvoicesTable from "./InvoicesTable";
import InvoiceAPI from '../../../api-data/Advertiser/InvoiceAPI';

import '../../../styles/css/billing/index.css';

export default class Billing extends Component {

    state = {
        invoices: [],
    };

    componentDidMount() {
         InvoiceAPI.list(this.state.submit)
            .then(({invoices}) => this.setState({invoices: invoices}))
            .catch(this.errorCallback);
    }

    render() {
        return (
            <div>
                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                    </Panel.Heading>

                    <Panel.Body className='panel-body panel-body_unbordered'>
                        <InvoicesTable invoices={this.state.invoices} />
                    </Panel.Body>

                    <Panel.Footer className='panel-footer_bottom-rounded'>
                    </Panel.Footer>
                </Panel>
            </div>
        );
    }
}
