/**
 * Структура, содержащая единицу данных о статистике
 */
export default class StatsData {

    group_by    = '';
    impressions = 0;
    conversions = 0;
    revenue     = 0;
    ecpm        = 0;
    cr          = 0;

    constructor(data) {
        this.group_by    = data.group_by || '';
        this.impressions = data.impressions || 0;
        this.conversions = data.conversions || 0;
        this.revenue     = data.revenue || 0;
        this.ecpm        = data.ecpm || 0;
        this.cr          = data.cr || 0;
    }
}
