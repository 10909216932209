import React, {Component} from 'react';
import {Navbar} from 'react-bootstrap';
import {NavLink, Route, Switch} from 'react-router-dom';
import Logout from "../Auth/Logout";
import Zones from "./Zones";
import ShowZone from "./Zones/Show";
import Statistics from "./Statistics";
import Billing from "./Billing";
import PropTypes from 'prop-types';
import User from "../../api-data/Publisher/Structs/User";

/**
 * Индексная страница кабинета манагеров
 */
export default class Publisher extends Component {

    render() {
        return (
            <div className='content-block publisher-content-block'>
                <Navbar fixedTop className='bg-primary'>
                    <Navbar.Header>
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>
                        <Navbar.Brand>
                            <NavLink to='/'>
                                <img src={'/images/logo.png'} alt='Checket.net brand ico' />
                            </NavLink>
                        </Navbar.Brand>
                    </Navbar.Header>
                    <div className="collapse navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li><NavLink to='/statistics'>Statistics</NavLink></li>
                            <li><NavLink to='/zones'>Zones</NavLink></li>
                            <li><NavLink to='/billing'>Billing</NavLink></li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <NavLink to='/logout'>Logout</NavLink>
                            </li>
                        </ul>
                    </div>
                </Navbar>

                <Switch>
                    <Route path="/statistics" render={(props) =>
                        <div className='container-fluid content'>
                            <Statistics {...props}/>
                        </div>
                    }/>
                    <Route path="/zones" render={() =>
                        <div className='container content'>
                            <Switch>
                                <Route exact path='/zones' render={(props) => {
                                    return <Zones {...props}/>;
                                }}/>
                                <Route exact path={'/zones/:id'} render={(props) => {
                                    return <ShowZone {...props} id={+props.match.params.id} />;
                                }}/>
                            </Switch>
                        </div>
                    }/>
                    <Route path="/billing" render={(props) =>
                        <div className='container content'>
                            <Billing {...props}/>
                        </div>
                    }/>
                    <Route path="/logout" render={(props) =>
                        <div className='container content'>
                            <Logout {...props} />
                        </div>
                    }/>
                    <Route render={(props) =>
                        <div className='container-fluid content'>
                            <Statistics {...props}/>
                        </div>
                    }/>
                </Switch>

                <div className="clearfix"/>

                <footer className='footer'>
                    <div className='container'>
                        <div className='footer__line'>
                            <hr/>
                        </div>
                        <div className='footer__copyright'>
                            Checket Net OÜ, registry code: 16271528
                            Narva mnt 5, Tallinn 10117, Estonia.
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

Publisher.propTypes = {
    authUser: PropTypes.instanceOf(User).isRequired
};
