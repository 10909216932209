import React, {Component} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import ZoneLink from "../../../components/ObjectLinks/ZoneLink";
import CampaignLink from "../../../components/ObjectLinks/CampaignLink";
import Correction from "../../../api-data/Manager/Structs/Correction";
import StatisticsCorrectionStatus from "../../../api-data/Enums/StatisticsCorrectionStatus";
import UserLink from '../../../components/ObjectLinks/UserLink';
import _ from 'lodash';
import DateTime from '../../../lib/DateTime';
import ColorNumber from '../../../components/ColorNumber';

class CorrectionRaw extends Component {

    render() {
        const correction = this.props.correction;
        const color      = StatisticsCorrectionStatus.statusColor(correction.status);

        return (
            <tr className={'corrections-table__body ' + color}>
                <td className="corrections-table__body_creator cut-off">
                    <UserLink id={correction.user.id} name={correction.user.pretty_name} is_blank={true}/>
                </td>
                <td className="corrections-table__body_zone cut-off">
                    <ZoneLink id={correction.zone.id} name={correction.zone.pretty_name} is_blank={true}/>
                </td>
                <td className="corrections-table__body_campaign cut-off">
                    <CampaignLink id={correction.campaign.id} name={correction.campaign.pretty_name} is_blank={true}/>
                </td>
                <td className="corrections-table__body_description cut-off">{correction.description}</td>
                <td className="corrections-table__body_status">{correction.status}</td>
                <td className="corrections-table__body_revenue">
                    <ColorNumber value={correction.profit} precision={2} postfix='$' />
                </td>
                <td className="corrections-table__body_paid">
                    <ColorNumber value={correction.paid} precision={2} postfix='$' />
                </td>
                <td className="corrections-table__body_date">{DateTime.dateToYmd(correction.date)}</td>
            </tr>
        );
    }
}

CorrectionRaw.propTypes = {
    correction: PropTypes.instanceOf(Correction).isRequired,
};

/**
 * Таблица списка зон
 */
export default class CorrectionsListTable extends Component {

    render() {
        return (<Table bordered condensed hover className="corrections-table">
                <thead>
                <tr className="corrections-table__head">
                    <td className="corrections-table__head_creator">Creator</td>
                    <td className="corrections-table__head_zone">Zone</td>
                    <td className="corrections-table__head_campaign">Campaign</td>
                    <td className="corrections-table__head_description">Description</td>
                    <td className="corrections-table__head_status">Status</td>
                    <td className="corrections-table__head_revenue">Revenue</td>
                    <td className="corrections-table__head_paid">Paid</td>
                    <td className="corrections-table__head_date">Date</td>
                </tr>
                </thead>

                <tbody>
                    {_.map(this.props.list, (correction) => <CorrectionRaw correction={correction} key={correction.id} />)}
                </tbody>
            </Table>);
    }
}

CorrectionsListTable.propTypes = {
    list: PropTypes.arrayOf(PropTypes.instanceOf(Correction)).isRequired,
};

