/**
 * Структура, содержащая информацию о положении финансов у зоны
 */
export default class ZoneMoney {

    constructor(data) {
        this._total_profit = data.total_profit;
        this._today_profit = data.today_profit;
    }

    get total_profit() {
        return this._total_profit;
    }

    get today_profit() {
        return this._today_profit;
    }
}
