import React, {Component} from 'react';
import {Modal, Panel, Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import BaseForm from "../../../components/BaseForm";
import ZoneTypes from "../../../api-data/Enums/ZoneTypes";
import _ from 'lodash';
import ZoneAPI from "../../../api-data/Manager/ZoneAPI";
import Loading from "../../../components/Loading";
import '../../../styles/css/manager/zones/form.css';
import ListAPI from "../../../api-data/Manager/ListAPI";
import SelectOptions from "../../../components/Select/SelectOptions";
import Select from "../../../components/Select/Select";
import DeliveryReplaceParamsInfoModal from "../../../components/DeliveryReplaceParamsInfoModal";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import ZoneRotationTypes from '../../../api-data/Enums/ZoneRotationTypes';

/**
 * Модальное окно с вспомогательной информаций об repostback URL &&
 */
class InfoModal extends Component {

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>URL params helper</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered>
                        <thead>
                        <tr>
                            <td>Param name</td>
                            <td>Description</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>&#123;ip&#125;</td>
                            <td>Request IP address.</td>
                        </tr>
                        <tr>
                            <td>&#123;partner_subid&#125;</td>
                            <td>Partner subid/click_id parameter.</td>
                        </tr>
                        <tr>
                            <td>&#123;subid&#125;</td>
                            <td>Partner subid/click_id parameter.</td>
                        </tr>
                        <tr>
                            <td>&#123;user_agent&#125;</td>
                            <td>Request User-Agent header parameter.</td>
                        </tr>
                        <tr>
                            <td>&#123;country&#125;</td>
                            <td>Request country.</td>
                        </tr>
                        <tr>
                            <td>&#123;carrier&#125;</td>
                            <td>Request Carrier/ISP.</td>
                        </tr>
                        <tr>
                            <td>&#123;connection_type&#125;</td>
                            <td>Request connection type (Wifi/3G).</td>
                        </tr>
                        <tr>
                            <td>&#123;device&#125;</td>
                            <td>Request device type.</td>
                        </tr>
                        <tr>
                            <td>&#123;browser&#125;</td>
                            <td>Request Browser name.</td>
                        </tr>
                        <tr>
                            <td>&#123;os&#125;</td>
                            <td>Request OS name.</td>
                        </tr>
                        <tr>
                            <td>&#123;amount&#125;</td>
                            <td>Conversion cost. Only paid part.</td>
                        </tr>
                        <tr>
                            <td>&#123;date&#125;</td>
                            <td>Date, when conversion created.</td>
                        </tr>
                        </tbody>
                    </Table>

                </Modal.Body>
            </Modal>
        );
    }
}

InfoModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};

/**
 * Компонент формы создания/обновления зоны
 */
export default class Form extends BaseForm {

    state = {
        submit: {
            name: '',
            repostback_url: '',
            back_url: '',
            user_id: null,
            firewall_campaign_id: null,
            type: ZoneTypes.directLink,
            rotation_type: ZoneRotationTypes.ecpm,
            allow_to_backbutton: false,
            enabled_firewall: false,
            inherit_back_url: true,
            inherit_repostback_url: true,
            is_enabled_quality_page: false,
            rate : 0,
            rev_share : 70,
            fix_conversion_rate : 0,
            main_campaigns: [],
            main_campaigns_groups: [],
            backup_campaigns: [],
            backup_campaigns_groups: [],
            user: null,
        },

        isReady: false,
        showRepostbackInfoModal: false,
        showDeliveryInfoModal: false,
        groupsList: new SelectOptions(),
        publisherList: new SelectOptions(),
        campaignsList: new SelectOptions(),
    };

    /**
     * При инициализации компонента загружаем:
     * - зону по id в случае если он указан
     * - список паблишеров
     */
    componentWillMount() {
        if (this.props.id) {
            ZoneAPI.getById(this.props.id, this.onLoadZone, DangerEvent.onSystemError);
        } else {
            this.setState({isReady: true});
        }

        ListAPI.publishers()
            .then((publishers) => {
                const options = SelectOptions.createByIdPrettyName(publishers);
                const userId  = this.state.submit.user_id || (options.first() && options.first().value);

                this.setState({publisherList: options});
                this.updateSubmitState('user_id', userId);
            })
            .catch(DangerEvent.onSystemError);
        ListAPI.campaigns()
            .then((campaigns) => this.setState({campaignsList: SelectOptions.createByIdPrettyName(campaigns)}))
            .catch(DangerEvent.onSystemError);
        ListAPI.campaignsGroups()
            .then((groups) => this.setState({groupsList: SelectOptions.createByIdPrettyName(groups)}))
            .catch(DangerEvent.onSystemError);
    }

    /**
     * Поле отправки формы, если данные сохранились
     * Делаем редирект на страницу просмотра информации о текущей зоне
     *
     * @returns {function(*)}
     */
    get successCallback () {
        window.scrollTo(0, 0);

        return this.props.handleSubmit;
    };

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        ZoneAPI.save(this.state.submit, this.successCallback, this.errorCallback);
    };

    /**
     * Метод обновления рейта
     * Поскольку нельзя одновременно учитывать rate && rev_share,
     * То при измененении rate, обнуляем rev_share
     *
     * @param event
     */
    handleChangeRate = (event) => {
        this.handleChangeFloat(event);
        this.updateSubmitState('rev_share', 0);
    };

    /**
     * Метод обновления рейта
     * Поскольку нельзя одновременно учитывать rate && rev_share,
     * То при измененении rev_share, обнуляем rate
     *
     * @param event
     */
    handleChangeRevShare = (event) => {
        this.handleChangeInt(event);
        this.updateSubmitState('rate', 0);
    };

    /**
     * Метод который выполняется при загрузке информации о зоне по id
     * @param {Zone} zone
     */
    onLoadZone = (zone) => {
        // Список групп кампаний превращаем в список id кампаний
        const mGroups    = _.map(zone.main_campaigns_groups, (group) => group.id);
        const bGroups    = _.map(zone.backup_campaigns_groups, (group) => group.id);
        // Список кампаний превращаем в список id кампаний
        const mCampaigns = _.map(zone.main_campaigns, (campaign) => campaign.id);
        const bCampaigns = _.map(zone.backup_campaigns, (campaign) => campaign.id);

        this.setState({submit: {...zone, main_campaigns_groups: mGroups, main_campaigns: mCampaigns, backup_campaigns_groups: bGroups, backup_campaigns: bCampaigns}, isReady: true})
    };

    /**
     * Обработка события обновления чекбокса enable_firewall
     * @param event
     */
    handleChangeEnableFirewall = (event) => {
        if (!this.state.submit.enabled_firewall === false) {
            this.updateSubmitState('firewall_campaign_id', null);
        }

        this.handleChangeCheckbox(event);
    };

    /**
     * Обработка события обновления чекбокса is_enabled_quality_page
     * @param event
     */
    handleChangeEnableQualityPage = (event) => {
        if (!this.state.submit.is_enabled_quality_page === false) {
            this.updateSubmitState('allow_to_backbutton', false);
            this.updateSubmitState('enabled_firewall', false);
            this.updateSubmitState('firewall_campaign_id', null);
        }

        this.handleChangeCheckbox(event);
    };

    /**
     * Скрывает открытое модальное окно
     */
    hideModal = () => {
        this.setState({showRepostbackInfoModal: false, showDeliveryInfoModal: false})
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        const submit                 = this.state.submit;
        const disabledBBButton       = submit.is_enabled_quality_page === false;
        const bbButtonClass          = disabledBBButton === true
            ? 'disabled'
            : (submit.allow_to_backbutton ? 'active' : '');
        const disabledFirewallButton = submit.is_enabled_quality_page === false;
        const firewallButtonClass    = disabledFirewallButton === true
            ? 'disabled'
            : (submit.enabled_firewall ? 'active' : '');

        return (
            <form className='manager-zone-settings-form' id='zone-form' onSubmit={this.handleSubmit}>
                <InfoModal show={this.state.showRepostbackInfoModal} onHide={this.hideModal} />
                <DeliveryReplaceParamsInfoModal show={this.state.showDeliveryInfoModal} onHide={this.hideModal} />

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left'>Main settings</div>
                    </Panel.Heading>

                    <div className='config-buttons'>
                        <div className='btn-group btn-group-justified' data-toggle="buttons">
                            <label className={'btn btn-orange ' + (submit.is_enabled_quality_page ? 'active' : '')}>
                                <input type='checkbox' name='is_enabled_quality_page' onChange={this.handleChangeEnableQualityPage} checked={submit.is_enabled_quality_page} /> Enable Quality Page
                            </label>
                            <label className={'btn btn-orange ' + bbButtonClass}>
                                <input type='checkbox' name='allow_to_backbutton' onChange={this.handleChangeCheckbox} disabled={disabledBBButton} checked={submit.allow_to_backbutton} /> Enable BackButton
                            </label>
                            <label className={'btn btn-orange ' + firewallButtonClass}>
                                <input type='checkbox' name='enabled_firewall' onChange={this.handleChangeEnableFirewall} disabled={disabledFirewallButton} checked={submit.enabled_firewall} /> Enable Firewall
                            </label>
                            <label className={'btn btn-orange ' + (submit.inherit_back_url ? 'active' : '')}>
                                <input type='checkbox' name='inherit_back_url' onChange={this.handleChangeCheckbox} checked={submit.inherit_back_url} /> Inherit Publisher BackURL
                            </label>
                            <label className={'btn btn-orange ' + (submit.inherit_repostback_url ? 'active' : '')}>
                                <input type='checkbox' name='inherit_repostback_url' onChange={this.handleChangeCheckbox} checked={submit.inherit_repostback_url} /> Inherit Publisher Repostback
                            </label>
                        </div>
                    </div>

                    <Panel.Body>
                        <div className='row'>
                            <div className="form-group col-md-6" id="name-block">
                                <label htmlFor="name" className='control-label'>Zone name</label>
                                <input type="text" name="name" value={submit.name} className="form-control" placeholder="Zone name" onChange={this.handleChange} required autoFocus/>
                                <small className="text-danger"/>
                            </div>

                            <div className="form-group col-md-6" id="user_id-block">
                                <label className="control-label" htmlFor="type">Publisher</label>
                                <Select
                                    name="user_id"
                                    placeholder='Choose publisher'
                                    value={submit.user_id}
                                    onChange={this.updateSubmitState}
                                    options={this.state.publisherList}
                                    searchable={true}
                                    clearable={false}
                                    required={true}
                                />
                                <small className="text-danger" />
                            </div>
                        </div>

                        <div className='row'>
                            <div className="form-group col-md-6" id="back_url-block">
                                <label htmlFor="back_url" className='control-label'>Back traffic URL</label>
                                <div className="input-group">
                                    {submit.inherit_back_url &&
                                        <input type="text" name="back_url" value='' className="form-control" placeholder='Inherit from publisher' onChange={this.handleChange} disabled />
                                    }
                                    {submit.inherit_back_url === false &&
                                        <input type="text" name="back_url" value={submit.back_url} className="form-control" placeholder="https://back-traffic-url.com" onChange={this.handleChange} />
                                    }
                                    <div className="input-group-btn">
                                        <button type="button" className="btn btn-info" aria-label="Help" onClick={() => this.setState({showDeliveryInfoModal: true})}>
                                            <span className="glyphicon glyphicon-question-sign"/>
                                        </button>
                                    </div>
                                </div>
                                <small className="text-danger"/>
                            </div>

                            <div className="form-group col-md-3" id="type-block">
                                <label className="control-label" htmlFor="type">Type</label>
                                <Select
                                    name="type"
                                    value={submit.type}
                                    onChange={this.updateSubmitState}
                                    options={new SelectOptions([{value: ZoneTypes.directLink, label: 'Direct Link'}])}
                                    searchable={false}
                                    required={true}
                                />
                                <small className="text-danger" />
                            </div>

                            <div className="form-group col-md-3" id="rotation_type-block">
                                <label className="control-label" htmlFor="type">Rotation Type</label>
                                <Select
                                    name="rotation_type"
                                    value={submit.rotation_type}
                                    onChange={this.updateSubmitState}
                                    options={new SelectOptions([
                                        {value: ZoneRotationTypes.ecpm, label: 'eCPM'},
                                        {value: ZoneRotationTypes.random, label: 'Random'},
                                    ])}
                                    searchable={false}
                                    required={true}
                                />
                                <small className="text-danger" />
                            </div>
                        </div>

                        <div className='row'>
                            <div className="form-group col-md-6" id="repostback_url-block">
                                <label htmlFor="repostback_url" className='control-label'>Repostback URL</label>
                                <div className="input-group">
                                    {submit.inherit_repostback_url &&
                                        <input type="text" name="repostback_url" value='' className="form-control" placeholder='Inherit from publisher' onChange={this.handleChange} disabled />
                                    }
                                    {submit.inherit_repostback_url === false &&
                                        <input type="text" name="repostback_url" value={submit.repostback_url} className="form-control" placeholder='https://repostback-url.com' onChange={this.handleChange} />
                                    }
                                    <div className="input-group-btn">
                                        <button type="button" className="btn btn-info" aria-label="Help" onClick={() => this.setState({showRepostbackInfoModal: true})}>
                                            <span className="glyphicon glyphicon-question-sign"/>
                                        </button>
                                    </div>
                                </div>
                                <small className="text-danger"/>
                            </div>

                            <div className='form-group col-md-6' id='firewall_campaign_id-block'>
                                <label htmlFor="firewall_campaign_id" className='control-label'>Firewall campaign</label>
                                <Select
                                    name="firewall_campaign_id"
                                    value={submit.firewall_campaign_id}
                                    onChange={this.updateSubmitState}
                                    options={this.state.campaignsList}
                                    searchable={true}
                                    clearable={false}
                                    required={submit.enabled_firewall}
                                    disabled={!submit.enabled_firewall}
                                />
                                <small className="text-danger" />
                            </div>
                        </div>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left'>Money settings</div>
                    </Panel.Heading>

                    <Panel.Body>
                        <div className='col-md-2 col-md-offset-1' />
                        <div className="form-group col-md-2" id="rate-block">
                            <label htmlFor="rate" className='control-label'>Rate</label>
                            <div className="input-group">
                                <input type="text" name="rate" value={submit.rate} className="form-control" placeholder="0.10$" onChange={this.handleChangeRate} required/>
                                <span className="input-group-addon">$</span>
                            </div>
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group col-md-2" id="rev_share-block">
                            <label htmlFor="rev_share" className='control-label'>Rev. share</label>
                            <div className="input-group">
                                <input type="text" name="rev_share" value={submit.rev_share} className="form-control" placeholder="70%" onChange={this.handleChangeRevShare} required/>
                                <span className="input-group-addon">%</span>
                            </div>
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group col-md-2" id="fix_conversion_rate-block">
                            <label htmlFor="fix_conversion_rate" className='control-label'>Fix conv. rate</label>
                            <div className="input-group">
                                <input type="text" name="fix_conversion_rate" value={submit.fix_conversion_rate} className="form-control" placeholder="0.10$" onChange={this.handleChangeFloat} required/>
                                <span className="input-group-addon">$</span>
                            </div>
                            <small className="text-danger"/>
                        </div>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left pull-left'>Main campaigns</div>
                    </Panel.Heading>

                    <Panel.Body>
                        <div className="form-group" id="main_campaigns_groups-block">
                            <label htmlFor="main_campaigns_groups" className='control-label'>Campaigns Groups</label>
                            <Select
                                name='main_campaigns_groups'
                                value={this.state.submit.main_campaigns_groups}
                                placeholder='Attach rotation list of campaigns groups...'
                                options={this.state.groupsList}
                                multi={true}
                                searchable={true}
                                clearable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group" id="main_campaigns-block">
                            <label htmlFor="main_campaigns" className='control-label'>Campaigns</label>
                            <Select
                                name='main_campaigns'
                                value={this.state.submit.main_campaigns}
                                placeholder='Attach rotation list campaigns...'
                                options={this.state.campaignsList}
                                multi={true}
                                searchable={true}
                                clearable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger"/>
                        </div>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='text-left pull-left'>Backup campaigns</div>
                    </Panel.Heading>

                    <Panel.Body>
                        <div className="form-group" id="backup_campaigns_groups-block">
                            <label htmlFor="backup_campaigns_groups" className='control-label'>Campaigns Groups</label>
                            <Select
                                name='backup_campaigns_groups'
                                value={this.state.submit.backup_campaigns_groups}
                                placeholder='Attach rotation list of campaigns groups...'
                                options={this.state.groupsList}
                                multi={true}
                                searchable={true}
                                clearable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger"/>
                        </div>

                        <div className="form-group" id="backup_campaigns-block">
                            <label htmlFor="campaigns" className='control-label'>Campaigns</label>
                            <Select
                                name='backup_campaigns'
                                value={this.state.submit.backup_campaigns}
                                placeholder='Attach rotation list campaigns...'
                                options={this.state.campaignsList}
                                multi={true}
                                searchable={true}
                                clearable={true}
                                onChange={this.updateSubmitState}
                            />
                            <small className="text-danger"/>
                        </div>
                    </Panel.Body>
                </Panel>
            </form>
        );
    }
}

Form.propTypes = {
    id: PropTypes.number,
    handleSubmit: PropTypes.func.isRequired,
};
