import React, {Component} from 'react';
import {Button, Panel} from 'react-bootstrap';
import Loading from "../../../components/Loading";
import CreateModal from "./CreateModal";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import S2SRequestAPI from "../../../api-data/Manager/S2SRequestAPI";
import S2SRequestsListTable from "./S2SRequestsListTable";

import '../../../styles/css/manager/s2s_request/index.css'
import SuccessEvent from "../../../components/Alerts/SuccessEvent";

/**
 * Индексная страница, на которой отображается список прелендов
 */
export default class S2SRequests extends Component {

    state = {
        showModal: false,
        isReady: false,
        list: [],
    };

    /**
     * Загружаем список s2s requests
     */
    componentWillMount() {
        S2SRequestAPI.list((requests) => this.setState({list: requests, isReady: true}), DangerEvent.onSystemError);
    }

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel className='s2s-request-index'>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <div className='panel-heading__button-group panel-heading__button-group_right btn-group'>
                        <Button onClick={() => this.setState({showModal: true})} className='btn btn-success'>
                            &nbsp;<span className='glyphicon glyphicon-plus'/>&nbsp;
                        </Button>
                    </div>
                </Panel.Heading>

                <Panel.Body className='panel-body panel-body_unbordered'>
                    <CreateModal
                        show={this.state.showModal}
                        handleClose={() => this.setState({showModal: false})}
                        handleSave={(s2s) => {
                            let requests = this.state.list;
                                requests.unshift(s2s);

                            this.setState({list: requests, showModal: false}, SuccessEvent.created);
                        }}
                    />

                    <S2SRequestsListTable list={this.state.list} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'/>
            </Panel>
        );
    }
}

S2SRequests.propTypes = {
};
