import axios from "axios/index";

/**
 * Компонент для получения списка OS в системе
 */
export default class OSAPI {

    /**
     * Получение списка OS
     *
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static list(successCallback, errorCallback) {
        axios.get(process.env.REACT_APP_API_URL + '/dictionary/oses')
            .then(successCallback)
            .catch(errorCallback);
    }
}
