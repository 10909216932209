export default class LocalStorage {

    /**
     * Сохраняет список колонок, которое не нужно отображаться в таблице статы
     *
     * @param columns
     */
    static setHiddenColumns = (columns) => {
        columns = JSON.stringify(columns || []);

        return localStorage.setItem('statistics-hidden-columns', columns);
    };

    /**
     * Возвращает список колонок, которое не нужно отображаться в таблице статы
     *
     * @returns {any | Array}
     */
    static getHiddenColumns = () => {
        const columns = localStorage.getItem('statistics-hidden-columns');

        return JSON.parse(columns) || [];
    };
}
