import React, {Component} from 'react';
import PropTypes from "prop-types";
import ReactCreatableSelect from "react-select/creatable";
import SelectOptions from "./SelectOptions";
import _ from "lodash";

/**
 * Оббертка для компонента Select из пакета react-select
 * Реализует недостющую логику по созданию списка несуществующих значений
 */
export default class CreatableSelect extends Component {

    /**
     * Метод вызывается при изменении значения/й селекта
     * Возвращает name поля и его новое значение/я
     *
     * @param selected
     */
    handleChange = (selected) => {
        let value = null;

        // Если это мультиселект, возвращаем масив значений
        // Иначе, значение от выбранного элемента
        if (this.props.multi) {
            value = _.map(selected, el => el.value);
        } else {
            value = selected !== null ? selected.value : this.props.defaultValue;
        }

        this.props.onChange(this.props.name, value);
    };

    render() {
        const value = this.props.multi
            ? _.map(this.props.value, (value) => ({value: value, label: value}))
            : {value: this.props.value, label: this.props.value};

        return (
            <ReactCreatableSelect
                id={this.props.name}
                name={this.props.name}
                value={value}
                onChange={this.handleChange}
                options={this.props.options.list()}
                isClearable={this.props.clearable}
                isMulti={this.props.multi}
                placeholder={this.props.placeholder}
                required={this.props.required}
                openMenuOnClick={false}
                classNamePrefix="react-select"
            />
        );
    }
}

CreatableSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    options: PropTypes.instanceOf(SelectOptions).isRequired,
    onChange: PropTypes.func.isRequired,
    clearable: PropTypes.bool,
    multi: PropTypes.bool,
    required: PropTypes.bool,
};

CreatableSelect.defaultProps = {
    name: '',
    value: '',
    defaultValue: null,
    placeholder: '',
    options: new SelectOptions(),
    onChange: function () {
        console.log('Default change callback');
    },
    clearable: false,
    multi: false,
    required: false,
};
