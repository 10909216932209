/**
 * Список доступных статусов
 */
export default class PrelandingStatuses {
    static get Active () { return 'Active'; };
    static get Disabled () { return 'Disabled'; };

    /**
     * @param status
     * @return {boolean}
     */
    static IsActive(status) {
        return status === PrelandingStatuses.Active;
    }

    /**
     * @param status
     * @return {boolean}
     */
    static IsDisabled(status) {
        return status === PrelandingStatuses.Disabled;
    }
}
