import React, {Component} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import _ from 'lodash';
import Prelanding from "../../../api-data/Manager/Structs/Prelanding";
import IsOk from "../../../components/IsOk";
import PrelandingStatuses from "../../../api-data/Enums/PrelandingStatuses";

/**
 * Информация о кампании
 */
class TableRow extends Component {

    render() {
        const prelanding = this.props.prelanding;

        return (
            <tr className='prelanding-table__body'>
                <td>{prelanding.id}</td>
                <td>{prelanding.name}</td>
                <td>
                    <a href={prelanding.domain} rel="noopener noreferrer" target='_blank'>{prelanding.domain}</a>
                </td>
                <td className='prelanding-table__body_file-name'>
                    <div>{prelanding.file_name}</div>
                </td>
                <td className='prelanding-table__body_status'>
                    <IsOk isOk={PrelandingStatuses.IsActive(prelanding.status)}/>
                </td>
            </tr>
        );
    }
}

TableRow.propTypes = {
    prelanding: PropTypes.instanceOf(Prelanding).isRequired,
};

/**
 * Возвращает список рядов таблицы
 */
function PrelandingList({list}) {
    return _.map(list, (prelanding) => <TableRow prelanding={prelanding} key={prelanding.id} />);
}

PrelandingList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.instanceOf(Prelanding)).isRequired
};

/**
 * Таблица со списком прелендов
 */
export default function PrelandingsListTable({list}) {
    return (
        <Table bordered condensed hover className='prelanding-table'>
            <thead>
            <tr className='prelanding-table__head'>
                <td className='prelanding-table__head_id'>ID</td>
                <td className='prelanding-table__head_name'>Name</td>
                <td className='prelanding-table__head_domain'>Domain</td>
                <td className='prelanding-table__head_file-name'>File Name</td>
                <td className='prelanding-table__head_status'>Status</td>
            </tr>
            </thead>

            <tbody>
                <PrelandingList list={list} />
            </tbody>
        </Table>
    );
}

PrelandingsListTable.propTypes = {
    list: PropTypes.arrayOf(PropTypes.instanceOf(Prelanding)).isRequired
};
