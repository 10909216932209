import React from 'react';
import BaseForm from "../../../components/BaseForm";
import PropTypes from "prop-types";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import SelectOptions from "../../../components/Select/SelectOptions";
import ListAPI from "../../../api-data/Manager/ListAPI";
import Select from "../../../components/Select/Select";
import CorrectionsAPI from '../../../api-data/Manager/CorrectionsAPI';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import Calendar from 'react-date-range/dist/components/Calendar';
import DateTime from '../../../lib/DateTime';
import _ from 'lodash';

export default class Form extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            submit: {
                zone_id: null,
                campaign_id: null,
                impressions: 0,
                conversions: 0,
                paid: 0,
                profit: 0,
                description: '',
                date: new Date(),
            },
            zonesList: new SelectOptions(),
            campaignsList: new SelectOptions(),
        };
    }

    /**
     * При инициализации компонента загружаем:
     * - кампанию по id в случае если он указан
     */
    componentWillMount() {
        ListAPI.zones(
            (zones) => {
                this.setState({zonesList: SelectOptions.createByIdPrettyName(zones)});
            },
            DangerEvent.onSystemError
        );
        ListAPI.campaigns()
            .then((campaigns) => this.setState({campaignsList: SelectOptions.createByIdPrettyName(campaigns)}))
            .catch(DangerEvent.onSystemError);
    }

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        let submit  = _.clone(this.state.submit);
        submit.date = DateTime.dateToYmd(submit.date);

        CorrectionsAPI.save(submit)
            .then(this.props.handleSave)
            .catch(this.errorCallback);
    };

    render() {
        const submit = this.state.submit;

        return (
            <form className='correction-form' id='correction-form' onSubmit={this.handleSubmit}>
                <div className="form-group" id="zone_id-block">
                    <label htmlFor="zone_id" className='control-label'>Zone</label>
                    <Select
                        name='zone_id'
                        value={submit.zone_id}
                        placeholder='Select zone for correction'
                        options={this.state.zonesList}
                        searchable={true}
                        required={true}
                        onChange={this.updateSubmitState}
                    />
                    <small className="text-danger"/>
                </div>
                <div className="form-group" id="campaign_id-block">
                    <label htmlFor="campaign_id" className='control-label'>Campaign</label>
                    <Select
                        name='campaign_id'
                        value={submit.campaign_id}
                        placeholder='Select campaign for correction'
                        options={this.state.campaignsList}
                        searchable={true}
                        required={true}
                        onChange={this.updateSubmitState}
                    />
                    <small className="text-danger"/>
                </div>
                <div className='row'>
                    <div className="form-group col-md-3" id="profit-block">
                        <label htmlFor="profit" className='control-label center-block text-center'>Revenue</label>
                        <div className="input-group">
                            <input type="text" name="profit" value={submit.profit} className="form-control text-center" placeholder="0" onChange={this.handleChangeFloat} autoComplete="off" required/>
                            <span className="input-group-addon">$</span>
                        </div>
                        <small className="text-danger"/>
                    </div>
                    <div className="form-group col-md-3" id="paid-block">
                        <label htmlFor="paid" className='control-label center-block text-center'>Paid</label>
                        <div className="input-group">
                            <input type="text" name="paid" value={submit.paid} className="form-control text-center" placeholder="0" onChange={this.handleChangeFloat} autoComplete="off" required/>
                            <span className="input-group-addon">$</span>
                        </div>
                        <small className="text-danger"/>
                    </div>
                    <div className="form-group col-md-6" id="date-block">
                        <label htmlFor="date" className='control-label'>Date</label>
                        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={
                            <Popover id="calendar-popover" title="Calendar" bsClass='calendar-popover popover'>
                                <Calendar date={submit.date} onChange={this.handleChangeDate}/>
                            </Popover>
                        }>
                            <input type="text" name="date" value={DateTime.dateToYmd(submit.date)} className="form-control text-center date" autoComplete='off' required readOnly />
                        </OverlayTrigger>
                        <small className="text-danger"/>
                    </div>
                </div>
                <div className="form-group" id="description-block">
                    <label htmlFor="description" className='control-label'>Description</label>
                    <textarea name='description' value={submit.description} className="form-control" onChange={this.handleChange} />
                    <small className="text-danger"/>
                </div>
            </form>
        );
    }
}

Form.propTypes = {
    handleSave: PropTypes.func.isRequired,
};

