import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import Form from './Form';
import SuccessEvent from "../../../components/Alerts/SuccessEvent";

export default class Create extends Component {

    /**
     * @param {User} data
     */
    handleSubmit = ({data}) => {
        SuccessEvent.created();

        this.props.history.push(`/users/${data.id}`);
    };

    render() {
        return (
            <Panel>
                <Panel.Body className='unpadding'>
                    <Form handleSubmit={this.handleSubmit} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <NavLink to='/users' className='btn btn-default panel-footer__button panel-footer__button_left'>
                        &nbsp;<span className='glyphicon glyphicon-remove' />&nbsp;
                    </NavLink>
                    <button className='btn btn-primary pull-right panel-footer__button panel-footer__button_right' type='submit' form='user-form'>
                        &nbsp;<span className='glyphicon glyphicon-ok' />&nbsp;
                    </button>
                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}
