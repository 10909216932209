import React, {Component} from 'react';
import PropTypes from "prop-types";
import StatsData from "../../../../api-data/Manager/Structs/StatsData";
import ColorNumber from "../../../../components/ColorNumber";
import Number from "../../../../components/Formatting/Number";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import StatisticsColumns from "../../../../api-data/Enums/StatisticsColumns";

/**
 * Низ таблицы
 */
export default class CampaignFooter extends Component {

    render () {
        const total = this.props.total;

        return (
            <tr>
                <th colSpan={7} />
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.TotalImpressions)}>{Number(total.total_impressions)}</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.Impressions)}>{Number(total.impressions)}</th>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.UnshownImpressions}>{Number(total.unshown_impressions)}</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.UnshownImpressions)}>{Number(total.unshown_impressions_percent)}%</th>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.BackbuttonImpressions}>{Number(total.backbutton_impressions)}</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.BackbuttonImpressions)}>{Number(total.backbutton_impressions_percent)}%</th>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.UnshownBackbuttonImpressions}>{Number(total.unshown_backbutton_impressions)}</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.UnshownBackbuttonImpressions)}>{Number(total.unshown_backbutton_impressions_percent)}%</th>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.BackurlImpressions}>{Number(total.backurl_impressions)}</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.BackurlImpressions)}>{Number(total.backurl_impressions_percent)}%</th>
                </OverlayTrigger>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.Conversions)}>{Number(total.conversions)}</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.CR)}>{Number(total.cr, 4)}%</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.BackbuttonConversions)}>{Number(total.backbutton_conversions)}</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.BBCR)}>{Number(total.bb_cr, 4)}%</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.Revenue)}>{Number(total.revenue, 2)}$</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.Paid)}>{Number(total.paid, 2)}$</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.Profit)}>
                    <ColorNumber value={total.profit} precision={2} postfix='$'/>
                </th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.ECPM)}>{Number(total.ecpm, 4)}$</th>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.ECPMPub)}>{Number(total.ecpm_pub, 4)}$</th>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.BotImpressions}>{Number(total.bot_impressions)}</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.BotImpressions)}>{Number(total.bot_impressions_percent)}%</th>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.HostingImpressions}>{Number(total.hosting_impressions)}</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.HostingImpressions)}>{Number(total.hosting_impressions_percent)}%</th>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.IframeImpressions}>{Number(total.iframe_impressions)}</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.IframeImpressions)}>{Number(total.iframe_impressions_percent)}%</th>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.ProxyImpressions}>{Number(total.proxy_impressions)}</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.ProxyImpressions)}>{Number(total.proxy_impressions_percent)}%</th>
                </OverlayTrigger>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.InappImpressions}>{Number(total.inapp_impressions)}</Tooltip>}>
                    <th hidden={this.props.isHiddenColumn(StatisticsColumns.InappImpressions)}>{Number(total.inapp_impressions_percent)}%</th>
                </OverlayTrigger>
                <th hidden={this.props.isHiddenColumn(StatisticsColumns.RevShare)}>{total.rev_share}%</th>
            </tr>
        );
    }
}

CampaignFooter.propTypes = {
    total: PropTypes.instanceOf(StatsData).isRequired,
    isHiddenColumn: PropTypes.func.isRequired,
};
