import React, {Component} from 'react';
import PropTypes from "prop-types";
import CampaignStatuses from "../api-data/Enums/CampaignStatuses";

/**
 * Отображение иконки соответствующей статусу кампании
 */
export default class CampaignStatusIco extends Component {

    render() {
        switch (this.props.status) {
            case CampaignStatuses.Started:
                return <span className='glyphicon glyphicon-play-circle text-success'/>;
            case CampaignStatuses.Waiting:
                return <span className='glyphicon glyphicon-refresh text-info'/>;
            case CampaignStatuses.Paused:
                return <span className='glyphicon glyphicon-pause text-warning'/>;
            case CampaignStatuses.Finished:
                return <span className='glyphicon glyphicon-stop text-danger'/>;
            default:
                return '';
        }
    }
}

CampaignStatusIco.propTypes = {
    status: PropTypes.string.isRequired,
};
