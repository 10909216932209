import React, {Component} from 'react';
import PropTypes from "prop-types";
import StatsData from "../../../../api-data/Advertiser/Structs/StatsData";
import ColorNumber from "../../../../components/ColorNumber";
import Number from "../../../../components/Formatting/Number";

/**
 * Низ таблицы
 */
export default class Footer extends Component {

    render () {
        const total = this.props.total;

        return (
            <tr>
                <th colSpan={2} />
                <th>{Number(total.impressions)}</th>
                <th>{Number(total.conversions)}</th>
                <th>{Number(total.cr, 4)}%</th>
                <th>
                    <ColorNumber value={total.revenue} precision={2} postfix='$'/>
                </th>
                <th>{Number(total.ecpm, 4)}$</th>
            </tr>
        );
    }
}

Footer.propTypes = {
    total: PropTypes.instanceOf(StatsData).isRequired,
};
