import React from 'react';
import {Modal, OverlayTrigger, Popover} from 'react-bootstrap';
import PropTypes from 'prop-types';
import SelectOptions from '../../../components/Select/SelectOptions';
import ListAPI from '../../../api-data/Manager/ListAPI';
import DangerEvent from '../../../components/Alerts/DangerEvent';
import Select from '../../../components/Select/Select';
import BaseForm from '../../../components/BaseForm';
import DateTime from '../../../lib/DateTime';
import Calendar from 'react-date-range/dist/components/Calendar';
import InvoiceAPI from '../../../api-data/Manager/InvoiceAPI';
import UserAPI from '../../../api-data/Manager/UserAPI';

/**
 * @param {User|null} user
 * @param {Number} amount
 * @param {Function} onChange
 * @return {JSX.Element}
 * @constructor
 */
function AmountInputBlock({user, amount, onChange}) {
    if (!user) {
        return (
            <div className="input-group">
                <input type="text" name="amount" value={amount} className="form-control text-center" placeholder="0.10" onChange={onChange} autoComplete="off" required/>
                <span className="input-group-addon">$</span>
            </div>
        );
    }

    let total = Number(amount) + Number(user.balance);
        total = isNaN(total) ? user.balance: total;

    return (
        <div className="input-group">
            <span className="input-group-addon">{user.balance}</span>
            <input type="text" name="amount" value={amount} className="form-control text-center" placeholder="0.10" onChange={onChange} autoComplete="off" required/>
            <span className="input-group-addon">= {total}$</span>
        </div>
    );
}

export default class CreateInvoiceModal extends BaseForm {

    state = {
        submit: {
            date: new Date(),
            user_id: 0,
            amount: 0,
            comment: '',
        },
        users: new SelectOptions(),
        user: null,
    };

    componentDidMount() {
        ListAPI.users()
            .then((users) => this.setState({users: SelectOptions.createByIdPrettyName(users)}))
            .catch(DangerEvent.onSystemError);
    }

    loadUser = (id) => {
        UserAPI.getById(id)
            .then((user) => this.setState({user: user}))
            .catch(DangerEvent.onSystemError)
    };

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        let submit  = this.state.submit;
        submit.date = DateTime.dateToYmd(submit.date);

        InvoiceAPI.store(submit)
            .then(this.props.onSubmit)
            .catch(this.errorCallback);
    };

    render() {
        return (
            <Modal show={true} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <form className='create-invoice-form' id='create-invoice-form' onSubmit={this.handleSubmit}>
                        <div className="form-group" id="zone_id-block">
                            <label htmlFor="zone_id" className='control-label'>Users</label>
                            <Select
                                name='user_id'
                                value={this.state.submit.user_id}
                                placeholder='Select user for changing balance'
                                options={this.state.users}
                                searchable={true}
                                required={true}
                                onChange={(name, value) => {
                                    this.updateSubmitState(name, value);
                                    this.loadUser(value);
                                }}
                            />
                            <small className="text-danger"/>
                        </div>
                        <div className='row'>
                            <div className="form-group col-sm-6" id="date-block">
                                <label htmlFor="date" className='control-label'>Date</label>
                                <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={
                                    <Popover id="calendar-popover" title="Calendar" bsClass='calendar-popover popover'>
                                        <Calendar date={this.state.submit.date} onChange={this.handleChangeDate}/>
                                    </Popover>
                                }>
                                    <input type="text" name="date" value={DateTime.dateToYmd(this.state.submit.date)} className="form-control text-center date" autoComplete='off' required readOnly />
                                </OverlayTrigger>
                                <small className="text-danger"/>
                            </div>
                            <div className="form-group col-sm-6" id="amount-block">
                                <label htmlFor="amount" className='control-label'>Amount</label>
                                <AmountInputBlock user={this.state.user} amount={this.state.submit.amount} onChange={this.handleChangeFloat} />
                                <small className="text-danger"/>
                            </div>
                        </div>
                        <div className="form-group" id="comment-block">
                            <label htmlFor="comment" className='control-label'>Comment</label>
                            <textarea name='comment' value={this.state.submit.comment} className="form-control" onChange={this.handleChange} />
                            <small className="text-danger"/>
                        </div>
                    </form>
                </Modal.Body>

                <div className="clearfix"/>

                <Modal.Footer>
                    <button onClick={this.props.onHide} className='btn btn-default modal-footer__button modal-footer__button_left'>
                        &nbsp;<span className='glyphicon glyphicon-remove' />&nbsp;
                    </button>
                    <button className='btn btn-primary pull-right modal-footer__button modal-footer__button_right' type='submit' form='create-invoice-form'>
                        &nbsp;<span className='glyphicon glyphicon-ok' />&nbsp;
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

CreateInvoiceModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};