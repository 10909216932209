import React, {Component} from 'react';

export default class SimpleHeader extends Component {

    render () {
        return (
            <thead>
            <tr className='user-table__head'>
                <td className='user-table__head_id'>ID</td>
                <td className='user-table__head_name'>Name</td>
                <td className='user-table__head_email'>E-mail</td>
                <td className='user-table__head_manager'>Manager</td>
                <td className='user-table__head_role'>Role</td>
                <td className='user-table__head_balance'>Balance</td>
                <td className='user-table__head_actions'>Actions</td>
            </tr>
            </thead>
        );
    }
}