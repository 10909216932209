import React, {Component} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import _ from 'lodash';
import UserLink from '../../../components/ObjectLinks/UserLink';
import ColorNumber from "../../../components/ColorNumber";
import InvoiceResource from '../../../api-data/Manager/Structs/InvoiceResource';
import DateTime from '../../../lib/DateTime';
import HowOld from '../../../components/HowOld';

/**
 * Строка с информацией об операции
 */
class InvoiceRow extends Component {

    render () {
        const invoice = this.props.invoice;

        return (
            <tr>
                <td className='invoices-table_body_creator'>
                    <UserLink id={invoice.creator.id} name={invoice.creator.pretty_name} is_blank={true} />
                </td>
                <td className='invoices-table_body_user'>
                    <UserLink id={invoice.user.id} name={invoice.user.pretty_name} is_blank={true} />
                </td>
                <td className="invoices-table_body_comment">
                    {invoice.comment}
                </td>
                <td className="invoices-table_body_amount">
                    <ColorNumber value={invoice.amount} precision={2} postfix='$' />
                </td>
                <td className='invoices-table_body_date'>{DateTime.dateToYmd(invoice.date)}</td>
                <td className='invoices-table_body_created-at'>
                    <HowOld date={invoice.created_at} />
                </td>
            </tr>
        );
    }
}

InvoiceRow.propTypes = {
    invoice: PropTypes.instanceOf(InvoiceResource).isRequired,
};

/**
 * @param {InvoiceResource} invoice
 * @return {Array}
 * @constructor
 */
function InvoicesList ({invoices}) {
    return _.map(invoices, (invoice, key) => <InvoiceRow invoice={invoice} key={key} />);
}

InvoicesList.propTypes = {
    invoices: PropTypes.arrayOf(PropTypes.instanceOf(InvoiceResource)).isRequired,
};

/**
 * Таблица отображения записей биллинга
 */
export default class InvoicesTable extends Component {
    
    render() {
        return (
            <Table striped bordered className='invoices-table'>
                <thead>
                <tr>
                    <th className='invoices-table_head_creator'>Creator</th>
                    <th className='invoices-table_head_user'>User</th>
                    <th className='invoices-table_head_comment'>Comment</th>
                    <th className='invoices-table_head_amount'>Amount</th>
                    <th className='invoices-table_head_date'>Date</th>
                    <th className='invoices-table_head_created-at'>Created At</th>
                </tr>
                </thead>

                <tbody>
                    <InvoicesList invoices={this.props.invoices} />
                </tbody>
            </Table>
        );
    }
}

InvoicesTable.propTypes = {
    invoices: PropTypes.arrayOf(PropTypes.instanceOf(InvoiceResource)).isRequired,
};

