import _ from 'lodash';
import axios from "axios/index";
import Correction from './Structs/Correction';

export default class CorrectionsAPI {

    /**
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/manager/corrections';
    };

    /**
     * @return {Promise}
     */
    static list() {
        return axios.get(CorrectionsAPI.link)
            .then(({data}) => _.map(data.data, (correction) => new Correction(correction)));
    }

    /**
     * Сохранение данных о s2s request
     *
     * @param data
     * @return {Promise}
     */
    static save(data) {
        return axios.post(CorrectionsAPI.link, data)
            .then(({data}) => new Correction(data));
    }
}
