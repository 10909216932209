import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Panel} from 'react-bootstrap';
import PropTypes from "prop-types";
import UserAPI from "../../../api-data/Manager/UserAPI";
import User from "../../../api-data/Manager/Structs/User";
import Loading from "../../../components/Loading";
import '../../../styles/css/users/user-list.css';
import qs from "query-string";
import PaginationConstants from "../../../api-data/Enums/PaginationConstants";
import History from "../../../lib/History";
import Pagination from "../../../components/Pagination";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import UsersList from "./UsersList";

/**
 * Список пользователей
 */
export default class Users extends Component {

    constructor(props) {
        super(props);

        const query = qs.parse(this.props.location.search);

        this.state = {
            searchParams: {
                id:         query.id || '',
                name:       query.name || '',
                email:      query.email || '',
                manager_id: query.manager_id || '',
                role:       query.role || '',
            },
            isReady: false,
            currentPage: query.page || PaginationConstants.defaultPage,
            lastPage: PaginationConstants.defaultPage,
            usersList: []
        };
    }

    /**
     * При инициализации компонента загружаем список пользователей
     */
    componentWillMount() {
        this.loadUsersList();
    }

    loadUsersList = () => {
        const searchParams = {...this.state.searchParams, page: this.state.page};

        History.update(this.props.history, searchParams);

        UserAPI.list(searchParams)
            .then((response) => this.setState({currentPage: response.current_page, lastPage: response.last_page, usersList: response.users, isReady: true}))
            .catch(DangerEvent.onSystemError);
    };

    /**
     * Обновляем историю браузера, при смене страницы
     *
     * @param page
     */
    onChangePage = (page) => {
        this.setState({currentPage: page}, this.loadUsersList);
    };

    /**
     * При смене фильтра, сбрасываем и страницу на 1-ю
     * @param searchParams
     */
    onChangeFilter = (searchParams) => {
        this.setState({currentPage: PaginationConstants.defaultPage, searchParams: searchParams}, this.loadUsersList)
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel className='users-list-block'>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <div className='pull-right'>
                        <NavLink to='/users/create' className='btn btn-block btn-success panel-heading__button panel-heading__button_right'>
                            &nbsp;<span className='glyphicon glyphicon-plus'/>&nbsp;
                        </NavLink>
                    </div>
                </Panel.Heading>

                <Panel.Body className='panel-body panel-body_unbordered'>
                    <UsersList.Table>
                        <UsersList.SearchHeader defaultValues={this.state.searchParams} handleFilters={this.onChangeFilter} />
                        <UsersList.Body users={this.state.usersList} />
                    </UsersList.Table>
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <div className='col-sm-12 panel-footer_pagination-wrapper'>
                        {this.refFilters !== null && <Pagination
                            onChangePage={(page) => this.onChangePage(page)}
                            current_page={this.state.currentPage}
                            last_page={this.state.lastPage}
                        />}
                    </div>

                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}

Users.propTypes = {
    authUser: PropTypes.instanceOf(User).isRequired
};
