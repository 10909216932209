import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import CampaignAPI from '../../../api-data/Advertiser/CampaignAPI';
import CampaignsListTable from "./CampaignsListTable";
import Loading from "../../../components/Loading";
import qs from "query-string";
import PaginationConstants from "../../../api-data/Enums/PaginationConstants";
import History from "../../../lib/History";
import Pagination from "../../../components/Pagination";
import DangerEvent from "../../../components/Alerts/DangerEvent";

/**
 * Индексная страница, на которой отображается список кампаний
 */
export default class Campaigns extends Component {

    constructor(props) {
        super(props);

        const query = qs.parse(this.props.location.search);

        this.state = {
            isReady: false,
            currentPage: query.page || PaginationConstants.defaultPage,
            lastPage: PaginationConstants.defaultPage,
            campaignsList: []
        };
    }

    /**
     * Загружаем список кампаний
     */
    componentWillMount() {
        this.loadCampaignsList();
    }

    /**
     * Загрузка списка кампаний
     */
    loadCampaignsList = () => {
        CampaignAPI.list(
            this.state.currentPage,
            (response) => this.setState({currentPage: response.current_page, lastPage: response.last_page, campaignsList: response.campaigns, isReady: true}),
            DangerEvent.onSystemError
        );
    };

    /**
     * Обновляем историю браузера, при смене страницы
     *
     * @param page
     */
    onChangePage = (page) => {
        this.setState({currentPage: page}, () => {
            History.update(this.props.history, {page: page});
            this.loadCampaignsList();
        });
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel>
                <Panel.Heading className='panel-heading_top-rounded'/>

                <Panel.Body>
                    <CampaignsListTable list={this.state.campaignsList} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <div className='col-sm-12 panel-footer_pagination-wrapper'>
                        {this.refFilters !== null && <Pagination
                            onChangePage={(page) => this.onChangePage(page)}
                            current_page={this.state.currentPage}
                            last_page={this.state.lastPage}
                        />}
                    </div>

                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}
