import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import StatisticsTable from "./partials/StatisticsTable";
import StatsData from "../../../api-data/Publisher/Structs/StatsData";
import Filters from "./components/Filters";
import '../../../styles/css/publisher/statistics/index.css';
import PaginationConstants from "../../../api-data/Enums/PaginationConstants";
import Pagination from "../../../components/Pagination";

/**
 * Страница статистики
 */
export default class Statistics extends Component {

    state = {
        currentPage: PaginationConstants.defaultPage,
        lastPage: PaginationConstants.defaultPage,
        data: [],
        totalData: new StatsData({}),
    };

    /**
     * Создадим реф в поле `refFilters` для хранения ссылки на элемент фильтров
     *
     * @type {Object}
     */
    refFilters = null;

    /**
     * Создадим реф в поле `refStatistics` для хранения ссылки на элемент таблицы статистики
     *
     * @type {Object}
     */
    refStatistics = null;

    /**
     * При полной загрузке компонента отправляем запрос на получение статы
     */
    componentDidMount = () => {
        this.refFilters.handleSubmit(this.refFilters.getGroupBy());
    };

    /**
     * Перед получением статы, очищаем текущую
     */
    beforeSubmitFilters = () => {
        this.setState({data: [], totalData: new StatsData({})});
    };

    /**
     * Обновляем текущее сотояние новыми данными
     *
     * @param data
     */
    afterSubmitFilters = (data) => {
        this.setState({
            currentPage: data.current_page,
            lastPage: data.last_page,
            data: data.data,
            totalData: data.total_data
        });
    };

    render() {
        return (
            <div className='row'>
                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <Filters
                            ref={ref => this.refFilters = ref}
                            search={this.props.location.search}
                            history={this.props.history}
                            beforeSubmit={this.beforeSubmitFilters}
                            afterSubmit={this.afterSubmitFilters}
                        />
                    </Panel.Heading>

                    <Panel.Body className='panel-body panel-body_unbordered panel-body_horizontal-scroll'>
                        {this.refFilters !== null && <StatisticsTable
                            ref={ref => this.refStatistics = ref}
                            onSelect={this.refFilters.addToFilter}
                            onUnselect={this.refFilters.removeFromFilter}
                            sortBy={this.refFilters.updateSortBy}
                            group_by={this.refFilters.getGroupBy()}
                            selected={this.refFilters.getSelectedElements()}
                            statistics={this.state.data}
                            total={this.state.totalData}
                        />}
                    </Panel.Body>

                    <Panel.Footer className='panel-footer_bottom-rounded'>
                        <div className='col-sm-3 col-sm-push-4 panel-footer_pagination-wrapper'>
                            {this.refFilters !== null && <Pagination
                                onChangePage={(page) => this.refFilters.updateCurrentPage(page)}
                                current_page={this.state.currentPage}
                                last_page={this.state.lastPage}
                            />}
                        </div>

                        <div className='clearfix'/>
                    </Panel.Footer>
                </Panel>
            </div>
        );
    }
}
