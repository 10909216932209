import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

/**
 * Кнопка просмотра
 */
export default class ShowButton extends Component {

    render() {
        return (
            <NavLink to={this.props.route} className='btn btn-warning'>
                <span className='glyphicon glyphicon-eye-open'/>
            </NavLink>
        );
    }
}

ShowButton.propTypes = {
    route: PropTypes.string.isRequired,
};
