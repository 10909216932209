/**
 * Константы для полей сортировки
 */
import StatisticsGroupBy from "./StatisticsGroupBy";

export default class StatisticsColumns {
    /**
     * @returns {string}
     * @constructor
     */
    static get GroupBy() {
        return 'group_by';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get TotalImpressions() {
        return 'total_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get Impressions() {
        return 'impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get UnshownImpressions() {
        return 'unshown_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get BackbuttonImpressions() {
        return 'backbutton_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get UnshownBackbuttonImpressions() {
        return 'unshown_backbutton_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get BackurlImpressions() {
        return 'backurl_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get Conversions() {
        return 'conversions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get BackbuttonConversions() {
        return 'backbutton_conversions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get BotImpressions() {
        return 'bot_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get ProxyImpressions() {
        return 'proxy_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get HostingImpressions() {
        return 'hosting_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get IframeImpressions() {
        return 'iframe_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get InappImpressions() {
        return 'inapp_impressions';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get Paid() {
        return 'paid';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get Profit() {
        return 'profit';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get Revenue() {
        return 'revenue';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get ECPM() {
        return 'ecpm';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get ECPMPub() {
        return 'ecpm_pub';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get CR() {
        return 'cr';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get BBCR() {
        return 'bb_cr';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get ImpressionsPercent() {
        return 'impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get UnshownImpressionsPercent() {
        return 'unshown_impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get BackbuttonImpressionsPercent() {
        return 'backbutton_impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get UnshownBackbuttonImpressionsPercent() {
        return 'unshown_backbutton_impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get BackurlImpressionsPercent() {
        return 'backurl_impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get BotImpressionsPercent() {
        return 'bot_impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get HostingImpressionsPercent() {
        return 'hosting_impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get IframeImpressionsPercent() {
        return 'iframe_impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get ProxyImpressionsPercent() {
        return 'proxy_impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get InappImpressionsPercent() {
        return 'inapp_impressions_percent';
    };

    /**
     * @returns {string}
     * @constructor
     */
    static get RevShare() {
        return 'rev_share';
    };

    /**
     * Возвращает поле сортировки по типу группировки
     *
     * @param groupBy
     * @constructor
     */
    static FieldByGroupBy(groupBy) {
        switch (groupBy) {
            case StatisticsGroupBy.day:
            case StatisticsGroupBy.hour:
                return StatisticsColumns.GroupBy;

            default:
                return StatisticsColumns.Profit;
        }
    }

    /**
     * Возвращает поле сортировки по типу группировки для адветайзера
     *
     * @param groupBy
     * @constructor
     */
    static FieldByGroupByForAdvertiser(groupBy) {
        switch (groupBy) {
            case StatisticsGroupBy.day:
            case StatisticsGroupBy.hour:
                return StatisticsColumns.GroupBy;

            default:
                return StatisticsColumns.Revenue;
        }
    }

    /**
     * Возвращает поле сортировки по типу группировки для паблишера
     *
     * @param groupBy
     * @constructor
     */
    static FieldByGroupByForPublisher(groupBy) {
        switch (groupBy) {
            case StatisticsGroupBy.day:
            case StatisticsGroupBy.hour:
                return StatisticsColumns.GroupBy;

            default:
                return StatisticsColumns.Paid;
        }
    }
}
