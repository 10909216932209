import axios from "axios";
import StatisticsAPIResponse from "./Structs/StatisticsAPIResponse";

/**
 * Компонент для получения данных о статистике
 */
export default class StatisticsAPI {

    static get link() {
        return process.env.REACT_APP_API_URL + '/manager/statistics';
    };

    /**
     * @param {StatisticsRequest} request
     *
     * @return {Promise}
     */
    static send(request) {
        return axios.get(StatisticsAPI.link, {params: request})
            .then((response) => new StatisticsAPIResponse(response));
    };
}
