import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Panel, Table} from 'react-bootstrap';
import ZoneAPI from "../../../api-data/Manager/ZoneAPI";
import DeleteButton from "../../../buttons/DeleteButton";
import Loading from "../../../components/Loading";
import PropTypes from "prop-types";
import IsOk from "../../../components/IsOk";
import ColorNumber from "../../../components/ColorNumber";
import CampaignLink from "../../../components/ObjectLinks/CampaignLink";
import StatisticsGroupBy from "../../../api-data/Enums/StatisticsGroupBy";
import CopyToClipboard from "../../../components/CopyToClipboard";
import {Redirect} from "react-router";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import HowOld from "../../../components/HowOld";
import InfoEvent from "../../../components/Alerts/InfoEvent";
import CampaignsList from '../Campaigns/CampaignsList';

/**
 * Компонент отображает полную информацию о зоне
 */
export default class Show extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isReady: false,
            isDeleted: false,
            zone: null,
        };
    }

    /**
     * При инициализации компонента, загружаем данные о зоне
     */
    componentWillMount() {
        ZoneAPI.getById(this.props.id, (zone) => this.setState({zone: zone, isReady: true}), DangerEvent.onSystemError);
    }

    /**
     * Метод удаления зоны
     */
    deleteZone = () => {
        ZoneAPI.delete(this.props.id, () => this.setState({isDeleted: true}, InfoEvent.deleted), DangerEvent.onSystemError);
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        if (this.state.isDeleted) {
            return <Redirect to='/zones' />
        }

        return (
            <Panel>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <NavLink to='/zones' className='btn btn-default panel-heading__button panel-heading__button_left'>
                        <span className='glyphicon glyphicon-chevron-left'/>
                        <span className='glyphicon glyphicon-chevron-left'/>
                    </NavLink>
                    <div className='panel-heading__button-group panel-heading__button-group_right btn-group'>
                        <NavLink to={`/statistics?${StatisticsGroupBy.zone}[]=${this.props.id}`} className='btn btn-info'>
                            &nbsp;<span className='glyphicon glyphicon-stats'/>&nbsp;
                        </NavLink>
                        <NavLink to={`/postbacks?${StatisticsGroupBy.zone}[]=${this.props.id}`} className='btn btn-warning'>
                            &nbsp;<span className='glyphicon glyphicon-transfer'/>&nbsp;
                        </NavLink>
                        <NavLink to={`/zones/${this.props.id}/edit`} className='btn btn-primary'>
                            &nbsp;<span className='glyphicon glyphicon-pencil'/>&nbsp;
                        </NavLink>
                        <DeleteButton callback={this.deleteZone} className='btn btn-danger' />
                    </div>
                </Panel.Heading>

                <Panel.Body>
                    <Table bordered>
                        <tbody>
                        <tr>
                            <th>ID</th>
                            <td>{this.state.zone.id}</td>
                        </tr>
                        <tr>
                            <th>Publisher</th>
                            <td>
                                <NavLink to={'/users/' + this.state.zone.user.id}>
                                    {this.state.zone.user.pretty_name}
                                </NavLink>
                            </td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{this.state.zone.name}</td>
                        </tr>
                        <tr>
                            <th>Type</th>
                            <td>{this.state.zone.type}</td>
                        </tr>
                        <tr>
                            <th>Rotation Type</th>
                            <td>{this.state.zone.rotation_type}</td>
                        </tr>
                        <tr>
                            <th>Direct link</th>
                            <td className='unpadding'>
                                <CopyToClipboard value={this.state.zone.direct_link} />
                            </td>
                        </tr>
                        {this.state.zone.inherit_repostback_url === false && this.state.zone.repostback_url !== '' &&
                            <tr>
                                <th>Repostback URL</th>
                                <td className='unpadding'>
                                    <CopyToClipboard value={this.state.zone.repostback_url} />
                                </td>
                            </tr>
                        }
                        {this.state.zone.inherit_repostback_url === true && this.state.zone.user.repostback_url !== '' &&
                            <tr>
                                <th>Repostback URL</th>
                                <td className='unpadding'>
                                    <CopyToClipboard value={this.state.zone.user.repostback_url} />
                                </td>
                            </tr>
                        }
                        {this.state.zone.inherit_back_url === false && this.state.zone.back_url !== '' &&
                            <tr>
                                <th>Back traffic URL</th>
                                <td className='unpadding'>
                                    <CopyToClipboard value={this.state.zone.back_url} />
                                </td>
                            </tr>
                        }
                        {this.state.zone.inherit_back_url === true && this.state.zone.user.back_url !== '' &&
                            <tr>
                                <th>Back traffic URL</th>
                                <td className='unpadding'>
                                    <CopyToClipboard value={this.state.zone.user.back_url} />
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>Enabled quality page</th>
                            <td>
                                <IsOk isOk={this.state.zone.is_enabled_quality_page}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Allow to rotate in BB</th>
                            <td>
                                <IsOk isOk={this.state.zone.allow_to_backbutton}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Created At</th>
                            <td>
                                <HowOld date={this.state.zone.created_at} />
                            </td>
                        </tr>
                        {this.state.zone.enabled_firewall === true &&
                            <tr>
                                <th>Firewall settings</th>
                                <td>
                                    <Table bordered striped>
                                        <thead>
                                        <tr>
                                            <td>Firewall Campaign</td>
                                            <td className='text-center'>Enabled Firewall</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                { this.state.zone.firewall_campaign &&
                                                    <CampaignLink id={this.state.zone.firewall_campaign.id} name={this.state.zone.firewall_campaign.pretty_name} is_blank={true}/>
                                                }
                                            </td>
                                            <td className='text-center'>
                                                <IsOk isOk={this.state.zone.enabled_firewall}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>Money</th>
                            <td className='unpadding'>
                                <Table bordered striped className='unmargin'>
                                    <thead>
                                    <tr>
                                        <td className='text-right'>Today profit $</td>
                                        <td className='text-right'>Total profit $</td>
                                        <td className='text-right'>Rate $</td>
                                        <td className='text-right'>Rev. share %</td>
                                        <td className='text-right'>Fix conv. rate $</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className='text-right'>
                                            <ColorNumber value={this.state.zone.money.today_profit} precision={2} postfix='$' />
                                        </td>
                                        <td className='text-right'>
                                            <ColorNumber value={this.state.zone.money.total_profit} precision={2} postfix='$' />
                                        </td>
                                        <td className='text-right'>{this.state.zone.rate}$</td>
                                        <td className='text-right'>{this.state.zone.rev_share}%</td>
                                        <td className='text-right'>{this.state.zone.fix_conversion_rate}$</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        </tbody>
                    </Table>

                    <Panel>
                        <Panel.Heading className='panel-heading_top-rounded'>
                            <Panel.Title>Main campaigns</Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>
                            <CampaignsList.Table>
                                <CampaignsList.SimpleHeader />
                                <CampaignsList.Body campaigns={this.state.zone.main_campaigns} />
                            </CampaignsList.Table>
                        </Panel.Body>
                    </Panel>

                    <Panel>
                        <Panel.Heading className='panel-heading_top-rounded'>
                            <Panel.Title>Backup campaigns</Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>
                            <CampaignsList.Table>
                                <CampaignsList.SimpleHeader />
                                <CampaignsList.Body campaigns={this.state.zone.backup_campaigns} />
                            </CampaignsList.Table>
                        </Panel.Body>
                    </Panel>
                </Panel.Body>
            </Panel>
        );
    }
}

Show.propTypes = {
    id: PropTypes.number.isRequired
};
