import React, {Component} from 'react';
import _ from 'lodash'
import EventInterpreter from "../lib/EventInterpreter";
import EventsAPI from "../api-data/Manager/EventsAPI";
import DangerEvent from "./Alerts/DangerEvent";
import DateTime from "../lib/DateTime";

export default class NavEvents extends Component {

    /**
     * Ссылка на элемент содержащий информацию о событиях
     *
     * @type {null|object}
     */
    ref = null;

    state = {
        oldEvents: [],
        newEvents: [],
        isOpenDropDown: false,
    };

    /**
     * Получаем список событий, которые пользователь еще не просмотрел
     */
    componentWillMount = () => this.refreshEventsList();

    /**
     * При инициализации компонента, вешаем событие на отлов кликов
     */
    componentDidMount = () => document.addEventListener('click', this.handleClickOutside);

    /**
     * Когда компонент уничтожается, убираем событие по отлову кликов
     */
    componentWillUnmount = () => document.removeEventListener('click', this.handleClickOutside);

    /**
     * Добавляет новый объект Event в список событий
     *
     * @param event
     */
    addEvent = (event) => {
        let events = this.state.newEvents;
            events.unshift(event);

        this.setState({newEvents: events});
    };

    /**
     * Обновляет список событий
     */
    refreshEventsList = () => {
        let startDate = new Date();
            startDate.setDate(startDate.getDate() - 7);
            startDate = DateTime.dateToYmd(startDate);

        EventsAPI.list(100, startDate,
            (events) => {
                let newEvents = _.filter(events, ['is_viewed', false]);
                let oldEvents = _.filter(events, ['is_viewed', true]);

                this.setState({newEvents: newEvents, oldEvents: oldEvents});
            },
            DangerEvent.onSystemError
        );
    };

    /**
     * Преобразоывает Event в читаемый вид
     *
     * @param events
     * @returns {Array}
     */
    transformEvents = (events) => {
        return _.map(events, (e) => {
            const event = EventInterpreter.init(e);

            return (
                <div className='event-info' key={e.id}>
                    <div className={'circle circle__' + event.color()}/>
                    <div className='event-info_title cut-off'>{event.title()}</div>
                    <div className='event-info_time'>{event.date()}</div>
                    <div className='event-info_description'>{event.description()}</div>
                </div>
            );
        });
    };

    /**
     * В случае, если клик произошел вне блока событий
     * Закрываем окно
     *
     * @param {object} e
     */
    handleClickOutside = (e) => {
        const clicked = this.ref.contains && this.ref.contains(e.target);

        if (clicked || !this.state.isOpenDropDown) {
            return;
        }

        this.setState({isOpenDropDown: false});

        // В случа если есть новые события
        // Обновляем все events до самого последнего в списке, как просмотренные
        if (this.state.newEvents.length > 0) {
            EventsAPI.viewedBefore(_.first(this.state.newEvents).id, this.refreshEventsList, DangerEvent.onSystemError);
        }
    };

    render() {
        let isOpen         = this.state.isOpenDropDown;
        let newEvents      = this.transformEvents(this.state.newEvents);
        let oldEvents      = this.transformEvents(this.state.oldEvents);
        let newEventsCount = this.state.newEvents.length;
        let oldEventsCount = this.state.oldEvents.length;

        return (
            <ul className="nav navbar-nav navbar-right">
                <li className='nav-events' id='nav-events' ref={ref => this.ref = ref}>
                    <button
                        className={'nav-events_button ' + (isOpen ? 'active': '')}
                        onClick={() => this.setState({isOpenDropDown: !isOpen})}
                    >
                        {newEventsCount > 0 &&
                            <span className="badge">{newEventsCount}</span>
                        }
                        <span className='glyphicon glyphicon-bell' />
                    </button>
                    {this.state.isOpenDropDown && (newEventsCount + oldEventsCount) > 0 &&
                        <div className='nav-events_drop-down'>
                            <div className='nav-events_drop-down_preview'>
                                <h4>Notifications</h4>
                                {newEventsCount > 0 &&
                                    <div>You have <span className='nav-events_drop-down_preview_notifications-count'>{newEventsCount}</span> unread messages</div>
                                }
                            </div>
                            <div className='events-list'>
                                {newEvents}

                                {newEventsCount > 0 && oldEventsCount > 0 &&
                                    <hr/>
                                }

                                {oldEvents}
                            </div>
                        </div>
                    }
                </li>
            </ul>
        );
    }
}

NavEvents.propTypes = {
};
