import axios from "axios/index";
import CampaignGroup from "./Structs/CampaignGroup";

/**
 * Компонент для получения данных о группах кампаний в системе
 */
export default class CampaignGroupAPI {

    /**
     * API URL для работы с группами кампаний
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/manager/campaign-groups';
    };

    /**
     * Получение кампании по id
     *
     * @param {Number} id
     * @param {Function} successCallback
     * @param {Function} errorCallback
     *
     * @return {Promise}
     */
    static getById(id, successCallback, errorCallback) {
        return axios.get(CampaignGroupAPI.link + '/' + id)
            .then(({data}) => new CampaignGroup(data))
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Сохранение данных о группе кампаний
     *
     * @param group
     *
     * @return {Promise}
     */
    static save(group) {
        return axios.post(CampaignGroupAPI.link, group)
            .then(({data}) => new CampaignGroup(data));
    }
}
