import React from 'react';
import CampaignStatuses from "../api-data/Enums/CampaignStatuses";
import EventTypes from "../api-data/Enums/EventTypes";
import HowOld from "../components/HowOld";
import ZoneLink from "../components/ObjectLinks/ZoneLink";
import UserLink from "../components/ObjectLinks/UserLink";
import CampaignLink from "../components/ObjectLinks/CampaignLink";
import StatisticsCorrectionStatus from '../api-data/Enums/StatisticsCorrectionStatus';

class EmptyEvent {
    /**
     * @type {Event}
     */
    event = null;

    /**
     * @param {Event} event
     */
    constructor(event) {
        this.event = event;
    }

    color = () => 'success';
    date  = () => <HowOld date={this.event.created_at}/>;
}

class ProcessedCorrectionEvent extends EmptyEvent
{
    /**
     * @type {string}
     */
    status = '';

    /**
     * @param {Event} event
     */
    constructor(event) {
        super(event);

        this.status = this.event.statisticsCorrection.status;
    }

    title       = () => <span>Correction status <span className={'text-' + this.color()}>{this.status}</span>!</span>;
    color       = () => StatisticsCorrectionStatus.statusColor(this.status);
    description = () => {
        return (
            <span><span className={'text-' + this.color()}>Correction</span> changed status to {this.status}.</span>
        );
    };
}

class S2SRequestDoneEvent extends EmptyEvent
{
    title       = () => <span>S2S Request <span className={'text-' + this.color()}>done</span>!</span>;
    description = () => {
        const zone     = this.event.s2sRequest.zone;
        const campaign = this.event.s2sRequest.campaign;

        return (
            <span><span className={'text-' + this.color()}>Complete</span> S2S request for <ZoneLink id={zone.id} name={zone.pretty_name}/> zone and <CampaignLink id={campaign.id} name={campaign.pretty_name} /> campaign.</span>
        );
    };
}

class S2SRequestCreatedEvent extends EmptyEvent {

    title       = () => <span>S2S Request <span className={'text-' + this.color()}>created</span>!</span>;
    description = () => {
        const zone     = this.event.s2sRequest.zone;
        const campaign = this.event.s2sRequest.campaign;

        return (
            <span>Created S2S request for <ZoneLink id={zone.id} name={zone.pretty_name}/> zone and <CampaignLink id={campaign.id} name={campaign.pretty_name} /> campaign.</span>
        );
    };
}

class PostbacksReportDoneEvent extends EmptyEvent {

    title       = () => <span>Postbacks report ready</span>;
    description = () => <span>Postbacks report {this.event.postbacksReport.name} is ready.</span>;
}

class CampaignChangedStatusEvent extends EmptyEvent {

    /**
     * @type {string}
     */
    status = '';

    /**
     * @param {Event} event
     */
    constructor(event) {
        super(event);

        this.status = EventTypes.toCampaignStatus(event.type);
    }

    title = () => <span>Campaign&nbsp;<span className={'text-' + this.color()}>{this.status}</span></span>;
    color = () => CampaignStatuses.statusColor(this.status);

    description = () => {
        const campaign = this.event.campaign;
        const creator  = this.event.creator
            ? <span>User <UserLink id={this.event.creator.id} name={this.event.creator.name}/></span>
            : 'The system user ';

        return (
            <span>
                {creator} changed the campaign <CampaignLink id={campaign.id} name={campaign.pretty_name} /> status to <span className={'text-' + this.color()}>{this.status}</span>
            </span>
        );
    };
}

export default class EventInterpreter {

    /**
     * @type {function(Event): (CampaignChangedStatusEvent|PostbacksReportDoneEvent |S2SRequestCreatedEvent |S2SRequestDoneEvent|null)}
     */
    static init = (event) => {
        switch (event.type) {
            case EventTypes.CampaignPaused:
            case EventTypes.CampaignStarted:
            case EventTypes.CampaignWaiting:
            case EventTypes.CampaignFinished:
                return new CampaignChangedStatusEvent(event);

            case EventTypes.PostbacksReportDone:
                return new PostbacksReportDoneEvent(event);

            case EventTypes.S2SRequestCreated:
                return new S2SRequestCreatedEvent(event);

            case EventTypes.S2SRequestDone:
                return new S2SRequestDoneEvent(event);

            case EventTypes.ProcessedCorrection:
                return new ProcessedCorrectionEvent(event);

            default:
                return null;
        }
    };
}
