import _ from 'lodash';
import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Panel, Table} from 'react-bootstrap';
import CampaignAPI from "../../../api-data/Manager/CampaignAPI";
import DeleteButton from "../../../buttons/DeleteButton";
import Progressbar from "../../../components/Progressbar";
import Loading from "../../../components/Loading";
import IsOk from "../../../components/IsOk";
import UserLink from "../../../components/ObjectLinks/UserLink";
import ZoneLink from "../../../components/ObjectLinks/ZoneLink";
import '../../../styles/css/campaigns/show.css';
import CampaignFormats from "../../../api-data/Enums/CampaignFormats";
import StatisticsGroupBy from "../../../api-data/Enums/StatisticsGroupBy";
import CampaignStatuses from "../../../api-data/Enums/CampaignStatuses";
import CopyToClipboard from "../../../components/CopyToClipboard";
import {Redirect} from "react-router";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import EventTypes from "../../../api-data/Enums/EventTypes";
import HowOld from "../../../components/HowOld";
import InfoEvent from "../../../components/Alerts/InfoEvent";
import CloneButton from '../../../buttons/CloneButton';
import EditButton from '../../../buttons/EditButton';
import StatisticsButton from '../../../buttons/StatisticsButton';
import ZonesList from '../Zones/ZonesList';

/**
 * Компонент преобразовывает список стран кампании в таблицу
 *
 * @param {CampaignCountry[]} countries
 * @return {JSX}
 * @constructor
 */
function CountryList({countries}) {
    const body   = _.map(countries, (country, key) => {
        return (
            <tr key={key}>
                <td>{country.pretty_name}</td>
                <td className='text-success'>{country.rate}$</td>
            </tr>
        )}
    );

    return (
        <Table bordered hover className='campaign-show campaign-show__country-rate-table unmargin'>
            <thead>
            <tr>
                <th className='campaign-show__country-rate-table__name'>Name</th>
                <th className='campaign-show__country-rate-table__rate'>Rate</th>
            </tr>
            </thead>

            <tbody>
            {body}
            </tbody>
        </Table>
    );
}

/**
 * Меняет цвет строки статуса
 *
 * @param status
 * @returns {*}
 */
function ColoredStatus({status}) {
    const color = CampaignStatuses.statusColor(status);

    return <span className={'text-' + color}>{status}</span>;
}

/**
 * Отображение таблицы смены статусов
 *
 * @param events
 * @returns {*}
 * @constructor
 */
function StatusChangesTable({events}) {
    const body = _.map(events, (event, key) => {
        const date     = <HowOld date={event.created_at} />;
        const status   = EventTypes.toCampaignStatus(event.type);
        const userLink = event.creator
            ? <UserLink id={event.creator.id} name={event.creator.pretty_name} is_blank={true} />
            : 'System';

        return (
            <tr key={key}>
                <td>{userLink}</td>
                <td><ColoredStatus status={status} /></td>
                <td>{date}</td>
            </tr>
        );
    });

    return (
        <Table bordered hover className='status-updates-table unmargin'>
            <thead>
            <tr>
                <th className='status-updates-table__head_user'>Manager</th>
                <th className='status-updates-table__head_new-status'>Status</th>
                <th className='status-updates-table__head_created-at'>Date</th>
            </tr>
            </thead>
            <tbody>
            {body}
            </tbody>
        </Table>
    );
}

/**
 * Компонент отображения информации о кампании
 */
export default class Show extends Component {

    state = {
        isReady: false,
        isDeleted: false,
        campaign: null,
    };

    /**
     * Загрузка кампании по id
     */
    componentWillMount() {
        CampaignAPI.getById(this.props.id, (campaign) => this.setState({campaign: campaign, isReady: true}), DangerEvent.onSystemError);
    }

    /**
     * Метод удаления кампании
     */
    deleteCampaign = () => {
        CampaignAPI.delete(this.state.campaign.id, () => this.setState({isDeleted: true}, InfoEvent.deleted), DangerEvent.onSystemError);
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        if (this.state.isDeleted) {
            return <Redirect to='/campaigns' />
        }

        return (
            <Panel>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <NavLink to='/campaigns' className='btn btn-default panel-heading__button panel-heading__button_left'>
                        <span className='glyphicon glyphicon-chevron-left'/>
                        <span className='glyphicon glyphicon-chevron-left'/>
                    </NavLink>
                    <div className='panel-heading__button-group panel-heading__button-group_right btn-group'>
                        <StatisticsButton route={`/statistics?${StatisticsGroupBy.campaign}[]=${this.state.campaign.id}`} />
                        <NavLink to={`/postbacks?${StatisticsGroupBy.campaign}[]=${this.state.campaign.id}`} className='btn btn-warning'>
                            <span className='glyphicon glyphicon-transfer'/>
                        </NavLink>
                        <EditButton route={`/campaigns/${this.state.campaign.id}/edit`} />
                        <CloneButton route={`/campaigns/${this.state.campaign.id}/clone`} />
                        <DeleteButton callback={this.deleteCampaign} className='btn btn-danger' />
                    </div>
                </Panel.Heading>

                <Panel.Body>
                    <Table bordered className='campaign-show'>
                        <tbody>
                        <tr>
                            <th>ID</th>
                            <td>{this.state.campaign.id}</td>
                        </tr>
                        <tr>
                            <th>Advertiser</th>
                            <td>
                                <UserLink id={this.state.campaign.user.id} name={this.state.campaign.user.pretty_name} />
                            </td>
                        </tr>
                        <tr>
                            <th>Group</th>
                            <td>{this.state.campaign.group.name}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{this.state.campaign.name}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td><ColoredStatus status={this.state.campaign.status} /></td>
                        </tr>
                        <tr hidden={this.state.campaign.format !== CampaignFormats.direct}>
                            <th>Link</th>
                            <td className='unpadding'>
                                <CopyToClipboard value={this.state.campaign.link} />
                            </td>
                        </tr>
                        {this.state.campaign.prelanding !== null && this.state.campaign.format === CampaignFormats.prelanding &&
                        <tr>
                            <th>Prelanding</th>
                            <td>{this.state.campaign.prelanding.pretty_name}</td>
                        </tr>
                        }
                        <tr hidden={this.state.campaign.format !== CampaignFormats.prelanding}>
                            <th>Link</th>
                            <td className='unpadding'>
                                <CopyToClipboard value={this.state.campaign.prelanding_link} />
                            </td>
                        </tr>
                        <tr>
                            <th>Postback link</th>
                            <td className='unpadding'>
                                <CopyToClipboard value={this.state.campaign.postback_link} />
                            </td>
                        </tr>
                        <tr>
                            <th>Format</th>
                            <td>{this.state.campaign.format}</td>
                        </tr>
                        <tr>
                            <th>Allow to rotate in BB</th>
                            <td>
                                <IsOk isOk={this.state.campaign.allow_to_backbutton}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Created At</th>
                            <td>
                                <HowOld date={this.state.campaign.created_at} />
                            </td>
                        </tr>
                        <tr className='money-block'>
                            <th>Money</th>
                            <td className='unpadding'>
                                <Table bordered striped className='unmargin'>
                                    <thead>
                                    <tr>
                                        <td className='money-block_frequency'>Frequency</td>
                                        <td className='money-block_rate'>Rate $</td>
                                        <td className='money-block_daily_limit'>
                                            Daily limit: {this.state.campaign.money.daily_limit ? this.state.campaign.money.daily_limit + '$': 'Unlimited'}
                                        </td>
                                        <td className='money-block_total_limit'>
                                            Total limit: {this.state.campaign.money.total_limit ? this.state.campaign.money.total_limit + '$': 'Unlimited'}
                                        </td>
                                        <td className='money-block_pm'>Price model (PM)</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className='text-center'>{this.state.campaign.frequency || '∞ / ∞'}</td>
                                        <td className='text-center'>{this.state.campaign.rate}$</td>
                                        <td>
                                            <Progressbar
                                                value={this.state.campaign.money.daily_limit_spent}
                                                max={this.state.campaign.money.daily_limit}
                                                unlimited={this.state.campaign.money.is_unlimited_daily}
                                            />
                                        </td>
                                        <td>
                                            <Progressbar
                                                value={this.state.campaign.money.total_limit_spent}
                                                max={this.state.campaign.money.total_limit}
                                                unlimited={this.state.campaign.money.is_unlimited_total}
                                            />
                                        </td>
                                        <td className='text-center'>{this.state.campaign.price_model}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <th>Targeting</th>
                            <td className='unpadding'>
                                <Table bordered hover className='targeting-table unmargin'>
                                    <tbody>
                                    <tr>
                                        <td>Countries</td>
                                        <td className='unpadding'>
                                            <CountryList countries={this.state.campaign.countries} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Carriers</td>
                                        <td>{this.state.campaign.carriers.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td>Browsers</td>
                                        <td>{this.state.campaign.browsers.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td>Devices</td>
                                        <td>{this.state.campaign.devices.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td>OSes</td>
                                        <td>{this.state.campaign.oses.join(', ')}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <th>Blacklist</th>
                            <td className='unpadding'>
                                <Table bordered hover className='blacklist-table unmargin'>
                                    <tbody>
                                    <tr>
                                        <td>Publishers</td>
                                        <td>
                                            {_.map(this.state.campaign.blacklist_publishers, (publisher, key) =>
                                                <span key={key}>
                                                    <UserLink id={publisher.id} name={publisher.pretty_name} is_blank={true} />;&nbsp;
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Zones</td>
                                        <td>
                                            {_.map(this.state.campaign.blacklist_zones, (zone, key) =>
                                                <span key={key}>
                                                    <ZoneLink id={zone.id} name={zone.pretty_name} is_blank={true} />;&nbsp;
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Partner zones</td>
                                        <td>
                                            {_.map(this.state.campaign.blacklist_partner_zones, (partner_zone, key) =>
                                                <span key={key}>
                                                    <span className="label label-default">{partner_zone}</span>&nbsp;
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        <tr>
                            <th>Status Updates</th>
                            <td className='unpadding'>
                                <StatusChangesTable events={this.state.campaign.status_changes} />
                            </td>
                        </tr>
                        </tbody>
                    </Table>

                    <Panel>
                        <Panel.Heading className='panel-heading_top-rounded'>
                            <Panel.Title>Zones</Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>
                            <ZonesList.Table>
                                <ZonesList.SimpleHeader />
                                <ZonesList.Body zones={this.state.campaign.zones} />
                            </ZonesList.Table>
                        </Panel.Body>
                    </Panel>
                </Panel.Body>
            </Panel>
        );
    }
}
