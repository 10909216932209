/**
 * Структура, содержащая информацию о пользователе
 */
import Manager from "../../Structs/Manager";
import Campaign from "./Campaign";
import Zone from "./Zone";
import _ from 'lodash';
import Company from "./Company";
import Domain from './Domain';

export default class User {
    id                = 0;
    name              = '';
    pretty_name       = '';
    email             = '';
    balance           = 0;
    role              = '';
    back_url          = '';
    repostback_url    = '';
    manager_id        = null;
    traffic_domain_id = null;
    manager           = null;
    company           = null;
    campaigns         = [];
    zones             = [];
    created_at        = null;

    constructor(data) {
        this.id                = data.id;
        this.name              = data.name;
        this.pretty_name       = data.pretty_name;
        this.email             = data.email;
        this.balance           = data.balance;
        this.role              = data.role;
        this.back_url          = data.back_url || '';
        this.repostback_url    = data.repostback_url || '';
        this.manager_id        = data.manager_id || null;
        this.traffic_domain_id = data.traffic_domain_id || null;
        this.company           = data.company ? new Company(data.company) : null;
        this.traffic_domain    = data.traffic_domain ? new Domain(data.traffic_domain) : null;
        this.manager           = data.manager ? new Manager(data.manager) : null;
        this.campaigns         = data.campaigns ? _.map(data.campaigns, (campaign) => new Campaign(campaign)) : [];
        this.zones             = data.zones ? _.map(data.zones, (zone) => new Zone(zone)) : [];
        this.created_at        = new Date(data.created_at);
    }
}
