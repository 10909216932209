import axios from "axios/index";

/**
 * Компонент для получения списка timezone в системе
 */
export default class TimezoneAPI {

    /**
     * Получение списка таймзон
     */
    static list() {
        return axios.get(process.env.REACT_APP_API_URL + '/dictionary/timezones')
            .then(({data}) => data)
    }
}
