import React, {Component} from 'react';

export default class NoDomainsError extends Component {

    render() {
        return (
            <div className="text-center">
                <h2>You don't have any domains.</h2>
            </div>
        );
    }
}

NoDomainsError.propTypes = {
};

