/**
 * Структура, содержащая информацию о менеджере
 */
export default class Manager {

    id          = 0;
    name        = '';
    pretty_name = '';
    email       = '';

    constructor(data) {
        this.id          = data.id;
        this.name        = data.name;
        this.pretty_name = data.pretty_name;
        this.email       = data.email;
    }
}
