import User from "./User";

export default class InvoiceResource {

    id         = 0;
    user       = null;
    creator    = null;
    amount     = 0;
    comment    = '';
    /** @type {Date} */
    date       = null;
    /** @type {Date} */
    created_at = null;

    constructor(data) {
        this.id         = data.id;
        this.user       = new User(data.user);
        this.creator    = new User(data.creator);
        this.amount     = data.amount;
        this.comment    = data.comment;
        this.date       = new Date(data.date);
        this.created_at = new Date(data.created_at);
    }
}
