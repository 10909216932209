/**
 * Структура, содержащая информацию о преленде
 */
import Company from "./Company";
import PrelandingStatuses from "../../Enums/PrelandingStatuses";

export default class Prelanding {

    id          = 0;
    name        = '';
    pretty_name = '';
    file_name   = '';
    domain      = '';
    subdomain   = '';
    status      = PrelandingStatuses.Active;
    company     = null;
    created_at  = '';
    updated_at  = '';

    constructor(data) {
        this.id          = data.id;
        this.name        = data.name;
        this.pretty_name = data.pretty_name;
        this.file_name   = data.file_name;
        this.domain      = data.domain;
        this.subdomain   = data.subdomain;
        this.status      = data.status;
        this.company     = data.company ? new Company(data.company): null;
        this.created_at  = data.created_at;
        this.updated_at  = data.updated_at;
    }
}
