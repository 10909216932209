import axios from "axios/index";
import User from "./Structs/User";

/**
 * Компонент для получения данных о пользователе в системе
 */
export default class CurrentUserAPI {

    /**
     * Текущий авторизованный пользователь
     *
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static current(successCallback, errorCallback) {
        axios.get(process.env.REACT_APP_API_URL + '/user')
            .then(({data}) => successCallback(User.make(data)))
            .catch(errorCallback);
    }
}
