import React, {Component} from 'react';
import PropTypes from "prop-types";
import StatsData from "../../../../api-data/Publisher/Structs/StatsData";
import ColorNumber from "../../../../components/ColorNumber";
import Number from "../../../../components/Formatting/Number";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import StatisticsColumns from "../../../../api-data/Enums/StatisticsColumns";

/**
 * Низ таблицы
 */
export default class Footer extends Component {

    render () {
        const total = this.props.total;
console.log(this.props);
        return (
            <tr>
                <th colSpan={2} />
                <th>{Number(total.impressions)}</th>
                <th>{Number(total.conversions)}</th>
                <th>{Number(total.cr, 4)}%</th>
                <th>
                    <ColorNumber value={total.paid} precision={2} postfix='$'/>
                </th>
                <th>{Number(total.ecpm, 4)}$</th>
                <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.BotImpressions}>{Number(total.bot_impressions)}</Tooltip>}>
                    <th>{Number(total.bot_impressions_percent)}%</th>
                </OverlayTrigger>
            </tr>
        );
    }
}

Footer.propTypes = {
    total: PropTypes.instanceOf(StatsData).isRequired,
};
