import axios from "axios/index";

/**
 * Компонент для работы с авторизацией
 */
export default class AuthAPI {

    /**
     * @param {Function} successCallback
     * @param {Function} errorCallback
     * @returns {Promise<AxiosResponse<any>>}
     */
    static logout(successCallback, errorCallback) {
        return axios.post(process.env.REACT_APP_API_URL + '/auth/logout')
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * @param {Object} data
     * @param {Function} successCallback
     * @param {Function} errorCallback
     * @returns {Promise<AxiosResponse<any>>}
     */
    static login(data, successCallback, errorCallback) {
        return axios.post(process.env.REACT_APP_API_URL + '/auth/login', data)
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * @param {Object} data
     * @param {Function} successCallback
     * @param {Function} errorCallback
     * @returns {Promise<AxiosResponse<any>>}
     */
    static registration(data, successCallback, errorCallback) {
        return axios.post(process.env.REACT_APP_API_URL + '/auth/register', data)
            .then(successCallback)
            .catch(errorCallback);
    }
}
