import _ from "lodash";
import Campaign from "./Campaign";

/**
 * Структура, содержащая информацию о пользователе
 */

export default class CampaignGroup {
    id          = 0;
    name        = '';
    pretty_name = '';
    campaigns   = [];
    created_at  = '';
    updated_at  = '';

    constructor(data) {
        this.id          = data.id;
        this.name        = data.name;
        this.pretty_name = data.pretty_name;
        this.campaigns   = data.campaigns ? _.map(data.campaigns, (campaign) => new Campaign(campaign)) : [];
        this.created_at  = data.created_at;
        this.updated_at  = data.updated_at;
    }
}
