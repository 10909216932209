import React, {Component} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import _ from 'lodash';
import ZoneLink from "../../../components/ObjectLinks/ZoneLink";
import CampaignLink from "../../../components/ObjectLinks/CampaignLink";
import S2SRequest from "../../../api-data/Manager/Structs/S2SRequest";
import CopyToClipboard from "../../../components/CopyToClipboard";
import HowOld from "../../../components/HowOld";

/**
 * Одна страка с информацией о s2s request
 */
class S2SRequestRaw extends Component {

    render() {
        const s2s      = this.props.s2s;
        // Если это поле заполнено, значит тест удался
        const color    = s2s.received_at ? 'success': 'warning';
        const received = s2s.received_at ? <HowOld date={s2s.received_at} />: 'waiting';

        return (
            <tr className={'s2s-requests-table__body ' + color}>
                <td className='s2s-requests-table__body_id'>{s2s.id}</td>
                <td className='s2s-requests-table__body_zone cut-off'>
                    <ZoneLink id={s2s.zone.id} name={s2s.zone.pretty_name} is_blank={true}/>
                </td>
                <td className='s2s-requests-table__body_campaign cut-off'>
                    <CampaignLink id={s2s.campaign.id} name={s2s.campaign.pretty_name} is_blank={true}/>
                </td>
                <td className='s2s-requests-table__body_link unpadding'>
                    <CopyToClipboard value={s2s.link} />
                </td>
                <td className='s2s-requests-table__body_received_at'>
                    {received}
                </td>
            </tr>
        );
    }
}

S2SRequestRaw.propTypes = {
    s2s: PropTypes.instanceOf(S2SRequest).isRequired,
};

/**
 * Таблица списка зон
 */
export default class S2SRequestsListTable extends Component {

    render() {
        return (
            <Table bordered condensed hover className='s2s-requests-table'>
                <thead>
                <tr className='s2s-requests-table__head'>
                    <td className='s2s-requests-table__head_id'>ID</td>
                    <td className='s2s-requests-table__head_zone'>Zone</td>
                    <td className='s2s-requests-table__head_campaign'>Campaign</td>
                    <td className='s2s-requests-table__head_link'>Link</td>
                    <td className='s2s-requests-table__head_received_at'>Received At</td>
                </tr>
                </thead>

                <tbody>
                {_.map(this.props.list, (s2s) => <S2SRequestRaw s2s={s2s} key={s2s.id} />)}
                </tbody>
            </Table>
        );
    }
}

S2SRequestsListTable.propTypes = {
    list: PropTypes.arrayOf(PropTypes.instanceOf(S2SRequest)).isRequired
};

