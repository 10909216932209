import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Panel, Table} from 'react-bootstrap';
import ZoneAPI from "../../../api-data/Publisher/ZoneAPI";
import Loading from "../../../components/Loading";
import PropTypes from "prop-types";
import ColorNumber from "../../../components/ColorNumber";
import StatisticsGroupBy from "../../../api-data/Enums/StatisticsGroupBy";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import CopyToClipboard from "../../../components/CopyToClipboard";

/**
 * Компонент отображает полную информацию о зоне
 */
export default class Show extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isReady: false,
            zone: null,
        };
    }

    /**
     * При инициализации компонента, загружаем данные о зоне
     */
    componentWillMount() {
        ZoneAPI.getById(this.props.id, (zone) => this.setState({zone: zone, isReady: true}), DangerEvent.onSystemError);
    }

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <NavLink to='/zones' className='btn btn-default panel-heading__button panel-heading__button_left'>
                        <span className='glyphicon glyphicon-chevron-left'/>
                        <span className='glyphicon glyphicon-chevron-left'/>
                    </NavLink>
                    <div className='panel-heading__button-group panel-heading__button-group_right btn-group'>
                        <NavLink to={`/statistics?${StatisticsGroupBy.zone}[]=${this.props.id}`} className='btn btn-info'>
                            &nbsp;<span className='glyphicon glyphicon-stats'/>&nbsp;
                        </NavLink>
                    </div>
                </Panel.Heading>

                <Panel.Body>
                    <Table bordered>
                        <tbody>
                        <tr>
                            <th>ID</th>
                            <td>{this.state.zone.id}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{this.state.zone.name}</td>
                        </tr>
                        <tr>
                            <th>Type</th>
                            <td>{this.state.zone.type}</td>
                        </tr>
                        <tr>
                            <th>Direct link</th>
                            <td className='unpadding'>
                                <CopyToClipboard value={this.state.zone.direct_link} />
                            </td>
                        </tr>
                        {this.state.zone.inherit_repostback_url === false && this.state.zone.repostback_url !== '' &&
                        <tr>
                            <th>Repostback URL</th>
                            <td className='unpadding'>
                                <CopyToClipboard value={this.state.zone.repostback_url} />
                            </td>
                        </tr>
                        }
                        {this.state.zone.inherit_repostback_url === true && this.state.zone.user.repostback_url !== '' &&
                        <tr>
                            <th>Repostback URL</th>
                            <td className='unpadding'>
                                <CopyToClipboard value={this.state.zone.user.repostback_url} />
                            </td>
                        </tr>
                        }
                        {this.state.zone.inherit_back_url === false && this.state.zone.back_url !== '' &&
                        <tr>
                            <th>Back traffic URL</th>
                            <td className='unpadding'>
                                <CopyToClipboard value={this.state.zone.back_url} />
                            </td>
                        </tr>
                        }
                        {this.state.zone.inherit_back_url === true && this.state.zone.user.back_url !== '' &&
                        <tr>
                            <th>Back traffic URL</th>
                            <td className='unpadding'>
                                <CopyToClipboard value={this.state.zone.user.back_url} />
                            </td>
                        </tr>
                        }
                        <tr>
                            <th>Money</th>
                            <td>
                                <Table bordered striped>
                                    <thead>
                                    <tr>
                                        <td className='text-right'>Today profit $</td>
                                        <td className='text-right'>Total profit $</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className='text-right'>
                                            <ColorNumber value={this.state.zone.money.today_profit} precision={2} postfix='$' />
                                        </td>
                                        <td className='text-right'>
                                            <ColorNumber value={this.state.zone.money.total_profit} precision={2} postfix='$' />
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </Panel.Body>
            </Panel>
        );
    }
}

Show.propTypes = {
    id: PropTypes.number.isRequired
};
