import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import CreateForm from './Form';
import PropTypes from "prop-types";
import User from "../../../api-data/Manager/Structs/User";
import SuccessEvent from "../../../components/Alerts/SuccessEvent";

/**
 * Компонент создания новой кампании
 */
export default class Create extends Component {

    state = {
        newCampaignAfterSubmit: false
    };

    handleSubmit = ({data}) => {
        SuccessEvent.created();

        if (this.state.newCampaignAfterSubmit) {
            this.props.history.push('/temporary');
            this.props.history.goBack();
        } else {
            this.props.history.push(`/campaigns/${data.id}`);
        }
    };

    render() {
        return (
            <Panel>
                <Panel.Body className='manager-campaign-settings-panel'>
                    <CreateForm user={this.props.authUser} handleSubmit={this.handleSubmit} />
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <NavLink to='/campaigns' className='btn btn-default panel-footer__button panel-footer__button_left'>
                        &nbsp;<span className='glyphicon glyphicon-remove' />&nbsp;
                    </NavLink>
                    <div className='panel-footer__button-group panel-footer__button-group_right btn-group'>
                        <button className='btn btn-primary' type='submit' form='campaign-form'>
                            &nbsp;<span className='glyphicon glyphicon-ok' />&nbsp;
                        </button>
                        <button className='btn btn-success' type='submit' form='campaign-form' onClick={() => {
                            this.setState({newCampaignAfterSubmit: true});
                        }}>
                            <span className='glyphicon glyphicon-chevron-right'/>
                            <span className='glyphicon glyphicon-chevron-right'/>
                        </button>
                    </div>
                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}

Create.propTypes = {
    authUser: PropTypes.instanceOf(User).isRequired,
};
