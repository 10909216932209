/**
 * Структура, содержащая информацию о положении финансов у кампании
 */
export default class CampaignMoney {

    constructor(data) {
        data = data || {};

        this._daily_limit        = data.daily_limit || 0;
        this._total_limit        = data.total_limit || 0;
        this._is_unlimited_daily = data.is_unlimited_daily || false;
        this._is_unlimited_total = data.is_unlimited_total || false;
        this._daily_limit_spent  = data.daily_limit_spent || 0;
        this._total_limit_spent  = data.total_limit_spent || 0;
    }

    get is_unlimited_daily() {
        return this._is_unlimited_daily;
    }

    get is_unlimited_total() {
        return this._is_unlimited_total;
    }

    get daily_limit() {
        return this._daily_limit;
    }

    get total_limit() {
        return this._total_limit;
    }

    get daily_limit_spent() {
        return this._daily_limit_spent;
    }

    get total_limit_spent() {
        return this._total_limit_spent;
    }
}
