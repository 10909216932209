import axios from "axios";
import _ from "lodash";
import Event from "./Structs/Event";

/**
 * Компонент для получения данных о событиях
 */
export default class EventsAPI {

    /**
     * API URL для работы с events
     *
     * @return {string}
     */
    static get link() {
        return process.env.REACT_APP_API_URL + '/manager/events';
    };

    /**
     * Возвращает список events
     *
     * @param limit - Максимальное кол-во событий
     * @param date  - Начальная дата, с которой стоит брать события
     * @param {Function} successCallback
     * @param {Function} errorCallback
     * @return {Promise}
     */
    static list(limit, date, successCallback, errorCallback) {
        return axios.get(EventsAPI.link, {params: {limit: limit, date: date}})
            .then(({data}) => _.map(data, (event) => new Event(event)))
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     *
     * @param id
     * @param successCallback
     * @param errorCallback
     * @returns {Promise}
     */
    static viewedBefore(id, successCallback, errorCallback) {
        return axios.put(EventsAPI.link + '/viewed-before', {id: id})
            .then(successCallback)
            .catch(errorCallback);
    }
}
