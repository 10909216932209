import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

/**
 * Кнопка клонирования
 */
export default class CloneButton extends Component {

    render() {
        return (
            <NavLink to={this.props.route} className='btn btn-success'>
                <span className='glyphicon glyphicon-copy'/>
            </NavLink>
        );
    }
}

CloneButton.propTypes = {
    route: PropTypes.string.isRequired,
};
