import React, {Component} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import _ from 'lodash';
import PostbacksReport from "../../../api-data/Manager/Structs/PostbacksReport";
import PostbacksReportStatus from "../../../api-data/Enums/PostbacksReportStatus";
import PostbacksReportAPI from "../../../api-data/Manager/PostbacksReportAPI";

/**
 * Тело таблицы
 */
class ReportRow extends Component {

    render () {
        const report   = this.props.report;
        const isActive = PostbacksReportStatus.isReady(report);

        return (
            <tr className={'postbacks-report-table_row-' + report.status.toLocaleLowerCase()}>
                <td className='postbacks-report-table_body_name'>#{this.props.index}&nbsp;{report.name}</td>
                <td className='postbacks-report-table_body_download'>
                    <a href={PostbacksReportAPI.link + '/' + this.props.report.id} target='_blank' rel="noopener noreferrer" className={'btn btn-xs btn-success ' + (isActive ? '': 'disabled')}>
                        Download&nbsp;<span className='glyphicon glyphicon-save' />
                    </a>
                </td>
            </tr>
        );
    }
}

ReportRow.propTypes = {
    report: PropTypes.instanceOf(PostbacksReport).isRequired,
    index: PropTypes.number.isRequired,
};

/**
 * Список отчетов по постбекам
 */
export default class ReportsTable extends Component {
    render() {
        const count = this.props.reports.length;
        const rows  = _.map(this.props.reports, (report, key) => <ReportRow report={report} index={count - key} key={key} />);

        return (
            <Table striped className='postbacks-report-table'>
                <tbody>{rows}</tbody>
            </Table>
        );
    }
}

ReportsTable.propTypes = {
    reports: PropTypes.arrayOf(PropTypes.instanceOf(PostbacksReport)).isRequired,
};

