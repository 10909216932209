import React, {Component} from 'react';
import {Table} from 'react-bootstrap';
import PropTypes from "prop-types";
import _ from 'lodash';
import ShowButton from "../../../buttons/ShowButton";
import StatisticsButton from "../../../buttons/StatisticsButton";
import Zone from "../../../api-data/Publisher/Structs/Zone";
import ZoneLink from "../../../components/ObjectLinks/ZoneLink";
import ColorNumber from "../../../components/ColorNumber";
import '../../../styles/css/zones/zone-list.css';
import StatisticsGroupBy from "../../../api-data/Enums/StatisticsGroupBy";

/**
 * Одна страка с информацией о зоне для таблицы спика зон
 */
class ZoneRow extends Component {

    render() {
        const zone = this.props.zone;

        return (
            <tr className='publisher-zone-table__body'>
                <td className='publisher-zone-table__body_id'>{zone.id}</td>
                <td>
                    <ZoneLink id={zone.id} name={zone.name}/>
                </td>
                <td className='publisher-zone-table__body_today-profit'>
                    <ColorNumber value={zone.money.today_profit} precision={2} postfix='$' />
                </td>
                <td className='publisher-zone-table__body_total-profit'>
                    <ColorNumber value={zone.money.total_profit} precision={2} postfix='$' />
                </td>
                <td>
                    <div className='btn-group btn-group-xs pull-right'>
                        <ShowButton route={'/zones/' + zone.id}/>
                        <StatisticsButton route={`/statistics?${StatisticsGroupBy.zone}[]=${zone.id}`}/>
                    </div>
                </td>
            </tr>
        );
    }
}

ZoneRow.propTypes = {
    zone: PropTypes.instanceOf(Zone).isRequired,
};

/**
 * Возвращает список рядов таблицы
 *
 * @param list
 * @return {Array}
 * @constructor
 */
class ZonesList extends Component {

    render() {
        return _.map(this.props.list, (zone) => <ZoneRow zone={zone} key={zone.id} />);
    }
}

ZonesList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.instanceOf(Zone)).isRequired,
};

/**
 * Таблица списка зон
 */
export default class ZonesListTable extends Component {

    render() {
        return (
            <Table bordered condensed hover className='publisher-zone-table'>
                <thead>
                <tr className='publisher-zone-table__head'>
                    <td className='publisher-zone-table__head_id'>ID</td>
                    <td className='publisher-zone-table__head_name'>Name</td>
                    <td className='publisher-zone-table__head_today-profit'>Today profit $</td>
                    <td className='publisher-zone-table__head_total-profit'>Total profit $</td>
                    <td className='publisher-zone-table__head_actions'>Actions</td>
                </tr>
                </thead>

                <tbody>
                <ZonesList list={this.props.list} />
                </tbody>
            </Table>
        );
    }
}

ZonesListTable.propTypes = {
    list: PropTypes.array.isRequired
};

