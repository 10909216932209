import qs from "query-string";
import _ from 'lodash';

/**
 * Работа с историей браузера
 */
export default class History {

    /**
     * @param history
     * @param params
     */
    static update (history, params) {
        params = _.transform(params, (result, value, key) => {
            if (value) result[key] = value;
        });

        history.push({search: qs.stringify(params, {arrayFormat: 'bracket'})});
    };
}
