import _ from 'lodash';
import StatsData from "./StatsData";

/**
 * Класс содержащий информацию от запроса к API компонента StatisticsAPI
 */
export default class StatisticsAPIResponse {

    group_by     = '';
    current_page = 0;
    last_page    = 0;
    per_page     = 0;
    from         = 0;
    to           = 0;
    total        = 0;
    data         = [];
    total_data   = null;

    constructor(response) {
        this.group_by     = response.data.group_by;
        this.current_page = response.data.current_page;
        this.last_page    = response.data.last_page;
        this.per_page     = response.data.per_page;
        this.from         = response.data.from;
        this.to           = response.data.to;
        this.total        = response.data.total;
        this.data         = _.map(response.data.data, (data) => new StatsData(data));
        this.total_data   = new StatsData(response.data.total_data);
    }
}
