import axios from "axios/index";

/**
 * Компонент для получения данных о кампании/ях в системе
 */
export default class FileAPI {

    /**
     * API URL для работы с кампаниями
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/file';
    };

    /**
     * Загрузка файла CSV с гео рейтами
     *
     * @param data
     * @param successCallback
     * @param errorCallback
     */
    static uploadCSVGeoRatesFile(data, successCallback, errorCallback) {
        axios.post(FileAPI.link + '/parse-csv-geo-rates', data)
            .then(successCallback)
            .catch(errorCallback);
    }
}
