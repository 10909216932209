import React from 'react';
import PropTypes from "prop-types";
import User from '../../../../api-data/Manager/Structs/User';
import BaseForm from '../../../../components/BaseForm';
import UserAPI from '../../../../api-data/Manager/UserAPI';

export default class Form extends BaseForm {

    state = {
        submit: {
            comment: ''
        }
    };

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        UserAPI.storeComment(this.props.user.id, this.state.submit)
            .then(this.props.onSubmit)
            .then(() => this.updateSubmitState('comment', ''));
    };

    render() {
        return (
            <form className='user-comment-form' id='user-comment-form' onSubmit={this.handleSubmit} autoComplete="off">
                <div className="input-group" id="comment-block">
                    <textarea name="comment" value={this.state.submit.comment} className="form-control" placeholder="Comment" onChange={this.handleChange} style={{resize: 'none'}} required/>
                    <span className="input-group-btn">
                        <button className="btn btn-success" type="submit">
                            &nbsp;<span className='glyphicon glyphicon-ok' />&nbsp;
                        </button>
                    </span>
                    <small className="text-danger"/>
                </div>
            </form>
        );
    }
}

Form.propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    onSubmit: PropTypes.func.isRequired,
};
