import Domain from './Domain';

export default class Company {
    id               = 0;
    name             = '';
    trafficDomain    = null;
    postbackDomain   = null;
    prelandingDomain = null;

    constructor(data) {
        this.id               = data.id;
        this.name             = data.name;
        this.trafficDomain    = new Domain(data.trafficDomain);
        this.postbackDomain   = new Domain(data.postbackDomain);
        this.prelandingDomain = new Domain(data.prelandingDomain);
    }
}
