import React, {Component} from 'react';
import PropTypes from "prop-types";

/**
 * Отрисовывает элемент checkbox
 */
export default class Checkbox extends Component {

    render() {
        const key = Math.random();

        return (
            <label htmlFor={key} className="checkbox-block">
                <input id={key} name={this.props.name} value={this.props.value} checked={this.props.isChecked} onChange={this.props.onChange} type="checkbox" className="checkbox-block__input_invisible" />
                <div className="checkbox">
                    <svg viewBox="0 0 20 20">
                        <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z" />
                        <polyline points="4 11 8 15 16 6" />
                    </svg>
                </div>
                <div className='description' hidden={!this.props.children}>{this.props.children}</div>
            </label>
        );
    }
}

Checkbox.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
};
