import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

/**
 * Ссылка на просмотр информации о зоне
 *
 * @param id
 * @param name
 * @param is_blank
 * @returns {string|*}
 * @constructor
 */
export default function ZoneLink({id, name, is_blank}) {
    if (id === undefined || name === undefined) {
        return '';
    }

    if (is_blank === true) {
        return <a href={'/zones/' + id} rel="noopener noreferrer" target='_blank'>{name}</a>;
    }

    return <NavLink to={'/zones/' + id}>{name}</NavLink>;
}

ZoneLink.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    is_blank: PropTypes.bool,
};
