import _ from "lodash";

/**
 * Масив объектов для компонента Select
 */
export default class SelectOptions {

    /**
     * Возможные варианты опций для выбора
     *
     * @type {Array}
     */
    options = [];

    /**
     * @param options
     */
    constructor(options) {
        this.options = options || [];
    }

    /**
     * Создает объект SelectOptions по списку, из которого выбирает Id для value и pretty_name для label
     *
     * @param list
     * @param {String} key
     * @param {String} value
     * @returns {SelectOptions}
     */
    static createBy = (list, key, value) => {
        const options = _.map(list, (element) => ({value: element[key], label: element[value]}));

        return new SelectOptions(options);
    };

    /**
     * Создает объект SelectOptions по списку, из которого выбирает Id для value и pretty_name для label
     *
     * @param list
     * @returns {SelectOptions}
     */
    static createByIdPrettyName = (list) => {
        return SelectOptions.createBy(list, 'id', 'pretty_name');
    };

    /**
     * Создает объект SelectOptions по коллекции, из которого выбирает value и label
     *
     * @param list
     * @returns {SelectOptions}
     */
    static createByCollection = (list) => {
        return SelectOptions.createBy(list, 'value', 'label');
    };

    /**
     * Создает объект SelectOptions по словарю, из которого выбирает key для value и value для label
     *
     * @param list
     * @returns {SelectOptions}
     */
    static createFromDictionary = (list) => {
        const options = _.map(list, (label, value) => ({value: value, label: label}));

        return new SelectOptions(options);
    };

    /**
     * Возвращает 1 элемент опций по параметру value
     *
     * @param value
     * @returns {*}
     */
    optionByValue = (value) => _.keyBy(this.options, 'value')[value] || null;

    /**
     * Возвращает N элементов опций по параметрам values
     *
     * @param values
     * @returns {*}
     */
    optionsByValues = (values) => {
        values = _.map(values, (value) => value.toString());

        return _.filter(this.options, (value) => _.indexOf(values, value.value.toString()) !== -1);
    };

    /**
     * Возвращает список опций
     *
     * @returns {Array}
     */
    list = () => this.options;

    /**
     * Возвращает кол-во опций
     *
     * @returns {number}
     */
    length = () => this.options.length;

    /**
     * Добавляет новую оцию в конец списка
     *
     * @param option
     * @returns {number}
     */
    push = (option) => this.options.push(option);

    /**
     * Добавляет новую оцию в начало списка
     *
     * @param option
     * @returns {number}
     */
    unshift = (option) => this.options.unshift(option);

    /**
     *
     * @param value
     * @returns {Array}
     */
    drop = (value) => {
        this.options = _.remove(this.options, (option) => option.value !== value);
    };

    /**
     * Возвращает первый элемент из списка опций
     *
     * @returns {*}
     */
    first = () => _.first(_.values(this.options));
}
