import React, {Component} from 'react';
import PropTypes from "prop-types";
import _ from "lodash";
import ColorNumber from "../../../../components/ColorNumber";
import StatsData from "../../../../api-data/Manager/Structs/StatsData";
import StatisticsGroupBy from "../../../../api-data/Enums/StatisticsGroupBy";
import Number from "../../../../components/Formatting/Number";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Checkbox from "../../../../components/Checkbox";
import StatisticsColumns from "../../../../api-data/Enums/StatisticsColumns";

/**
 * Список рядов таблицы статистки
 */
export default class Body extends Component {
    render () {
        return _.map(this.props.statistics, (statistics, key) => {
            let groupBy = statistics.group_by;

            return (
                <tr className={this.props.isSelectedRow(groupBy) ? 'selected': ''} key={key}>
                    <td className='check-column'>
                        <Checkbox onChange={() => this.props.updateSelection(groupBy)} isChecked={this.props.isSelectedRow(groupBy)} />
                    </td>
                    <td className='group_by-column'>{groupBy}</td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.TotalImpressions)}>{Number(statistics.total_impressions)}</td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.Impressions)}>{Number(statistics.impressions)}</td>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.UnshownImpressions}>{Number(statistics.unshown_impressions)}</Tooltip>}>
                        <td hidden={this.props.isHiddenColumn(StatisticsColumns.UnshownImpressions)}>{Number(statistics.unshown_impressions_percent)}%</td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.BackbuttonImpressions}>{Number(statistics.backbutton_impressions)}</Tooltip>}>
                        <td hidden={this.props.isHiddenColumn(StatisticsColumns.BackbuttonImpressions)}>{Number(statistics.backbutton_impressions_percent)}%</td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.UnshownBackbuttonImpressions}>{Number(statistics.unshown_backbutton_impressions)}</Tooltip>}>
                        <td hidden={this.props.isHiddenColumn(StatisticsColumns.UnshownBackbuttonImpressions)}>{Number(statistics.unshown_backbutton_impressions_percent)}%</td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.BackurlImpressions}>{Number(statistics.backurl_impressions)}</Tooltip>}>
                        <td hidden={this.props.isHiddenColumn(StatisticsColumns.BackurlImpressions)}>{Number(statistics.backurl_impressions_percent)}%</td>
                    </OverlayTrigger>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.Conversions)}>{Number(statistics.conversions)}</td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.CR)}>{Number(statistics.cr, 4)}%</td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.BackbuttonConversions)}>{Number(statistics.backbutton_conversions)}</td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.BBCR)}>{Number(statistics.bb_cr, 4)}%</td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.Revenue)}>{Number(statistics.revenue, 2)}$</td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.Paid)}>{Number(statistics.paid, 2)}$</td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.Profit)}>
                        <ColorNumber value={statistics.profit} precision={2} postfix='$'/>
                    </td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.ECPM)}>{Number(statistics.ecpm, 4)}$</td>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.ECPMPub)}>{Number(statistics.ecpm_pub, 4)}$</td>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.BotImpressions}>{Number(statistics.bot_impressions)}</Tooltip>}>
                        <td hidden={this.props.isHiddenColumn(StatisticsColumns.BotImpressions)}>{Number(statistics.bot_impressions_percent)}%</td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.HostingImpressions}>{Number(statistics.hosting_impressions)}</Tooltip>}>
                        <td hidden={this.props.isHiddenColumn(StatisticsColumns.HostingImpressions)}>{Number(statistics.hosting_impressions_percent)}%</td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.IframeImpressions}>{Number(statistics.iframe_impressions)}</Tooltip>}>
                        <td hidden={this.props.isHiddenColumn(StatisticsColumns.IframeImpressions)}>{Number(statistics.iframe_impressions_percent)}%</td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.ProxyImpressions}>{Number(statistics.proxy_impressions)}</Tooltip>}>
                        <td hidden={this.props.isHiddenColumn(StatisticsColumns.ProxyImpressions)}>{Number(statistics.proxy_impressions_percent)}%</td>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={StatisticsColumns.InappImpressions}>{Number(statistics.inapp_impressions)}</Tooltip>}>
                        <td hidden={this.props.isHiddenColumn(StatisticsColumns.InappImpressions)}>{Number(statistics.inapp_impressions_percent)}%</td>
                    </OverlayTrigger>
                    <td hidden={this.props.isHiddenColumn(StatisticsColumns.RevShare)}>{Number(statistics.rev_share)}%</td>
                </tr>
            )
        });
    }
}

Body.propTypes = {
    group_by: PropTypes.oneOf(StatisticsGroupBy.list).isRequired,
    isHiddenColumn: PropTypes.func.isRequired,
    isSelectedRow: PropTypes.func.isRequired,
    statistics: PropTypes.arrayOf(PropTypes.instanceOf(StatsData)).isRequired,
};
