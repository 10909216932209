import React, {Component} from 'react';
import {Navbar} from 'react-bootstrap';
import {NavLink, Route, Switch} from 'react-router-dom';
import Logout from "../Auth/Logout";
import Zones from "./Zones";
import CreateZone from "./Zones/Create";
import EditZone from "./Zones/Edit";
import ShowZone from "./Zones/Show";
import Campaigns from "./Campaigns";
import CreateCampaign from "./Campaigns/Create";
import EditCampaign from "./Campaigns/Edit";
import CloneCampaign from "./Campaigns/Clone";
import ShowCampaign from "./Campaigns/Show";
import Users from "./Users";
import ShowUser from "./Users/Show";
import CreateUser from "./Users/Create";
import EditUser from "./Users/Edit";
import Statistics from "./Statistics";
import PostbacksReports from "./PostbacksReports";
import Billing from "./Billing";
import PropTypes from "prop-types";
import User from "../../api-data/Manager/Structs/User";
import Prelandings from "./Prelandings";
import S2SRequests from "./S2SRequests";
import NavEvents from "../../components/NavEvents";
import PrivateManagerChannel from "../../components/Socket/PrivateManagerChannel";
import Corrections from './Corrections';

/**
 * Индексная страница кабинета манагеров
 */
export default class Managers extends Component {

    /**
     * @type {object}
     */
    navRef = null;

    /**
     * Регистрация слушателей каналов socket.io
     */
    componentDidMount() {
        new PrivateManagerChannel(this.props.authUser).listen(this.navRef.addEvent);
    }

    render() {
        return (
            <div className='content-block manager-content-block'>
                <Navbar fixedTop className='bg-primary'>
                    <Navbar.Header>
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>
                        <Navbar.Brand>
                            <NavLink to='/'>
                                <img src={'/images/logo.png'} alt='Checket.net brand ico' />
                            </NavLink>
                        </Navbar.Brand>
                    </Navbar.Header>
                    <div className="collapse navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li><NavLink to='/statistics'>Statistics</NavLink></li>
                            <li><NavLink to='/corrections'>Corrections</NavLink></li>
                            <li><NavLink to='/postbacks'>Postbacks</NavLink></li>
                            <li><NavLink to='/zones'>Zones</NavLink></li>
                            <li><NavLink to='/campaigns'>Campaigns</NavLink></li>
                            <li><NavLink to='/s2s-requests'>S2S</NavLink></li>
                            <li><NavLink to='/users'>Users</NavLink></li>
                            <li><NavLink to='/billing'>Billing</NavLink></li>
                            {/*<li><NavLink to='/prelandings'>Prelandings</NavLink></li>*/}
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <NavLink to='/logout'>Logout</NavLink>
                            </li>
                        </ul>
                        <NavEvents ref={ref => this.navRef = ref} />
                    </div>
                </Navbar>

                <Switch>
                    <Route path="/statistics" render={(props) =>
                        <div className='container-fluid content'>
                            <Statistics {...props}/>
                        </div>
                    }/>
                    <Route path="/corrections" render={(props) =>
                        <div className='container-fluid content'>
                            <Corrections {...props}/>
                        </div>
                    }/>
                    <Route path="/postbacks" render={(props) =>
                        <div className='container content'>
                            <PostbacksReports {...props}/>
                        </div>
                    }/>
                    <Route path="/campaigns" render={() =>
                        <div className='container-fluid content'>
                            <Switch>
                                <Route exact path='/campaigns' render={(props) => {
                                    return <Campaigns {...props}/>;
                                }}/>
                                <Route path={'/campaigns/create'} render={(props) => {
                                    return <CreateCampaign {...props} authUser={this.props.authUser} />;
                                }}/>
                                <Route path={'/campaigns/:id/edit'} render={(props) => {
                                    return <EditCampaign {...props} authUser={this.props.authUser} id={+props.match.params.id} />;
                                }}/>
                                <Route path={'/campaigns/:id/clone'} render={(props) => {
                                    return <CloneCampaign {...props} authUser={this.props.authUser} id={+props.match.params.id} />;
                                }}/>
                                <Route exact path={'/campaigns/:id'} render={(props) => {
                                    return <ShowCampaign {...props} id={+props.match.params.id} />;
                                }}/>
                            </Switch>
                        </div>
                    }/>
                    <Route path="/zones" render={() =>
                        <div className='container content'>
                            <Switch>
                                <Route exact path='/zones' render={(props) => {
                                    return <Zones {...props}/>;
                                }}/>
                                <Route path={'/zones/create'} render={(props) => {
                                    return <CreateZone {...props} />;
                                }}/>
                                <Route path={'/zones/:id/edit'} render={(props) => {
                                    return <EditZone {...props} id={+props.match.params.id} />;
                                }}/>
                                <Route exact path={'/zones/:id'} render={(props) => {
                                    return <ShowZone {...props} id={+props.match.params.id} />;
                                }}/>
                            </Switch>
                        </div>
                    }/>
                    <Route path="/s2s-requests" render={(props) =>
                        <div className='container content'>
                            <S2SRequests {...props}/>
                        </div>
                    }/>
                    <Route path="/users" render={() =>
                        <div className='container content'>
                            <Switch>
                                <Route exact path='/users' render={(props) => {
                                    return <Users {...props} authUser={this.props.authUser} />;
                                }}/>
                                <Route path={'/users/create'} render={(props) => {
                                    return <CreateUser {...props} authUser={this.props.authUser} />;
                                }}/>
                                <Route exact path={'/users/:id'} render={(props) => {
                                    return <ShowUser {...props} id={+props.match.params.id} authUser={this.props.authUser} />;
                                }}/>
                                <Route path={'/users/:id/edit'} render={(props) => {
                                    return <EditUser {...props} id={+props.match.params.id} authUser={this.props.authUser} />;
                                }}/>
                            </Switch>
                        </div>
                    }/>
                    <Route path="/billing" render={(props) =>
                        <div className='container content'>
                            <Billing {...props}/>
                        </div>
                    }/>
                    <Route path="/prelandings" render={(props) =>
                        <div className='container prelanding-container content '>
                            <Prelandings {...props} authUser={this.props.authUser} />
                        </div>
                    }/>
                    <Route path="/logout" render={(props) =>
                        <div className='container content'>
                            <Logout {...props} />
                        </div>
                    }/>
                    <Route path="/temporary" render={() =>
                        <div className='container content'/>
                    }/>
                    <Route render={(props) =>
                        <div className='container-fluid content'>
                            <Statistics {...props}/>
                        </div>
                    }/>
                </Switch>

                <div className="clearfix"/>

                <footer className='footer'>
                    <div className='container'>
                        <div className='footer__line'>
                            <hr/>
                        </div>
                        <div className='footer__copyright'>
                            Checket Net OÜ, registry code: 16271528
                            Narva mnt 5, Tallinn 10117, Estonia.
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

Managers.propTypes = {
    authUser: PropTypes.instanceOf(User).isRequired
};
