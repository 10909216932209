import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Panel} from 'react-bootstrap';
import CampaignAPI from '../../../api-data/Manager/CampaignAPI';
import Loading from "../../../components/Loading";
import qs from "query-string";
import PaginationConstants from "../../../api-data/Enums/PaginationConstants";
import History from "../../../lib/History";
import Pagination from "../../../components/Pagination";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import CampaignsList from './CampaignsList';

/**
 * Индексная страница, на которой отображается список кампаний
 */
export default class Campaigns extends Component {

    constructor(props) {
        super(props);

        const query = qs.parse(this.props.location.search);

        this.state = {
            searchParams:  {
                id:                query.id || '',
                name:              query.name || '',
                user_id:           query.user_id || '',
                campaign_group_id: query.campaign_group_id || '',
                format:            query.format || '',
                price_model:       query.price_model || '',
            },
            isReady:       false,
            page:          query.page || PaginationConstants.defaultPage,
            lastPage:      PaginationConstants.defaultPage,
            campaignsList: [],
        };
    }

    /**
     * Загружаем список кампаний
     */
    componentWillMount() {
        this.loadCampaignsList();
    }

    /**
     * Загрузка списка кампаний
     */
    loadCampaignsList = () => {
        const searchParams = {...this.state.searchParams, page: this.state.page};

        History.update(this.props.history, searchParams);

        CampaignAPI.list(searchParams)
            .then((response) => {
                this.setState({
                    isReady:       true,
                    page:          response.current_page,
                    lastPage:      response.last_page,
                    campaignsList: response.campaigns,
                });
            })
            .catch(DangerEvent.onSystemError);
    };

    /**
     * Обновляем историю браузера, при смене страницы
     *
     * @param page
     */
    onChangePage = (page) => {
        this.setState({page: page}, this.loadCampaignsList);
    };

    /**
     * При смене фильтра, сбрасываем и страницу на 1-ю
     * @param searchParams
     */
    onChangeFilter = (searchParams) => {
        this.setState({page: PaginationConstants.defaultPage, searchParams: searchParams}, this.loadCampaignsList)
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <div className='pull-right'>
                        <NavLink to='/campaigns/create' className='btn btn-block btn-success panel-heading__button panel-heading__button_right'>
                            &nbsp;<span className='glyphicon glyphicon-plus'/>&nbsp;
                        </NavLink>
                    </div>
                </Panel.Heading>

                <Panel.Body className='panel-body panel-body_unbordered'>
                    <CampaignsList.Table>
                        <CampaignsList.SearchHeader defaultValues={this.state.searchParams} handleFilters={this.onChangeFilter} />
                        <CampaignsList.Body campaigns={this.state.campaignsList} />
                    </CampaignsList.Table>
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <div className='col-sm-12 panel-footer_pagination-wrapper'>
                        {this.refFilters !== null && <Pagination
                            onChangePage={(page) => this.onChangePage(page)}
                            current_page={this.state.page}
                            last_page={this.state.lastPage}
                        />}
                    </div>

                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}
