import React, {Component} from 'react';
import {Panel} from 'react-bootstrap';
import SearchInvoicesForm from './SearchInvoicesForm';
import InvoicesTable from "./InvoicesTable";
import CreateInvoiceModal from './CreateInvoiceModal';

import '../../../styles/css/billing/index.css';

export default class Billing extends Component {

    state = {
        invoices: [],
        showModal: false,
        refreshList: true,
    };

    render() {
        return (
            <div>
                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <Panel.Title>
                            <span className='glyphicon glyphicon-filter'/> Users invoices filters
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <SearchInvoicesForm
                            search={this.props.location.search}
                            history={this.props.history}
                            needSearch={this.state.refreshList}
                            updateInvoices={({invoices}) => this.setState({refreshList: false, invoices: invoices})}
                        />
                    </Panel.Body>

                    <Panel.Footer className='panel-footer_bottom-rounded'>
                        <button className='btn btn-primary panel-footer__button panel-footer__button_left' type='submit' form="invoices-form">
                            &nbsp;<span className='glyphicon glyphicon-search' />&nbsp;
                        </button>
                        <div className='clearfix'/>
                    </Panel.Footer>
                </Panel>
                <Panel>
                    <Panel.Heading className='panel-heading_top-rounded'>
                        <div className='pull-right'>
                            <button onClick={() => this.setState({showModal: true})} className='btn btn-block btn-success panel-heading__button panel-heading__button_right'>
                                &nbsp;<span className='glyphicon glyphicon-plus'/>&nbsp;
                            </button>

                            {this.state.showModal &&
                                <CreateInvoiceModal
                                    onSubmit={() => this.setState({refreshList: true, showModal: false})}
                                    onHide={() => this.setState({showModal: false})}
                                />
                            }
                        </div>
                    </Panel.Heading>

                    <Panel.Body className='panel-body panel-body_unbordered'>
                        <InvoicesTable invoices={this.state.invoices} />
                    </Panel.Body>

                    <Panel.Footer className='panel-footer_bottom-rounded'>
                    </Panel.Footer>
                </Panel>
            </div>
        );
    }
}
