import React, {Component} from 'react';
import PropTypes from "prop-types";
import {ListGroup, ListGroupItem, Modal} from "react-bootstrap";
import Checkbox from "../../../../components/Checkbox";
import StatisticsColumns from "../../../../api-data/Enums/StatisticsColumns";

export default class ColumnModal extends Component {

    /**
     * Передаем в родительский метод название колонки и новый статус
     * @param e
     */
    onChange = (e) => {
        const name = e.target.name;

        this.props.onChange(name);
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} className='manager-statistics-column-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Check enabled columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.TotalImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.TotalImpressions)}> Total Impressions</Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.Impressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.Impressions)}> Impressions </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.UnshownImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.UnshownImpressions)}> Unshown Impressions </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.BackbuttonImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.BackbuttonImpressions)}> Backbutton Impressions </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.UnshownBackbuttonImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.UnshownBackbuttonImpressions)}> Unshown Backbutton Impressions </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.BackurlImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.BackurlImpressions)}> Backurl Impressions </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.Conversions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.Conversions)}> Conversions </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.CR} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.CR)}> CR (Conversion Rate) </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.BackbuttonConversions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.BackbuttonConversions)}> Backbutton Conversions </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.BBCR} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.BBCR)}> BBCR (Backbutton Conversion Rate) </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.Revenue} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.Revenue)}> Revenue </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.Paid} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.Paid)}> Paid </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.Profit} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.Profit)}> Profit </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.ECPM} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.ECPM)}> eCPM (effective Cost Per Mile) </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.ECPMPub} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.ECPMPub)}> eCPMPub (effective Cost Per Mile Publisher) </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.BotImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.BotImpressions)}> Bots </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.HostingImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.HostingImpressions)}> Hosting </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.IframeImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.IframeImpressions)}> Iframe </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.ProxyImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.ProxyImpressions)}> Proxy </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.InappImpressions} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.InappImpressions)}> InApp </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Checkbox name={StatisticsColumns.RevShare} onChange={this.onChange} isChecked={!this.props.isHidden(StatisticsColumns.RevShare)}> ROI (Return On Investment) </Checkbox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <div className='description'>Campaign special columns</div>
                            <ListGroup>
                                <ListGroupItem>
                                    <Checkbox name='price_model' onChange={this.onChange} isChecked={!this.props.isHidden('price_model')}>Model</Checkbox>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Checkbox name='campaign_group' onChange={this.onChange} isChecked={!this.props.isHidden('campaign_group')}>Group</Checkbox>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Checkbox name='campaign_status' onChange={this.onChange} isChecked={!this.props.isHidden('campaign_status')}>Status</Checkbox>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Checkbox name='campaign_daily_limit' onChange={this.onChange} isChecked={!this.props.isHidden('campaign_daily_limit')}>Daily limit spent $</Checkbox>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Checkbox name='campaign_total_limit' onChange={this.onChange} isChecked={!this.props.isHidden('campaign_total_limit')}>Total limit spent $</Checkbox>
                                </ListGroupItem>
                            </ListGroup>
                        </ListGroupItem>
                    </ListGroup>
                </Modal.Body>
            </Modal>
        );
    }
}

ColumnModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isHidden: PropTypes.func.isRequired,
};
