import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Panel} from 'react-bootstrap';
import ZoneAPI from "../../../api-data/Manager/ZoneAPI";
import Loading from "../../../components/Loading";
import PaginationConstants from "../../../api-data/Enums/PaginationConstants";
import Pagination from "../../../components/Pagination";
import History from "../../../lib/History";
import PropTypes from "prop-types";
import qs from "query-string";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import ZonesList from './ZonesList';

/**
 * Индексная страница отображающая список зон
 */
export default class Zones extends Component {

    constructor(props) {
        super(props);

        const query = qs.parse(this.props.location.search);

        this.state = {
            searchParams: {
                id:      query.id || '',
                name:    query.name || '',
                user_id: query.user_id || '',
            },
            isReady:      false,
            page:         query.page || PaginationConstants.defaultPage,
            lastPage:     PaginationConstants.defaultPage,
            zonesList:    [],
        };
    }

    /**
     * При инициализации компонента загружает список зон
     */
    componentWillMount() {
        this.loadZonesList();
    }

    /**
     * Загрузка списка зон
     */
    loadZonesList = () => {
        const searchParams = {...this.state.searchParams, page: this.state.page};

        History.update(this.props.history, searchParams);

        ZoneAPI.list(searchParams)
            .then((response) => this.setState({page: response.current_page, lastPage: response.last_page, zonesList: response.zones, isReady: true}))
            .catch(DangerEvent.onSystemError);
    };

    /**
     * Обновляем историю браузера, при смене страницы
     *
     * @param page
     */
    onChangePage = (page) => {
        this.setState({page: page}, this.loadZonesList);
    };

    /**
     * При смене фильтра, сбрасываем и страницу на 1-ю
     * @param searchParams
     */
    onChangeFilter = (searchParams) => {
        this.setState({page: PaginationConstants.defaultPage, searchParams: searchParams}, this.loadZonesList)
    };

    render() {
        if (this.state.isReady === false) {
            return <Loading/>;
        }

        return (
            <Panel className='zone-list-block'>
                <Panel.Heading className='panel-heading_top-rounded'>
                    <div className='pull-right'>
                        <NavLink to='/zones/create' className='btn btn-block btn-success panel-heading__button panel-heading__button_right'>
                            &nbsp;<span className='glyphicon glyphicon-plus'/>&nbsp;
                        </NavLink>
                    </div>
                </Panel.Heading>

                <Panel.Body className='panel-body panel-body_unbordered'>
                    <ZonesList.Table>
                        <ZonesList.SearchHeader defaultValues={this.state.searchParams} handleFilters={this.onChangeFilter} />
                        <ZonesList.Body zones={this.state.zonesList} />
                    </ZonesList.Table>
                </Panel.Body>

                <Panel.Footer className='panel-footer_bottom-rounded'>
                    <div className='col-sm-12 panel-footer_pagination-wrapper'>
                        {this.refFilters !== null && <Pagination
                            onChangePage={(page) => this.onChangePage(page)}
                            current_page={this.state.page}
                            last_page={this.state.lastPage}
                        />}
                    </div>

                    <div className='clearfix'/>
                </Panel.Footer>
            </Panel>
        );
    }
}

Zones.propTypes = {
    history: PropTypes.object.isRequired,
};
