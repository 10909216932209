import React, {Component} from 'react';
import CampaignLink from '../../../../components/ObjectLinks/CampaignLink';
import UserLink from '../../../../components/ObjectLinks/UserLink';
import Progressbar from '../../../../components/Progressbar';
import StatusChanger from '../StatusChanger';
import ShowButton from '../../../../buttons/ShowButton';
import StatisticsButton from '../../../../buttons/StatisticsButton';
import StatisticsGroupBy from '../../../../api-data/Enums/StatisticsGroupBy';
import EditButton from '../../../../buttons/EditButton';
import CloneButton from '../../../../buttons/CloneButton';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Campaign from '../../../../api-data/Manager/Structs/Campaign';

/**
 * Информация о кампании
 */
class CampaignRow extends Component {

    render() {
        const {campaign} = this.props;

        return (
            <tr>
                <td className='campaign-table__body_id'>{campaign.id}</td>
                <td className='cut-off'>
                    <CampaignLink id={campaign.id} name={campaign.name}/>
                </td>
                <td className='cut-off'>
                    <UserLink id={campaign.user_id} name={campaign.user.pretty_name} is_blank={true}/>
                </td>
                <td className='cut-off'>{campaign.group.name}</td>
                <td>{campaign.format}</td>
                <td>{campaign.price_model}</td>
                <td>
                    <Progressbar
                        value={campaign.money.daily_limit_spent}
                        max={campaign.money.daily_limit}
                        unlimited={campaign.money.is_unlimited_daily}
                    />
                </td>
                <td>
                    <Progressbar
                        value={campaign.money.total_limit_spent}
                        max={campaign.money.total_limit}
                        unlimited={campaign.money.is_unlimited_total}
                    />
                </td>
                <td className='campaign-table__body_status'>
                    <StatusChanger id={campaign.id} status={campaign.status}/>
                </td>
                <td>
                    <div className='btn-group btn-group-xs pull-right'>
                        <ShowButton route={'/campaigns/' + campaign.id}/>
                        <StatisticsButton route={`/statistics?${StatisticsGroupBy.campaign}[]=${campaign.id}`}/>
                        <EditButton route={`/campaigns/${campaign.id}/edit`}/>
                        <CloneButton route={`/campaigns/${campaign.id}/clone`}/>
                    </div>
                </td>
            </tr>
        );
    }
}

CampaignRow.propTypes = {
    campaign: PropTypes.object.isRequired,
};

/**
 * Возвращает список рядов таблицы
 */
export default class Body extends Component {
    render() {
        const campaigns = _.map(this.props.campaigns, (campaign) => <CampaignRow campaign={campaign} key={campaign.id} />);

        return (
            <tbody>
                {campaigns}
            </tbody>
        );
    }
}

Body.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.instanceOf(Campaign)).isRequired,
};
