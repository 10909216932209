import Zone from "./Zone";
import Campaign from "./Campaign";
import User from './User';

export default class Correction {

    id          = 0;
    user_id     = 0;
    zone_id     = 0;
    campaign_id = 0;
    /** @type {User} */
    user        = null;
    /** @type {Zone} */
    zone        = null;
    /** @type {Campaign} */
    campaign    = null;
    status      = '';
    impressions = 0;
    conversions = 0;
    paid        = 0;
    profit      = 0;
    description = '';
    /** @type {Date} */
    date        = null;
    /** @type {Date} */
    created_at  = null;

    constructor(data) {
        this.id          = data.id;
        this.user_id     = data.user_id;
        this.zone_id     = data.zone_id;
        this.campaign_id = data.campaign_id;
        this.user        = new User(data.user);
        this.zone        = new Zone(data.zone);
        this.campaign    = new Campaign(data.campaign);
        this.status      = data.status;
        this.impressions = data.impressions;
        this.conversions = data.conversions;
        this.paid        = data.paid;
        this.profit      = data.profit;
        this.description = data.description;
        this.date        = new Date(data.date);
        this.created_at  = new Date(data.created_at);
    }
}
