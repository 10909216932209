import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

/**
 * Ссылка на просмотр информации о пользователе
 *
 * @param id
 * @param name
 * @param {Boolean} is_blank
 * @return {JSX.Element}
 */
export default function UserLink({id, name, is_blank}) {
    if (id === undefined || name === undefined) {
        return <span />;
    }

    if (is_blank === true) {
        return <a href={'/users/' + id} rel="noopener noreferrer" target='_blank'>{name}</a>;
    }

    return <NavLink to={'/users/' + id}>{name}</NavLink>;
}

UserLink.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    is_blank: PropTypes.bool,
};
