/**
 * Константы для сортировки
 */
export default class PostbacksReportStatus {
    static get pending () { return 'Pending'; };
    static get ready () { return 'Ready'; };
    static get defaultSort () { return PostbacksReportStatus.pending; };

    /**
     * Проверка готовности отчета
     *
     * @param {PostbacksReport} report
     */
    static isReady = (report) => {
        return report.status === PostbacksReportStatus.ready;
    };
}
