/**
 * Список дсотупных форматов группировки статистики
 */
export default class StatisticsGroupBy {

    static get publisher() {return 'Publisher'};
    static get advertiser() {return 'Advertiser'};
    static get zone() {return 'Zone'};
    static get campaign() {return 'Campaign'};
    static get day() {return 'Day'};
    static get hour() {return 'Hour'};
    static get country() {return 'Country'};
    static get carrier() {return 'Carrier'};
    static get browser() {return 'Browser'};
    static get os() {return 'OS'};
    static get device() {return 'Device'};
    static get pubid() {return 'Pubid'};
    static get appName() {return 'AppName'};
    static get error() {return 'Error'};
    static get default() {return StatisticsGroupBy.campaign};
    static get defaultForPublisher() {return StatisticsGroupBy.zone};
    static get defaultForAdvertiser() {return StatisticsGroupBy.campaign};

    /**
     * Получение списка группировок статистики
     *
     * @returns {*[]}
     */
    static get list () {
        return [StatisticsGroupBy.publisher, StatisticsGroupBy.advertiser, StatisticsGroupBy.zone, StatisticsGroupBy.campaign, StatisticsGroupBy.day, StatisticsGroupBy.hour, StatisticsGroupBy.country, StatisticsGroupBy.carrier, StatisticsGroupBy.browser, StatisticsGroupBy.os, StatisticsGroupBy.device, StatisticsGroupBy.pubid, StatisticsGroupBy.appName, StatisticsGroupBy.error];
    }

    /**
     * Получение списка группировок статистики
     */
    static get advertiserList() {
        return [StatisticsGroupBy.campaign, StatisticsGroupBy.day, StatisticsGroupBy.hour, StatisticsGroupBy.country, StatisticsGroupBy.carrier, StatisticsGroupBy.browser, StatisticsGroupBy.os, StatisticsGroupBy.device, StatisticsGroupBy.pubid];
    }

    /**
     * Получение списка группировок статистики
     */
    static get publisherList() {
        return [StatisticsGroupBy.zone, StatisticsGroupBy.day, StatisticsGroupBy.hour, StatisticsGroupBy.country, StatisticsGroupBy.carrier, StatisticsGroupBy.browser, StatisticsGroupBy.os, StatisticsGroupBy.device, StatisticsGroupBy.pubid];
    }
}
