import React from 'react';
import {OverlayTrigger} from 'react-bootstrap';
import BaseForm from "../../../components/BaseForm";
import DateTime from "../../../lib/DateTime";
import DateRange from "../../../components/DateRange";
import PostbacksReportAPI from "../../../api-data/Manager/PostbacksReportAPI";
import ListAPI from "../../../api-data/Manager/ListAPI";
import SelectOptions from "../../../components/Select/SelectOptions";
import Select from "../../../components/Select/Select";
import PropTypes from "prop-types";
import qs from "query-string";
import StatisticsGroupBy from "../../../api-data/Enums/StatisticsGroupBy";
import DangerEvent from "../../../components/Alerts/DangerEvent";

/**
 * Форма поиска конверсий
 */
export default class Form extends BaseForm {

    /**
     * В конструкторе разбираются GET параметры
     * Выбираются все ранее отмеченные данные и заполняется форма
     *
     * @param props
     */
    constructor(props) {
        super(props);

        const query = qs.parse(this.props.search);

        /**
         * Функция парсинга масивов данных из GET парамтров
         * @param query
         * @param name
         * @returns {*}
         */
        const getArrayFromQueryParam = (query, name) => {
            const paramName  = name + '[]';
            const queryParam = query[paramName];

            return Array.isArray(queryParam) ? queryParam : (queryParam === undefined ? [] : [queryParam]);
        };

        this.state = {
            submit: {
                from: new Date(),
                to: new Date(),
                zones: getArrayFromQueryParam(query, [StatisticsGroupBy.zone]),
                campaigns: getArrayFromQueryParam(query, [StatisticsGroupBy.campaign]),
            },

            campaignsList: new SelectOptions(),
            zonesList: new SelectOptions(),
        };
    }

    /**
     * При инициализации компонента загружаем:
     * - список кампаний
     * - список зон
     */
    componentWillMount() {
        ListAPI.campaigns()
            .then((campaigns) => this.setState({campaignsList: SelectOptions.createByIdPrettyName(campaigns)}))
            .catch(DangerEvent.onSystemError);
        ListAPI.zones(
            (zones) => {
                this.setState({zonesList: SelectOptions.createByIdPrettyName(zones)});
            },
            DangerEvent.onSystemError
        );
    }

    /**
     * Поле отправки формы, если данные сохранились
     *
     * @returns {function(*)}
     */
    get successCallback () {
        return (report) => this.props.onSubmit(report);
    }

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        PostbacksReportAPI.save(this.state.submit, this.successCallback, this.errorCallback);
    };

    render() {
        return (
            <form className="postbacks-form" id="postbacks-form" onSubmit={this.handleSubmit}>
                <div className="form-group col-sm-3" id="date_range">
                    <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={
                        <DateRange from={this.state.submit.from} to={this.state.submit.to} placement='bottom' position_top={170} offset_left={305} handleChange={this.handleChangeDateRange}/>
                    }>
                        <input type="string" name="date_range" value={DateTime.dateToYmd(this.state.submit.from) + ' - ' + DateTime.dateToYmd(this.state.submit.to)} className="form-control text-center date" onChange={this.handleChange} autoComplete='off' required />
                    </OverlayTrigger>
                    <small className="text-danger"/>
                </div>
                <div className="form-group col-sm-5" id="campaigns">
                    <Select
                        name='campaigns'
                        value={this.state.submit.campaigns}
                        placeholder='Select your campaigns...'
                        options={this.state.campaignsList}
                        multi={true}
                        searchable={true}
                        clearable={true}
                        onChange={this.updateSubmitState}
                    />
                    <small className="text-danger"/>
                </div>
                <div className="form-group col-sm-4" id="zones">
                    <Select
                        name='zones'
                        value={this.state.submit.zones}
                        placeholder='Select your zones...'
                        options={this.state.zonesList}
                        multi={true}
                        searchable={true}
                        clearable={true}
                        onChange={this.updateSubmitState}
                    />
                    <small className="text-danger"/>
                </div>
            </form>
        );
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
};
