import React, {Component} from 'react';

/**
 * Отрисовывает список алертов
 */
export default class Alerts extends Component {

    state = {
        // Алерты, которые должны закрыться сами, по истечению таймаута
        durationAlerts: []
    };

    /**
     * Регистрируем слушателя, при инициализации компонента
     */
    componentDidMount = () => {
        document.addEventListener('alert-notification', this.registerEventListener);
    };

    /**
     * Метод, случающий новые события
     * Каждое событие - это алерт с информацией, который добавляется в конец
     *
     * @param event
     */
    registerEventListener = (event) => {
        let alerts = this.state.durationAlerts;
            alerts.unshift(event.detail.alert);

        this.setState({durationAlerts: alerts});

        if (event.detail.duration) {
            this.startDismissTimeout(event.detail.duration);
        }
    };

    /**
     * Для каждого алерта, запускается таймаут
     * После истечения которого, он будет удален из списка
     *
     * @param duration
     */
    startDismissTimeout = (duration) => {
        setTimeout(() => {
            let alerts = this.state.durationAlerts;
                alerts.pop();

            this.setState({durationAlerts: alerts});
        }, duration);
    };

    render() {
        return (
            <div className='alerts-container'>
                {this.state.durationAlerts}
            </div>
        );
    }
}

Alerts.propTypes = {
};
