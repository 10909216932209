import axios from "axios/index";
import _ from "lodash";

/**
 * Компонент предоставляющий доступ к словарям
 */
export default class ListAPI {

    /**
     * API URL для работы со словарями
     *
     * @return {string}
     */
    static get link () {
        return process.env.REACT_APP_API_URL + '/manager/lists';
    };

    /**
     * Получение списка зон
     *
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static zones(successCallback, errorCallback) {
        axios.get(ListAPI.link + '/zones')
            .then((response) => response.data)
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Получение списка кампаний
     *
     * @return {Promise}
     */
    static campaigns() {
        return axios.get(ListAPI.link + '/campaigns')
            .then((response) => response.data);
    }

    /**
     * Получение списка доменов
     *
     * @return {Promise}
     */
    static domains(type) {
        return axios.get(ListAPI.link + '/domains', {params: {type: type}})
            .then((response) => response.data);
    }

    /**
     * Получение списка групп кампаний
     */
    static campaignsGroups() {
        return axios.get(ListAPI.link + '/campaigns-groups')
            .then((response) => response.data);
    }

    /**
     * Получение списка пользователей
     */
    static users() {
        return axios.get(ListAPI.link + '/users')
            .then((response) => _.map(response.data));
    }

    /**
     * Получение списка паблишеров
     * @return {Promise<Array>}
     */
    static publishers() {
        return axios.get(ListAPI.link + '/publishers')
            .then((response) => _.map(response.data));
    }

    /**
     * Получение списка адвертайзеров
     * @return {Promise<Array>}
     */
    static advertisers() {
        return axios.get(ListAPI.link + '/advertisers')
            .then((response) => _.map(response.data));
    }

    /**
     * Получение списка менеджеров
     * @return {Promise<Array>}
     */
    static managers() {
        return axios.get(ListAPI.link + '/managers')
            .then((response) => _.map(response.data));
    }
}
