import {Component} from 'react';
import PropTypes from "prop-types";
import DateTime from "../lib/DateTime";

/**
 * Компонент, который отображает в реальном времени
 * Сколько прошло времени от переданной даты
 */
export default class HowOld extends Component {

    /**
     * Константное значение
     * Разница в часах, относительно UTC
     *
     * @type {number}
     */
    hoursOffsetInUTC = new Date().getTimezoneOffset() * -60;

    /**
     * Номер интервала, который запустился для обновления времени
     *
     * @type {null|number}
     */
    interval = null;

    state = {
        secondsDiff: 0,
        minutesDiff: 0,
        hoursDiff: 0,
    };

    /**
     * Когда компонент инициализировался, запускаем таймер, который будет раз в период обновлять данные
     */
    componentDidMount = () => {
        this.update();

        this.interval = setInterval(this.update, this.props.delay);
    };

    /**
     * Останавливаем таймер, когда компонент перестает существовать
     */
    componentWillUnmount = () => clearInterval(this.interval);

    /**
     * Подсчет разницы времени
     */
    update = () => {
        const secondsDiff = (new Date().getTime() - this.props.date.getTime()) / 1000 - this.hoursOffsetInUTC;
        const minutesDiff = Math.round(secondsDiff / 60);
        const hoursDiff   = minutesDiff / 60;

        this.setState({secondsDiff: secondsDiff, minutesDiff: minutesDiff, hoursDiff: hoursDiff});
    };

    render() {
        switch (true) {
            case this.state.hoursDiff > 48:
                // В этом случае время больше не будет меняться
                // Останавливаем интервал
                clearInterval(this.interval);

                return DateTime.dateToYmdHis(this.props.date);
            case this.state.hoursDiff > 24:
                return 'yesterday at ' + DateTime.timeToHi(this.props.date);
            case this.state.hoursDiff >= 1:
                const hours = Math.floor(this.state.hoursDiff);
                const mins  = Math.round(this.state.minutesDiff - hours * 60);

                return `${hours} hours ${mins} mins ago`;
            case this.state.minutesDiff === 0:
                return `now`;
            default:
                return `${this.state.minutesDiff} mins ago`;
        }
    }
}

HowOld.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    delay: PropTypes.number.isRequired,
};

HowOld.defaultProps = {
    delay: 1000,
};

