/**
 * Структура, содержащая информацию о стране на которой будет кртиться кампания
 */
export default class CampaignCountry {

    pretty_name = '';
    country     = '';
    rate        = 0;

    constructor(data) {
        this.pretty_name = data.pretty_name;
        this.country     = data.country;
        this.rate        = data.rate;
    }
}
