/**
 * Структура, содержащая единицу данных о статистике
 */
export default class StatsData {

    group_by                               = '';
    total_impressions                      = 0;
    impressions                            = 0;
    unshown_impressions                    = 0;
    backbutton_impressions                 = 0;
    unshown_backbutton_impressions         = 0;
    backurl_impressions                    = 0;
    bot_impressions                        = 0;
    hosting_impressions                    = 0;
    iframe_impressions                     = 0;
    proxy_impressions                      = 0;
    inapp_impressions                      = 0;
    conversions                            = 0;
    backbutton_conversions                 = 0;
    revenue                                = 0;
    paid                                   = 0;
    profit                                 = 0;
    ecpm                                   = 0;
    ecpm_pub                               = 0;
    cr                                     = 0;
    bb_cr                                  = 0;
    impressions_percent                    = 0;
    unshown_impressions_percent            = 0;
    backbutton_impressions_percent         = 0;
    unshown_backbutton_impressions_percent = 0;
    backurl_impressions_percent            = 0;
    bot_impressions_percent                = 0;
    hosting_impressions_percent            = 0;
    iframe_impressions_percent             = 0;
    proxy_impressions_percent              = 0;
    inapp_impressions_percent              = 0;
    rev_share                              = 0;

    constructor(data) {
        this.group_by                               = data.group_by || '';
        this.total_impressions                      = data.total_impressions || 0;
        this.impressions                            = data.impressions || 0;
        this.unshown_impressions                    = data.unshown_impressions || 0;
        this.backbutton_impressions                 = data.backbutton_impressions || 0;
        this.unshown_backbutton_impressions         = data.unshown_backbutton_impressions || 0;
        this.backurl_impressions                    = data.backurl_impressions || 0;
        this.bot_impressions                        = data.bot_impressions || 0;
        this.hosting_impressions                    = data.hosting_impressions || 0;
        this.iframe_impressions                     = data.iframe_impressions || 0;
        this.proxy_impressions                      = data.proxy_impressions || 0;
        this.inapp_impressions                      = data.inapp_impressions || 0;
        this.conversions                            = data.conversions || 0;
        this.backbutton_conversions                 = data.backbutton_conversions || 0;
        this.revenue                                = data.revenue || 0;
        this.paid                                   = data.paid || 0;
        this.profit                                 = data.profit || 0;
        this.ecpm                                   = data.ecpm || 0;
        this.ecpm_pub                               = data.ecpm_pub || 0;
        this.cr                                     = data.cr || 0;
        this.bb_cr                                  = data.bb_cr || 0;
        this.impressions_percent                    = data.impressions_percent || 0;
        this.unshown_impressions_percent            = data.unshown_impressions_percent || 0;
        this.backbutton_impressions_percent         = data.backbutton_impressions_percent || 0;
        this.unshown_backbutton_impressions_percent = data.unshown_backbutton_impressions_percent || 0;
        this.backurl_impressions_percent            = data.backurl_impressions_percent || 0;
        this.bot_impressions_percent                = data.bot_impressions_percent || 0;
        this.hosting_impressions_percent            = data.hosting_impressions_percent || 0;
        this.iframe_impressions_percent             = data.iframe_impressions_percent || 0;
        this.proxy_impressions_percent              = data.proxy_impressions_percent || 0;
        this.inapp_impressions_percent              = data.inapp_impressions_percent || 0;
        this.rev_share                              = data.rev_share || 0;
    }
}
