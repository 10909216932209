import React, {Component} from "react";
import {Popover} from 'react-bootstrap';
import PropTypes from "prop-types";
import {DateRangePicker} from 'react-date-range';

export default class DateRange extends Component {

    /**
     * Вызываем метод handleChange из props, только в том случае, если обновились обе даты
     * @param selection
     */
    handleChange = ({selection}) => {
        this.setState({startDate: selection.startDate, endDate: selection.endDate});
        this.props.handleChange(selection);
    };

    render() {
        return (
            <Popover id="date-range-popover" title="Date range" bsClass='date-range-popover popover' placement={this.props.placement} positionTop={this.props.position_top} arrowOffsetLeft={this.props.offset_left}>
                <DateRangePicker
                    onChange={this.handleChange}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    showDateDisplay={false}
                    months={2}
                    ranges={[{
                        startDate: this.props.from,
                        endDate: this.props.to,
                        key: 'selection',
                    }]}
                    direction="horizontal"
                />
            </Popover>
        );
    }
}

DateRange.propTypes = {
    from: PropTypes.objectOf(Date).isRequired,
    to: PropTypes.objectOf(Date).isRequired,
    handleChange: PropTypes.func.isRequired,
    position_top: PropTypes.number.isRequired,
    offset_left: PropTypes.number.isRequired,
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
};
