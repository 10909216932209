export default class Domain {

    id              = 0;
    type            = '';
    domain          = '';
    is_default      = false;
    /** {Date} */
    last_checked_at = null;
    /** {Date} */
    created_at      = null;

    constructor(data) {
        this.id              = data.id;
        this.type            = data.type;
        this.domain          = data.domain;
        this.is_default      = data.is_default;
        this.last_checked_at = new Date(data.last_checked_at);
        this.created_at      = new Date(data.created_at);
    }
}
