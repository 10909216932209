import React from 'react';
import BaseForm from "../../../components/BaseForm";
import PropTypes from "prop-types";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import SelectOptions from "../../../components/Select/SelectOptions";
import ListAPI from "../../../api-data/Manager/ListAPI";
import S2SRequestAPI from "../../../api-data/Manager/S2SRequestAPI";
import Select from "../../../components/Select/Select";

/**
 * Компонент формы создания s2s request
 */
export default class Form extends BaseForm {

    constructor(props) {
        super(props);

        this.state = {
            submit: {
                zone_id: 0,
                campaign_id: 0,
            },
            zonesList: new SelectOptions(),
            campaignsList: new SelectOptions(),
        };
    }

    /**
     * Callback который нужно вызвать в случае успешной отправки данных
     * @returns {function(*)}
     */
    get successCallback () {
        return this.props.handleSave;
    };

    /**
     * При инициализации компонента загружаем:
     * - кампанию по id в случае если он указан
     */
    componentWillMount() {
        ListAPI.zones(
            (zones) => {
                this.setState({zonesList: SelectOptions.createByIdPrettyName(zones)});
            },
            DangerEvent.onSystemError
        );
        ListAPI.campaigns()
            .then((campaigns) => this.setState({campaignsList: SelectOptions.createByIdPrettyName(campaigns)}))
            .catch(DangerEvent.onSystemError);
    }

    /**
     * Отправляет данные формы
     * @param event
     */
    handleSubmit = (event) => {
        event.preventDefault();

        S2SRequestAPI.save(this.state.submit, this.successCallback, this.errorCallback);
    };

    render() {
        return (
            <form className='s2s-request-form' id='s2s-request-form' onSubmit={this.handleSubmit}>
                <div className="form-group" id="zone_id-block">
                    <label htmlFor="zone_id" className='control-label'>Zone</label>
                    <Select
                        name='zone_id'
                        value={this.state.submit.zone_id}
                        placeholder='Select zone for s2s test'
                        options={this.state.zonesList}
                        searchable={true}
                        required={true}
                        onChange={this.updateSubmitState}
                    />
                    <small className="text-danger"/>
                </div>
                <div className="form-group" id="campaign_id-block">
                    <label htmlFor="campaign_id" className='control-label'>Campaign</label>
                    <Select
                        name='campaign_id'
                        value={this.state.submit.campaign_id}
                        placeholder='Select campaign for s2s test'
                        options={this.state.campaignsList}
                        searchable={true}
                        required={true}
                        onChange={this.updateSubmitState}
                    />
                    <small className="text-danger"/>
                </div>
            </form>
        );
    }
}

Form.propTypes = {
    handleSave: PropTypes.func.isRequired,
};

