import axios from "axios";
import PostbacksReport from "./Structs/PostbacksReport";
import _ from "lodash";

/**
 * Компонент для получения данных о конверсиях
 */
export default class PostbacksReportAPI {

    static get link () {
        return process.env.REACT_APP_API_URL + '/manager/reports/postbacks';
    };

    /**
     * Получение списка отчетов
     *
     * @param {Function} successCallback
     * @param {Function} errorCallback
     */
    static list(successCallback, errorCallback) {
        axios.get(PostbacksReportAPI.link)
            .then(({data}) => _.map(data.data, (report) => new PostbacksReport(report)))
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Создание заявки на новый отчет
     *
     * @param filters
     * @param successCallback
     * @param errorCallback
     */
    static save(filters, successCallback, errorCallback) {
        axios.post(PostbacksReportAPI.link, filters)
            .then(({data}) => successCallback(new PostbacksReport(data)))
            .catch(errorCallback);
    }
}
