/**
 * Список дсотупных типов событий
 */
import CampaignStatuses from "./CampaignStatuses";

export default class EventTypes {
    static get CampaignPaused() {return 'campaign_paused'};
    static get CampaignWaiting()  {return 'campaign_waiting'};
    static get CampaignStarted() {return 'campaign_started'};
    static get CampaignFinished() {return 'campaign_finished'};
    static get PostbacksReportDone() {return 'postbacks_report_done'};
    static get S2SRequestCreated() {return 's2s_request_created'};
    static get S2SRequestDone() {return 's2s_request_done'};
    static get ProcessedCorrection() {return 'processed_correction'};

    /**
     * Превращает тип события в статус кампании
     *
     * @param type
     * @returns {string}
     */
    static toCampaignStatus(type) {
        switch (type) {
            case EventTypes.CampaignPaused:
                return CampaignStatuses.Paused;
            case EventTypes.CampaignWaiting:
                return CampaignStatuses.Waiting;
            case EventTypes.CampaignStarted:
                return CampaignStatuses.Started;
            case EventTypes.CampaignFinished:
                return CampaignStatuses.Finished;
            default:
                return '';
        }
    }
}
