import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

/**
 * Ссылка на просмотр информации о кампании
 *
 * @return {string}
 */
export default function CampaignLink({id, name, is_blank}) {
    if (id === undefined || name === undefined) {
        return '';
    }

    if (is_blank === true) {
        return <a href={'/campaigns/' + id} rel="noopener noreferrer" target='_blank'>{name}</a>;
    }

    return <NavLink to={'/campaigns/' + id}>{name}</NavLink>;
}

CampaignLink.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    is_blank: PropTypes.bool,
};
