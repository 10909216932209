import React, {Component} from 'react';
import PropTypes from "prop-types";
import CampaignStatuses from "../../../api-data/Enums/CampaignStatuses";
import CampaignAPI from "../../../api-data/Manager/CampaignAPI";
import DangerEvent from "../../../components/Alerts/DangerEvent";
import SuccessEvent from "../../../components/Alerts/SuccessEvent";

/**
 * Компонент смены и отображения статусов кампании
 */
export default class StatusChanger extends Component {

    constructor(props) {
        super(props);

        this.state = {...props};
    }

    /**
     * Смена статуса кампании
     *
     * @param status
     * @returns {Promise|null}
     */
    changeStatus = (status) => {
        if (status === this.state.status) {
            return null;
        }

        switch (status) {
            case CampaignStatuses.Started:
                return CampaignAPI.start(
                    this.props.id,
                    () => this.setState({status: CampaignStatuses.Started}, SuccessEvent.success),
                    DangerEvent.onSystemError
                );

            case CampaignStatuses.Paused:
                return CampaignAPI.pause(
                    this.props.id,
                    () => this.setState({status: CampaignStatuses.Paused}, SuccessEvent.success),
                    DangerEvent.onSystemError
                );

            default:
                return null;
        }
    };

    render() {
        return (
            <div className='btn-group btn-group-xs btn-group-statuses pull-right'>
                <button
                    type='button'
                    className={
                        'btn btn-success ' +
                        (CampaignStatuses.isStarted(this.state.status) ? 'active': '') +
                        (CampaignStatuses.isWaiting(this.state.status) ? 'hidden': '')
                    }
                    onClick={() => {this.changeStatus(CampaignStatuses.Started)}}
                >
                    <span className='glyphicon glyphicon-play'/>
                </button>
                <button
                    type='button'
                    style={{borderRadius: '3px 0 0 3px'}}
                    className={
                        'btn btn-info ' +
                        (CampaignStatuses.isWaiting(this.state.status) ? 'active': '') +
                        (!CampaignStatuses.isWaiting(this.state.status) ? 'hidden': '')
                    }
                >
                    <span className='glyphicon glyphicon-refresh'/>
                </button>
                <button
                    type='button'
                    style={{borderRadius: '0 3px 3px 0'}}
                    className={
                        'btn btn-danger ' +
                        (CampaignStatuses.isFinished(this.state.status) ? 'active': '') +
                        (!CampaignStatuses.isFinished(this.state.status) ? 'hidden': '')
                    }
                >
                    <span className='glyphicon glyphicon-stop'/>
                </button>
                <button
                    type='button'
                    className={
                        'btn btn-warning ' +
                        (CampaignStatuses.isPaused(this.state.status) ? 'active': '') +
                        (CampaignStatuses.isFinished(this.state.status) ? 'hidden': '')
                    }
                    onClick={() => {this.changeStatus(CampaignStatuses.Paused)}}
                >
                    <span className='glyphicon glyphicon-pause'/>
                </button>
            </div>
        );
    }
}

StatusChanger.propTypes = {
    id: PropTypes.number.isRequired,
    status: PropTypes.oneOf(CampaignStatuses.list).isRequired,
};
